import { RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";

interface RadioComponentProps {
  label: string;
  value: any;
  name: string;
  forChecked: any;
  className: any;
  handleFieldChange: any;
}

const Index = (props: RadioComponentProps) => {
  const style = useStyles();
  return (
    <div>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        className={`${style.muiRadio}`}
      >
        <FormControlLabel
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 17,
            },
            fontSize: 10,
          }}
          className={`${style.muiRadio}  align-items-center`}
          value={props.value}
          control={
            <Radio
              disableRipple
              sx={{
                color: "#DB383C",
                "&.Mui-checked": {
                  color: "#DB383C",
                },
              }}
            />
          }
          label={
            <Typography className={props.className}>{props.label}</Typography>
          }
          name={props.name}
          checked={props.value == props.forChecked}
          onChange={(e: any) => props.handleFieldChange(e)}
        />
      </RadioGroup>
    </div>
  );
};

export default Index;
