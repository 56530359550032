import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  BlogInterface,
  FaqInterface,
  OffersInterface,
  PolicyInterface,
} from "./types";
import { set } from "lodash";

export const useClinicSlice = createSlice({
  name: "homeState",
  initialState,
  reducers: {
    toggleOpenContactModal: (state) => {
      state.contactModal = !state.contactModal;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    doSend: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    clearForm: (state) => {
      state.form.name = "";
      state.form.email = "";
      state.form.phoneNo = "";
      state.form.description = "";
    },

    doGetTmcData: (state) => {},
    setTmcList: (state, action: PayloadAction<PolicyInterface>) => {
      state.tmcData = action.payload;
    },
    doGetBlogList: (state) => {},
    setBlogList: (state, action: PayloadAction<Array<BlogInterface>>) => {
      state.blogList = action.payload;
    },
    doGetBlogById: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    setBlogDetails: (state, action: PayloadAction<BlogInterface>) => {
      state.blogDetails = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setTotalPage: (state, action: PayloadAction<number>) => {
      state.totalPage = action.payload;
    },
    setBlogFilter: (state, action: PayloadAction<any>) => {
      state.blogDetails.category = action.payload;
    },
    // offers
    doGetOffersList: (state) => {},
    setOffersList: (state, action: PayloadAction<Array<OffersInterface>>) => {
      state.offerList = action.payload;
    },
    doGetFaqList: (state) => {},
    setFaqList: (state, action: PayloadAction<Array<FaqInterface>>) => {
      state.faqList = action.payload;
    },
    setBannerData: (state, action: PayloadAction<any>) => {
      state.homeBanner = action.payload;
    },
    doGetHomeBanner: (state) => {},
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
