import { Box } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface Props {
  handleChange: any;
  name: string;
  value: string;
  countryCode: string;
}

function Index(props: Props) {
  return (
    <Box>
      <PhoneInput
        className="PhoneInput"
        placeholder="Enter phone number"
        name={props.name}
        value={props.value}
        country={"IN"}
        //@ts-ignore
        defaultCountry={props.countryCode || "IN"}
        onChange={(value: string) => props.handleChange(value, props.name)}
        international={true}
        withCountryCallingCode
      />
    </Box>
  );
}

export default Index;
