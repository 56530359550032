import { Box, Typography, styled } from "@mui/material";
import React from "react";
import RadioWithLabel from "../../Component/RadioButton/RadioWithLabel";
import {
  DateFilterEnum,
  FlightPriceFilterEnum,
  FlightStopsQuantityEnum,
  TripTypesEnum,
} from "../../Redux/flightSearch/types";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../Redux/flightSearch/slice";
import { selectForm } from "../../Redux/flightSearch/selector";
import { DateTime } from "luxon";

function Filter() {
  const searchParams = new URLSearchParams(window.location.search);
  const journeyDate = searchParams.get("journeyDate");
  const returnDate = searchParams.get("returnDate");
  const tripType: any = searchParams.get("tripType");

  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const AnyTime = "00:00:00";
  const Morning = "08:00:00";
  const AfterNoon = "14:00:00";
  const Evening = "19:00:00";
  const Night = "01:00:00";

  const handleRadioFieldChange = (name: string, value: any) => {
    dispatch(actions.updateFormValue({ key: name, value: value }));
    dispatch(
      actions.doSearchFlight({
        callback: () => {},
      })
    );
  };
  const handleDepartureFilterChange = (id: any) => {
    const d: any = journeyDate?.split("T");
    console.log(d[0], "concatDate");
    const concatDate = d[0].concat(
      "T",
      id == DateFilterEnum.Morning
        ? Morning
        : id == DateFilterEnum.AfterNoon
        ? AfterNoon
        : id == DateFilterEnum.Evening
        ? Evening
        : DateFilterEnum.Night
        ? Night
        : AnyTime
    );
    console.log(concatDate, "concatDate");
    dispatch(
      actions.updateFormValue({
        key: "departureFilterSelection",
        value: id,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "journeyDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "journeyArrivalDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.doSearchFlight({
        callback: () => {},
      })
    );
  };
  const handleArrivalFilterChange = (id: any) => {
    const d: any = returnDate?.split("T");
    console.log(d[0], "concatDate");
    const concatDate = d[0].concat(
      "T",
      id == DateFilterEnum.Morning
        ? Morning
        : id == DateFilterEnum.AfterNoon
        ? AfterNoon
        : id == DateFilterEnum.Evening
        ? Evening
        : DateFilterEnum.Night
        ? Night
        : AnyTime
    );
    console.log(concatDate, "concatDate");
    dispatch(
      actions.updateFormValue({
        key: "arrivalFilterSelection",
        value: id,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "returnDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "returnArrivalDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.doSearchFlight({
        callback: () => {},
      })
    );
  };

  return (
    <>
      <FilterBox>
        <FilterHeading>Departure Filter</FilterHeading>
        {DateFilter.map((data) => {
          return (
            <RadioWithLabel
              className={""}
              forChecked={data?.id}
              handleFieldChange={() => handleDepartureFilterChange(data?.id)}
              name="departureFilterSelection"
              value={form.departureFilterSelection}
              label={data?.name}
            />
          );
        })}
        {tripType == TripTypesEnum.ROUNDTRIP && (
          <>
            <FilterHeading sx={{ mt: 2 }}>Arrival Filter</FilterHeading>
            {DateFilter.map((data) => {
              return (
                <RadioWithLabel
                  className={""}
                  forChecked={data?.id}
                  handleFieldChange={() => handleArrivalFilterChange(data?.id)}
                  name="arrivalFilterSelection"
                  value={form.arrivalFilterSelection}
                  label={data?.name}
                />
              );
            })}
          </>
        )}

        <FilterHeading sx={{ mt: 2 }}>Price Filter</FilterHeading>
        <RadioWithLabel
          className={""}
          forChecked={form.priceFilter}
          handleFieldChange={() =>
            handleRadioFieldChange(
              "priceFilter",
              FlightPriceFilterEnum.LOW_TO_HIGH
            )
          }
          name="priceFilter"
          value={FlightPriceFilterEnum.LOW_TO_HIGH}
          label="Low To High"
        />

        <RadioWithLabel
          className={""}
          forChecked={form.priceFilter}
          handleFieldChange={() =>
            handleRadioFieldChange(
              "priceFilter",
              FlightPriceFilterEnum.HIGH_TO_LOW
            )
          }
          name="priceFilter"
          value={FlightPriceFilterEnum.HIGH_TO_LOW}
          label="High To Low"
        />
        <FilterHeading sx={{ mt: 2 }}>Stop Filter</FilterHeading>
        <RadioWithLabel
          className={""}
          forChecked={form.stopsFilter}
          handleFieldChange={() =>
            handleRadioFieldChange(
              "stopsFilter",
              FlightStopsQuantityEnum.NON_STOP
            )
          }
          name="stopsFilter"
          value={FlightStopsQuantityEnum.NON_STOP}
          label="Non Stop"
        />
        <RadioWithLabel
          className={""}
          forChecked={form.stopsFilter}
          handleFieldChange={() =>
            handleRadioFieldChange(
              "stopsFilter",
              FlightStopsQuantityEnum.ONE_STOP
            )
          }
          name="stopsFilter"
          value={FlightStopsQuantityEnum.ONE_STOP}
          label="1 Stop"
        />
        <RadioWithLabel
          className={""}
          forChecked={form.stopsFilter}
          handleFieldChange={() =>
            handleRadioFieldChange(
              "stopsFilter",
              FlightStopsQuantityEnum.TWO_OR_MORE_STOP
            )
          }
          name="stopsFilter"
          value={FlightStopsQuantityEnum.TWO_OR_MORE_STOP}
          label="2+ Stops"
        />
      </FilterBox>
    </>
  );
}

export default Filter;
const FilterBox = styled(Box)(({ theme }) => ({
  // boxShadow: "0px 4px 30px 0px #D2D2D25E",
  // height: 600,
  // borderRadius: 15,
  padding: 10,
  position: "sticky",
  top: 97,
  [theme.breakpoints.down("sm")]: {
    position: "static",
  },
  [theme.breakpoints.between("sm", "md")]: {
    position: "static",
  },
  [theme.breakpoints.between("md", "lg")]: {
    top: 152,
  },
}));
const FilterHeading = styled(Typography)(({ theme }) => ({
  // borderBottom: "1px solid #e5e5e5",
  marginBottom: 8,
  fontSize: 16,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    fontWeight: 600,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));

const DateFilter = [
  { id: DateFilterEnum.Morning, name: "Morning (12:00AM - 04:59AM)" },
  { id: DateFilterEnum.AfterNoon, name: "AfterNoon (05:00AM - 11:59AM)" },
  { id: DateFilterEnum.Evening, name: "Evening (12:00PM - 05:59PM)" },
  { id: DateFilterEnum.Night, name: "Night (06:00PM - 11:59PM)" },
];
