import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";
import CatchBlockFunction from "../../Component/CatchError";
import {
  AddCoupon,
  BookingFlightSelect,
  BookingFlightSelectPayment,
} from "../../utils/request";
import { toast } from "react-toastify";
import { SelectTravellerList } from "../Login/selector";
import { TravellersInterface } from "../Login/types";
import {
  selectCouponForm,
  selectForm,
  selectRequestDetail,
  selectSaveTraveler,
} from "./selector";
import { ContactInterface, ICoupon, requestDetailInterface } from "./types";

export function* doBookingFlightSelectRequest(action: {
  payload: {
    ResultIndex: string;
    ReturnResultIndex: string;
    TraceId: string;
    callback: any;
  };
}) {
  yield delay(500);

  try {
    const data = {
      ResultIndex: action.payload.ResultIndex,
      ReturnResultIndex: action.payload.ReturnResultIndex,
      TraceId: action.payload.TraceId,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(BookingFlightSelect, data);

    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setFlightBookingId(response.data.data.bookingId));
    yield put(actions.setBookingTimer(response.data.data.callTime));
    yield put(
      actions.setSelectedFlightSearchList(response.data.data.data.onwardDetails)
    );
    yield put(
      actions.setSelectedFlightReturnData(response.data.data.data.returnDetails)
    );
    yield put(actions.setRequestDetail(response.data.data.data.request));

    yield put(actions.setLoading(false));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doBookingCreatePaymentRequest(action: {
  payload: { bookingId: string; callback: any };
}) {
  yield delay(500);
  try {
    const List: TravellersInterface[] = yield select(SelectTravellerList);
    const form: ContactInterface = yield select(selectForm);
    const SaveTraveler: boolean = yield select(selectSaveTraveler);
    const SelectedTraveler: requestDetailInterface = yield select(
      selectRequestDetail
    );
    if (form.firstName.length == 0) {
      toast.error("please enter contact name");
      return;
    }
    // if (form.mobileNumber.length == 0) {
    //   toast.error("please enter contact number");
    //   return;
    // }
    if (form.email.length == 0) {
      toast.error("please enter contact email");
      return;
    }
    const selectedList = List.filter((data) => data.checked == true);
    if (
      selectedList.length !=
      Number(SelectedTraveler.adults) +
        Number(SelectedTraveler.children) +
        Number(SelectedTraveler.infants)
    ) {
      toast.error(
        `please select ${
          Number(SelectedTraveler.adults) > 0
            ? `${Number(SelectedTraveler.adults)} adults`
            : ""
        }${
          Number(SelectedTraveler.children) > 0
            ? `${Number(SelectedTraveler.children)} children`
            : ""
        }${
          Number(SelectedTraveler.infants) > 0
            ? `${Number(SelectedTraveler.infants)} adults`
            : ""
        } passenger`
      );
      return;
    }

    const data = {
      bookingId: action.payload.bookingId,
      saveTraveler: SaveTraveler,
      Passengers: List.filter((data) => data.checked == true),
      contactDetail: form,
    };
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      BookingFlightSelectPayment,
      data
    );

    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setRazorePayFormData(response.data.data.data));
    yield put(actions.setLoading(false));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doAddCouponCodeRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const form: ICoupon = yield select(selectCouponForm);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(AddCoupon, form);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setLoading(false));
    yield put(actions.setCouponDiscount(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* BookingRepoSaga() {
  yield takeLatest(actions.doFlightSelect, doBookingFlightSelectRequest);
  yield takeLatest(
    actions.doFlightSelectBookingPayment,
    doBookingCreatePaymentRequest
  );
  yield takeLatest(actions.doAddCouponCode, doAddCouponCodeRequest);
}
