import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { initialState } from "./types/initialState";
import {
  AirportListInterface,
  CouponListInterface,
  DateFilterEnum,
  FlightSearchListInterface,
  TravelClassEnum,
  TripTypesEnum,
} from "./types";
import { DateTime } from "luxon";

export const useClinicSlice = createSlice({
  name: "flightSearchState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearSearchFlightForm: (state) => {
      state.genForm.adults = 1;
      state.genForm.children = 0;
      state.genForm.infants = 0;
      state.form.source = "";
      state.form.sourceLocation = "";
      state.form.destination = "";
      state.form.destinationLocation = "";
      state.form.journeyArrivalDate = DateTime.now().toISO();
      state.form.journeyDate = DateTime.now().toISO();
      state.form.returnArrivalDate = DateTime.now().toISO();
      state.form.returnDate = null;
      state.form.tripType = TripTypesEnum.ONE_WAY;
      state.form.travelClass = TravelClassEnum.ECONOMY;
      state.form.airportSearch = "";
      state.form.departureFilterSelection = DateFilterEnum.All;
      state.form.arrivalFilterSelection = DateFilterEnum.All;
    },
    updateGenFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `genForm.${action.payload.key}`, action.payload.value);
    },

    doGetAirportList: (state, action: PayloadAction<string>) => {},
    doSearchFlight: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setAirportList: (
      state,
      action: PayloadAction<Array<AirportListInterface>>
    ) => {
      state.airportList = action.payload;
    },
    setFlightSearchList: (
      state,
      action: PayloadAction<Array<FlightSearchListInterface>>
    ) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        state.flightSearchList[index] = action.payload[index];
        state.flightSearchList[index].selected = index == 0 ? true : false;
      }
    },
    setReturnFlightSearchList: (
      state,
      action: PayloadAction<Array<FlightSearchListInterface>>
    ) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        state.returnFlightSearchList[index] = action.payload[index];
        state.returnFlightSearchList[index].selected =
          index == 0 ? true : false;
      }
    },
    setReturnFlightSelected: (state, action: PayloadAction<{ index: any }>) => {
      for (
        let index = 0;
        index < state.returnFlightSearchList.length;
        index++
      ) {
        state.returnFlightSearchList[index].selected = false;
      }
      state.returnFlightSearchList[action.payload.index].selected = true;
    },
    setFlightSearchListSelected: (
      state,
      action: PayloadAction<{ index: any }>
    ) => {
      for (let index = 0; index < state.flightSearchList.length; index++) {
        state.flightSearchList[index].selected = false;
      }
      state.flightSearchList[action.payload.index].selected = true;
    },
    setFlightBookingId: (state, action: PayloadAction<string>) => {
      state.flightBookingId = action.payload;
    },
    setFromUpdate: (state, action: PayloadAction<boolean>) => {
      state.formUpdate = action.payload;
    },

    doGetCouponList: (state) => {},
    setCouponList: (
      state,
      action: PayloadAction<Array<CouponListInterface>>
    ) => {
      state.couponList = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
