import MainLayout from "../../Component/Layout/MainLayout";
import {
  Box,
  Container,
  Grid,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import BookingCard from "../../Component/BookingCard";
import BookingPayCard from "../../Component/BookingCard/BookingPayCard";
import BookButton from "../../Component/BookButton";
import ContactDetails from "../../Component/BookingSection/ContactDetails";
import TravellerDetails from "../../Component/BookingSection/TravellerDetails";
import { actions } from "../../Redux/Booking/slice";
import { actions as actionsPassenger } from "../../Redux/Login/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectFlightBookingId,
  SelectLoading,
  selectBookingTimer,
  selectForm,
  selectRazorePayForm,
  selectRequestDetail,
  selectSelectedFlightList,
  selectSelectedFlightReturnData,
  selectTimerModal,
} from "../../Redux/Booking/selector";
import {
  SelectTravellerList,
  selectUserDataForm,
} from "../../Redux/Login/selector";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as BookingSliceKey,
  reducer as BookingReducer,
} from "../../Redux/Booking/slice";
import { BookingRepoSaga } from "../../Redux/Booking/saga";
//@ts-ignore
import useRazorpay from "react-razorpay";
import Countdown from "react-countdown";
import TimerModal from "./TimerModal";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BookingSkeletonLoadingCard from "../../Component/BookingCard/bookingSkeletonLoading";
import Footer from "../../Component/Layout/Footer";
import CouponBox from "./CouponBox";

function Booking() {
  useInjectReducer({ key: BookingSliceKey, reducer: BookingReducer });
  useInjectSaga({ key: BookingSliceKey, saga: BookingRepoSaga });
  const [RazorPay] = useRazorpay();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const ResultIndex = searchParams.get("ResultIndex");
  const ReturnResultIndex = searchParams.get("ReturnResultIndex");
  const TraceId = searchParams.get("TraceId");
  const flightList = useSelector(selectSelectedFlightList);
  const loading = useSelector(SelectLoading);
  const returnResponse = useSelector(selectSelectedFlightReturnData);
  const bookingId = useSelector(SelectFlightBookingId);
  const passengerList = useSelector(SelectTravellerList);
  const contactForm = useSelector(selectForm);
  const requestData = useSelector(selectRequestDetail);
  const userDetails = useSelector(selectUserDataForm);
  const bookingTimer = useSelector(selectBookingTimer);
  const timerModal = useSelector(selectTimerModal);
  const paymentForm = useSelector(selectRazorePayForm);
  let token = localStorage.getItem("token");
  React.useEffect(() => {
    dispatch(actions.setLoading(true));
    if (token) {
      dispatch(actionsPassenger.doGetTraveller());
    }

    dispatch(
      actions.doFlightSelect({
        ResultIndex: ResultIndex as string,
        ReturnResultIndex:
          (ReturnResultIndex as string).length > 0
            ? (ReturnResultIndex as string)
            : "",
        TraceId: TraceId as string,
        callback() {},
      })
    );

    return () => {};
  }, []);
  const handleOpenTimer = () => {
    dispatch(actions.toggleBookingTimer(true));
  };
  const [CanDoPayment, setCanDoPayment] = React.useState(false);
  React.useEffect(() => {
    if (paymentForm.status) {
      gotoMakePayment();
    }

    return () => {};
  }, [paymentForm]);
  // }, [paymentForm, router]);

  const handleClick = () => {
    dispatch(
      actions.doFlightSelectBookingPayment({
        bookingId: bookingId,
        callback() {
          setCanDoPayment(true);
        },
      })
    );
  };
  const gotoMakePayment = () => {
    let options = {
      key: paymentForm.key,
      amount: paymentForm.amount,
      name: "Trivzy",
      description: "Order Payment",
      image: "https://web.trivzy.bigint.in/images/logo.png",
      order_id: paymentForm.paymentId,
      callback_url: paymentForm.callback_url,
      redirect: "http://localhost:3022/booking-loading",
      prefill: {
        name: `${userDetails.fullName}`,
        email: `${userDetails.email}`,
        contact: `${contactForm.mobileNumber}`,
      },
      notes: {
        address: "jaipur",
      },
      theme: {
        color: "rgba(219, 56, 60, 0.05)",
      },
      modal: {
        ondismiss: function () {
          setCanDoPayment(false);
        },
      },
    };
    //@ts-ignore
    const rzp = new RazorPay(options);
    rzp.open();
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      dispatch(actions.toggleBookingTimer(true));
    } else {
      return (
        <span style={{ fontWeight: 500 }}>
          Your session will expired in {minutes}:{seconds}
        </span>
      );
    }
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event: any) => {
  //     event.preventDefault();
  //     // Custom logic to handle the refresh
  //     // Display a confirmation message or perform necessary actions
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  return (
    <MainLayout maxWidth="1400px !important">
      <Box my={{ xs: 4, sm: 4 }} sx={{ minHeight: "calc(100vh - 200px)" }}>
        <Container sx={{ maxWidth: "1400px !important", pb: "100px" }}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={9}>
              <FilterBoxMain>
                <FilterBox>
                  <img
                    src="/images/CopyReview.svg"
                    width={20}
                    height={20}
                    alt="Picture of the author"
                  />
                  <HeadingReview>Review Itinerary</HeadingReview>
                </FilterBox>
                <FilterBox>
                  <SubHeading onClick={goBack} sx={{ cursor: "pointer" }}>
                    Change Flight
                  </SubHeading>
                </FilterBox>
              </FilterBoxMain>
              {loading ? (
                <>
                  {Array.from({ length: flightList?.Segments?.length }).map(
                    () => (
                      <BookingSkeletonLoadingCard />
                    )
                  )}
                </>
              ) : (
                <BookingCard
                  data={flightList}
                  returnData={returnResponse}
                  requestData={requestData}
                />
              )}

              <ContactDetails loading={loading} />
              <TravellerDetails
                requestData={requestData}
                passengerData={passengerList}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FilterBoxMain>
                <HeadingReview sx={{ m: 0 }}> Fare Details</HeadingReview>
                <SubHeading> Fare Rules</SubHeading>
              </FilterBoxMain>
              {flightList?.FareBreakdown?.length > 0 ? (
                <BookingPayCard
                  fareData={flightList?.Fare}
                  returnFareData={returnResponse?.Fare}
                  returnFareBreakdownData={returnResponse?.FareBreakdown}
                  fareBreakdown={flightList?.FareBreakdown}
                  loading={loading}
                  requestData={requestData}
                />
              ) : (
                ""
              )}
              <CouponBox loading={loading} />
              {loading ? (
                <Box mt={3} sx={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton variant="rounded" width="50%" height={50} />
                </Box>
              ) : (
                <Box textAlign={"center"} mt={3}>
                  {!CanDoPayment && (
                    <BookButton
                      handleClick={handleClick}
                      loading={false}
                      title="Book"
                    />
                  )}
                </Box>
              )}{" "}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
      {!loading && bookingTimer?.length > 0 && (
        <TimerBox>
          <Countdown
            date={moment(bookingTimer).valueOf() + 298999}
            renderer={renderer}
          />
        </TimerBox>
      )}
      {/* <TimerModal loading={loading} show={timerModal} /> */}
    </MainLayout>
  );
}

export default Booking;

const HeadingReview = styled(Typography)(({ theme }) => ({
  color: "#DE3151",
  fontSize: 18,
  fontWeight: 600,
  marginLeft: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    marginBottom: 20,
    marginTop: 30,
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginBottom: 30,
  },
}));
const SubHeading = styled(Typography)(({ theme }) => ({
  color: "#252525B2",
  fontSize: 11,
  fontWeight: 600,
}));
const FilterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const FilterBoxMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 20,
}));
const TimerBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100%",
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: 20,
  background: "#252525",
  color: "#fff",
  fontSize: 16,
}));
