import React from "react";
import CurrencyFormat from "react-currency-format";
interface CurrencyFormatInterface {
  value: any;
}
function Index(props: CurrencyFormatInterface) {
  return (
    <div>
      <CurrencyFormat
        value={props?.value?.toFixed(0)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"₹ "}
      />
    </div>
  );
}

export default Index;
