import { FlightSearchRepoSaga } from "../../Redux/flightSearch/saga";
import {
  sliceKey as FlightSearchSliceKey,
  reducer as FlightSearchReducer,
} from "../../Redux/flightSearch/slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as AuthSliceKey,
  reducer as AuthReducer,
} from "../../Redux/Login/slice";
import { AuthRepoSaga } from "../../Redux/Login/saga";
import {
  sliceKey as BookingSliceKey,
  reducer as BookingReducer,
} from "../../Redux/Booking/slice";

import { BookingRepoSaga } from "../../Redux/Booking/saga"; import {
  sliceKey as HomeSliceKey,
  reducer as HomeReducer,
} from "../../Redux/Home/slice";
import { HomeRepoSaga } from "../../Redux/Home/saga";

interface Props {
  children: any;
}
export default function Injector(props: Props) {
  // Login ------------->
  useInjectReducer({ key: AuthSliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  // Flight Search ------------->
  useInjectReducer({ key: FlightSearchSliceKey, reducer: FlightSearchReducer });
  useInjectSaga({ key: FlightSearchSliceKey, saga: FlightSearchRepoSaga });
  // Flight booking ------------->
  useInjectReducer({ key: BookingSliceKey, reducer: BookingReducer });
  useInjectSaga({ key: BookingSliceKey, saga: BookingRepoSaga });
  // Home booking ------------->
  useInjectReducer({ key: HomeSliceKey, reducer: HomeReducer });
  useInjectSaga({ key: HomeSliceKey, saga: HomeRepoSaga });

  return props.children;
}
