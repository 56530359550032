import { Box, Skeleton, Button, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../../Style/theme";

function SkeletonLoading() {
  const xsd = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        p={"24px 24px 16px 24px"}
        border="1px solid rgba(37, 37, 37, 0.2)"
        borderRadius={2}
        width={"100%"}
        mx="auto"
        mt={1}
      >
        <Box
          display="flex"
          alignItems="start"
          width={{ xs: "100%", md: "60%" }}
          justifyContent="space-between"
          mb={2}
        >
          <Skeleton width={xsd ? 40 : 100} />
          <Skeleton width={xsd ? 40 : 100} />
          <Skeleton width={xsd ? 40 : 100} />
          <Skeleton width={xsd ? 40 : 100} sx={{ ml: { xs: 0, sm: 2 } }} />
        </Box>
        <Box
          display="flex"
          alignItems={{ xs: "start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display="flex" alignItems={{ xs: "start", sm: "center" }}>
            <Skeleton variant="rectangular" width={40} height={40} />
            <Box ml={2}>
              <Skeleton width={100} />
              <Skeleton width={60} />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems={{ xs: "start", sm: "center" }}
            justifyContent="space-between"
            width={"100%"}
            pl={{ xs: 0, sm: "10%" }}
            mt={{ xs: 2.5, sm: 0 }}
          >
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={60} />
              <Skeleton width={60} />
            </Box>
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={60} />
              <Skeleton width={60} />
            </Box>
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={60} />
              <Skeleton width={60} />
            </Box>
            <Box
              textAlign={{ xs: "start", sm: "center" }}
              display={{ xs: "none", sm: "block" }}
            >
              <Skeleton width={60} />
              <Skeleton width={60} />
            </Box>
          </Box>
        </Box>
        <Box
          justifyContent={"space-between"}
          display={{ xs: "flex", sm: "none" }}
          alignItems={"center"}
          mt={1.5}
        >
          <Skeleton width={60} />
          <Skeleton width={60} />
        </Box>
      </Box>
    </>
  );
}

export default SkeletonLoading;
