import { TravelClassEnum } from "../../../Redux/flightSearch/types";
import {
  Box,
  ClickAwayListener,
  Fade,
  Grid,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import theme from "../../../Style/theme";
import {
  AirlineSeatReclineExtra,
  DirectionsRunRounded,
} from "@mui/icons-material";
import { path } from "../../../utils/Api";

function ViewFlightInfoCard({ detailsData, index, travelClass, stop }: any) {
  const [open, setOpen] = React.useState(false);
  const [layover, setLayover] = React.useState("");
  const smd = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const date1 = DateTime.fromISO(detailsData?.Origin?.DepTime);
  const date2 = DateTime.fromISO(detailsData?.Destination?.ArrTime);
  const startDate = DateTime.fromISO(detailsData?.Origin?.DepTime);
  const endDate = DateTime.fromISO(detailsData?.Destination?.ArrTime);
  const dateDifference = endDate.diff(startDate, ["days"]);
  const diff = date1.diff(date2, ["hours"]);

  React.useEffect(() => {
    console.log("Date difference in days:", dateDifference);
    console.log(diff, "diff");
    console.log(stop, "detailsData1");
    return () => {};
  }, []);

  const convertTimeString = (timeString: any) => {
    const [hours, minutes, seconds] = timeString?.split(":");
    const formattedTime = `${parseInt(hours, 10)}hr ${parseInt(minutes, 10)}m`;
    return formattedTime;
  };
  const convertMinutesToHours = (time: number) => {
    var num = time;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0) {
      return rhours + "h " + rminutes + "m";
    } else {
      return rminutes + "m";
    }
  };

  return (
    <div
      className={`${
        stop == 2 && index != 0
          ? "cardBorderBottom"
          : stop == 1 && index == 0
          ? "cardBorderBottom"
          : "cardBorderBottomNone"
      }`}
    >
      <FlightCard
        sx={
          stop == 2 && index != 0
            ? { borderRadius: "0px 0px 8px 8px", borderTop: 0 }
            : stop == 1 && index == 0
            ? { borderRadius: "8px" }
            : { borderRadius: "8px 8px 0px 0px" }
        }
        className="viewFlightInfoCardBorder"
        key={index}
      >
        {detailsData?.GroundTime > 0 && (
          <Box className="textCenter">
            <LayoverText>
              {convertMinutesToHours(detailsData?.GroundTime) > "2:00" ? (
                <AirlineSeatReclineExtra
                  sx={{ width: 14, height: 14, color: "#4d4d4d" }}
                />
              ) : (
                <DirectionsRunRounded
                  sx={{ width: 14, height: 14, color: "#4d4d4d" }}
                />
              )}
              Layover {convertMinutesToHours(detailsData?.GroundTime)}
            </LayoverText>
          </Box>
        )}
        <Grid container sx={{ rowGap: { xs: 2, md: 0 } }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <BookingTitleTypo>
                {detailsData?.Origin?.Airport?.CityName} -{" "}
                {detailsData?.Destination?.Airport?.CityName}
              </BookingTitleTypo>
              <DayAndDateTypo sx={{ mb: { xs: "1px", sm: "1px", md: "2px" } }}>
                {DateTime.fromISO(detailsData?.Origin?.DepTime)?.toFormat(
                  "EEE dd MMM"
                )}
              </DayAndDateTypo>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{ rowGap: { xs: 2, md: 0 } }}
              className="textAlign"
            >
              <Grid item xs={12} sm={3} md={3.3} lg={3.5}>
                <Box className="textAlign">
                  <img
                    src={
                      detailsData?.AirlineLogo
                        ? path() + detailsData?.AirlineLogo
                        : "/images/airplane.png"
                    }
                    alt="Logo"
                    style={{ width: "40px", height: "40px", marginRight: 18 }}
                  />
                  <Box>
                    <AirlineNameTypo>
                      {detailsData?.Airline?.AirlineName}
                    </AirlineNameTypo>
                    <CountryCodeTypo>
                      {detailsData?.Airline?.AirlineCode}-
                      {detailsData?.Airline?.FlightNumber}
                    </CountryCodeTypo>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={5.5} lg={5.5}>
                <Box className="viewFlightInfoTimeFlex">
                  <Box textAlign={"center"}>
                    <FlightTimeTypo>
                      {DateTime.fromISO(detailsData?.Origin?.DepTime)?.toFormat(
                        "HH:mm"
                      )}
                    </FlightTimeTypo>
                    <DestinationTypo>
                      {detailsData?.Origin?.Airport?.CityName}{" "}
                      {detailsData?.Origin?.Airport?.Terminal.length > 0 ? (
                        <>(Terminal {detailsData?.Origin?.Airport?.Terminal})</>
                      ) : (
                        ""
                      )}
                    </DestinationTypo>
                    <DayAndDateTypo>
                      {DateTime.fromISO(detailsData?.Origin?.DepTime)?.toFormat(
                        "EEE dd MMM"
                      )}
                    </DayAndDateTypo>
                  </Box>
                  <Box textAlign={"center"}>
                    <TimeTypo>
                      {convertTimeString(detailsData?.Duration)}
                    </TimeTypo>
                    <AirlineNameTypo sx={{ color: "rgba(37, 37, 37, 0.7)" }}>
                      {/* {index ? index : 0 + 1} stop */}
                      {index == 0 ? (
                        "non-stop"
                      ) : index > 0 ? (
                        <>{index} stop</>
                      ) : (
                        ""
                      )}
                    </AirlineNameTypo>
                  </Box>
                  <Box textAlign={"center"}>
                    <FlightTimeTypo>
                      {DateTime.fromISO(
                        detailsData?.Destination?.ArrTime
                      )?.toFormat("HH:mm")}
                    </FlightTimeTypo>
                    <DestinationTypo>
                      {detailsData?.Destination?.Airport?.CityName}{" "}
                      {detailsData?.Destination?.Airport?.Terminal.length >
                      0 ? (
                        <>
                          (Terminal{" "}
                          {detailsData?.Destination?.Airport?.Terminal})
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </DestinationTypo>
                    <DayAndDateTypo>
                      {DateTime.fromISO(
                        detailsData?.Destination?.ArrTime
                      )?.toFormat("EEE dd MMM")}
                    </DayAndDateTypo>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={3.2} lg={3}>
                <Box
                  sx={{
                    ml: { sm: 2.5 },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RefundButton>
                    <DownloadTicketButtonText>
                      Partial Refundable
                    </DownloadTicketButtonText>
                  </RefundButton>

                  <Box>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              maxWidth: { xs: "90%", sm: "428px" },
                              backgroundColor: "rgb(77,77,77)",
                            },
                          },
                        }}
                        open={open}
                        onClose={handleTooltipClose}
                        disableHoverListener
                        disableTouchListener
                        TransitionProps={{ timeout: 600 }}
                        TransitionComponent={Fade}
                        title={
                          <Box
                            sx={{
                              p: { sm: "8px 8px 8px 10px" },
                              width: "100%",
                            }}
                          >
                            <TooltipHeading>STANDARD</TooltipHeading>
                            <Box>
                              <Grid container columnSpacing={3}>
                                <Grid item xs={6}>
                                  <CancellationHeadingTypo>
                                    Cancellation fee per person
                                  </CancellationHeadingTypo>
                                  <Box>
                                    <Box
                                      className="textAlign"
                                      sx={{ justifyContent: "space-between" }}
                                    >
                                      <PolicyTypo>Airline Fee</PolicyTypo>
                                      <PolicyTypo>₹3,500</PolicyTypo>
                                    </Box>
                                    <Box
                                      className="textAlign"
                                      sx={{ justifyContent: "space-between" }}
                                    >
                                      <PolicyTypo>Cleartrip Fee</PolicyTypo>
                                      <PolicyTypo>₹400</PolicyTypo>
                                    </Box>
                                    <Box
                                      className="textAlign"
                                      sx={{ justifyContent: "space-between" }}
                                    >
                                      <PolicyTypo2>
                                        4 hours - 4 days
                                      </PolicyTypo2>
                                      <PolicyTypo2>₹3,500</PolicyTypo2>
                                    </Box>
                                    <Box
                                      className="textAlign"
                                      sx={{ justifyContent: "space-between" }}
                                    >
                                      <PolicyTypo2>
                                        4 days - 365 days
                                      </PolicyTypo2>
                                      <PolicyTypo2>₹3,000</PolicyTypo2>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <Box sx={{ width: "100%" }}>
                                    <CancellationHeadingTypo>
                                      Flight change fee per person
                                    </CancellationHeadingTypo>
                                    <Box>
                                      <Box
                                        className="textAlign"
                                        sx={{ justifyContent: "space-between" }}
                                      >
                                        <PolicyTypo>Airline Fee</PolicyTypo>
                                        <PolicyTypo>₹3,250</PolicyTypo>
                                      </Box>
                                      <Box
                                        className="textAlign"
                                        sx={{ justifyContent: "space-between" }}
                                      >
                                        <PolicyTypo>Cleartrip Fee</PolicyTypo>
                                        <PolicyTypo>₹400</PolicyTypo>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                              <CancellationInfoTypo>
                                Cancellation/Flight change charges are
                                indicative. Airline policy applicable during
                                cancellation/ flight change will be final. We
                                won't be able to accept cancellation/change
                                requests 3 hours before flight departure
                              </CancellationInfoTypo>
                            </Box>
                          </Box>
                        }
                        arrow
                        placement={`${smd ? "bottom" : "bottom-end"}`}
                      >
                        <DownloadTicketButtonText
                          sx={{ color: "#0075FF", mt: 1, cursor: "pointer" }}
                          onMouseEnter={handleTooltipOpen}
                          onMouseLeave={handleTooltipClose}
                        >
                          Know more
                        </DownloadTicketButtonText>
                      </Tooltip>
                    </ClickAwayListener>
                  </Box>
                  {detailsData.NoOfSeatAvailable > 0 && (
                    <DownloadTicketButtonText
                      sx={{ color: "rgba(0, 119, 26, 1)", mt: 1 }}
                    >
                      {detailsData.NoOfSeatAvailable}
                      {detailsData.NoOfSeatAvailable > 1 ? " Seats " : " Seat "}
                      Available
                    </DownloadTicketButtonText>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={{ xs: 0, md: 2 }}>
            <Grid container sx={{ rowGap: 2, overflow: "flex-wrap" }}>
              <Grid item>
                <ClassPerksButton>
                  <DownloadTicketButtonText>
                    {parseFloat(travelClass) === TravelClassEnum.ECONOMY
                      ? "Economy"
                      : parseFloat(travelClass) ===
                        TravelClassEnum.PREMIUM_ECONOMY
                      ? "Premium Economy"
                      : parseFloat(travelClass) === TravelClassEnum.BUSINESS
                      ? "Business Class"
                      : parseFloat(travelClass) === TravelClassEnum.FIRST_CLASS
                      ? "First Class"
                      : "Economy"}
                  </DownloadTicketButtonText>
                </ClassPerksButton>
              </Grid>
              {detailsData?.Baggage?.length > 0 && (
                <Grid item>
                  <ClassPerksButton>
                    <DownloadTicketButtonText>
                      Check-in baggage {detailsData?.Baggage} / Adult
                    </DownloadTicketButtonText>
                  </ClassPerksButton>
                </Grid>
              )}
              {detailsData?.CabinBaggage?.length > 0 && (
                <Grid item>
                  <ClassPerksButton>
                    <DownloadTicketButtonText>
                      Cabin baggage {detailsData?.CabinBaggage} / Adult
                    </DownloadTicketButtonText>
                  </ClassPerksButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </FlightCard>
    </div>
  );
}

export default ViewFlightInfoCard;
const FlightCard = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: "16px 24px 16px 24px",
  border: "1px solid rgba(37, 37, 37, 0.1)",
  borderRadius: 8,
  position: "relative",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FlightCardBorder = styled(Box)(({ theme }) => ({
  padding: "0 2px 2px 2px",
  border: "1px solid gray",
  borderTop: 0,
}));
const BookingTitleTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 16,
  fontWeight: 600,
  marginRight: 12,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const AirlineNameTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 14,
  fontWeight: 500,
}));
const DayAndDateTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.7)",
  fontSize: 12,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const CountryCodeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.7)",
  fontSize: 12,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FlightTimeTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 4,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const DestinationTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 12,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const TimeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.6)",
  fontSize: 14,
  fontWeight: 600,
  paddingBottom: 4,
  marginBottom: 4,
  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const RefundButton = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(232, 70, 0, 1)",
  backgroundColor: "rgba(232, 70, 0, 0.05)",
  borderRadius: 35,
  padding: "6px 16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // width: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: "8px 10px",
    width: 200,
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "8px 12px",
  },
}));
const ClassPerksButton = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  border: "1px solid rgba(232, 70, 0, 1)",
  backgroundColor: "rgba(232, 70, 0, 0.05)",
  borderRadius: 35,
  padding: "6px 18px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  marginRight: 14,
  [theme.breakpoints.down("sm")]: {
    padding: "6px 14px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "6px 16px",
  },
}));
const DownloadTicketButtonText = styled(Typography)(({ theme }) => ({
  color: "rgba(232, 70, 0, 1)",
  fontSize: 12,
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const NewLink = styled(Link)({
  textDecoration: "none",
});
const TooltipHeading = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 16,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const CancellationHeadingTypo = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: 12,
  fontWeight: 600,
  borderBottom: "1px dashed #b3b3b3",
  paddingBottom: 2,
  paddingRight: 12,
  marginBottom: 14,
  width: "max-content",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    width: "auto",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const PolicyTypo = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: 12,
  fontWeight: 400,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const PolicyTypo2 = styled(Typography)(({ theme }) => ({
  color: "#b3b3b3",
  fontSize: 10,
  fontWeight: 400,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const CancellationInfoTypo = styled(Typography)(({ theme }) => ({
  color: "#b3b3b3",
  fontSize: 12,
  fontWeight: 400,
  borderTop: "1px solid #b3b3b3",
  paddingTop: 14,
  marginTop: 10,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const LayoverText = styled(Box)(({ theme }) => ({
  color: "#000",
  backgroundColor: "#f7f7f7",
  fontSize: 12,
  fontWeight: 400,
  padding: "3px 10px",
  width: "20%",
  position: "absolute",
  top: -12,
  left: "50%",
  transform: "translate(-50%)",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  flexShrink: 0,
  minWidth: 180,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
