import MainLayout from "../../Component/Layout/MainLayout";
import { selectTmcData } from "../../Redux/Home/selector";
import { actions } from "../../Redux/Home/slice";
import { Container, Typography, styled } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

function RefundPolicy() {
  const dispatch = useDispatch();
  const privacyPolicy = useSelector(selectTmcData);
  React.useEffect(() => {
    dispatch(actions.doGetTmcData());
    return () => {};
  }, []);
  return (
    <>
      <MainLayout>
        <Container sx={{ minHeight: "calc(100vh - 200px)", pb: 4 }}>
          <TitleTypo>Refund Policy</TitleTypo>
          <DescriptionTypo
            dangerouslySetInnerHTML={{
              __html: privacyPolicy?.refundPolicyDescription
                ?.split("\n")
                ?.join("<br/>"),
            }}
          />
        </Container>
      </MainLayout>
    </>
  );
}

export default RefundPolicy;
const TitleTypo = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: 28,
  color: "rgba(0, 0, 0, 1)",
  marginTop: 36,
  marginBottom: 30,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    marginTop: 22,
    marginBottom: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 24,
    marginTop: 26,
    marginBottom: 18,
  },
}));
const DescriptionTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 20,
  color: "rgba(0, 0, 0, 1)",
  marginBottom: 20,
  textAlign: "unset",
  lineHeight: "29.3px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    marginBottom: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
