import { DateTime } from "luxon";
import {
  AuthState,
  FlightBookingDetailFilterEnum,
  GenderEnum,
  PassengerPaxTypeEnum,
  PassengerTitleEnum,
  RemarksTypeEnum,
  UserMaritalStatusEnum,
} from ".";

export const initialState: AuthState = {
  loading: false,
  search: "",
  token: null,
  isLogin: false,
  userData: {
    fullName: "",
    profilePic: "",
    email: "",
    id: "",
    // googleId: "",
  },
  loginModal: false,
  form: {
    email: "",
    fullName: "",
    pinCode: "",
    state: "",
    dob: DateTime.now().toISO(),
    address: "",
    maritalStatus: UserMaritalStatusEnum.UNMARRIED,
    gender: GenderEnum.MALE,
    phoneNo: "",
  },
  travellerForm: {
    isNoValid: false,
    honorifics: "Mr",
    Email: "",
    fullName: "",
    FirstName: "",
    LastName: "",
    DateOfBirth: "",
    PassportNo: "",
    PassportExpiry: "",
    CountryCode: "",
    CellCountryCode: "+91",
    ContactNo: "",
    type: 0,
    Gender: GenderEnum.MALE,
    _id: "",
    Title: PassengerTitleEnum.MR,
    checked: false,
    PaxType: PassengerPaxTypeEnum.Adult,
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    Nationality: "",
    IsLeadPax: true,
    FFAirlineCode: null,
    FFNumber: "",
    GSTCompanyAddress: "",
    GSTCompanyContactNumber: "",
    GSTCompanyName: "",
    GSTNumber: "",
    GSTCompanyEmail: "",
  },
  travellerList: [],
  tmc: true,
  passengerModal: false,
  EditIndex: -1,
  status: FlightBookingDetailFilterEnum.Upcoming,
  countryList: [],
  deleteModal: false,
  pageNo: 1,
  pageSize: 5,
  totalRow: 0,
  totalPage: 0,
  hasMore: false,
  bookingList: [],
  bookingCancel: {
    bookingId: "",
    Remarks: RemarksTypeEnum["My Plan Changed"],
    TicketIds: [],
  },
  bookingLoading: false,
  showUseDetailsModal: false,
  submitCancelPolicyModal: false,
  cancelPassengerList: [],
  setCancelPassengerList: { bookingId: "", userId: [] },
  CancelPassengerTotalBreakdown: {
    Fare: {
      AirlineCancellationFee: 0,
      AirlineFare: 0,
      ConvenienceFeeAmount: 0,
      RefundableAmount: 0,
      TotalAmount: 0,
      TotalCancellationCharge: 0,
      TrivzyCancellationFee: 0,
    },
    passenger: [],
    FlightDetails: { tripDate: "", source: "", destination: "" },
  },
  cancelBooking: false,
  cancelBookingTotalPassenger: 0,
  buttonLoading: false,
  stateList: []
};
