import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../types";
import { initialState } from "./types/initialState";

const selectDomain = (state: RootState) => {
  if (state && state.flightSearchState) {
    return state.flightSearchState;
  } else {
    return initialState;
  }
};
export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectGenList = createSelector(
  [selectDomain],
  (state) => state.genList
);
export const selectGenForm = createSelector(
  [selectDomain],
  (state) => state.genForm
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const SelectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectAirportList = createSelector(
  [selectDomain],
  (state) => state.airportList
);
export const SelectFlightSearchList = createSelector(
  [selectDomain],
  (state) => state.flightSearchList
);
export const SelectReturnFlightSearchList = createSelector(
  [selectDomain],
  (state) => state.returnFlightSearchList
);
export const SelectFlightBookingId = createSelector(
  [selectDomain],
  (state) => state.flightBookingId
);
export const SelectFormUpdate = createSelector(
  [selectDomain],
  (state) => state.formUpdate
);
export const SelectCouponList = createSelector(
  [selectDomain],
  (state) => state.couponList
);
