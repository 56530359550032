import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectLoading,
  selectBookingCancelForm,
  selectCancelBooking,
  selectCancelPassengerTotalBreakdown,
  selectSetCancelPassengerList,
  selectSubmitCancelPolicyModal,
} from "../../../Redux/Login/selector";
import { actions } from "../../../Redux/Login/slice";
import {
  Box,
  ClickAwayListener,
  Fade,
  Grid,
  Modal,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import theme from "../../../Style/theme";
import BookButton from "../../BookButton";
import WhiteButton from "../../BookButton/whiteButton";
import { GenderEnum, RemarksTypeEnum } from "../../../Redux/Login/types";
import RadioButtonNew from "../../RadioButtonNew";
import { DateTime } from "luxon";
import moment from "moment";
import CurrencyFormat from "../../CurrencyFormat";
import ContactUs from "../../ContactUs";
import { ContactUsTypeEnum } from "../../../Redux/Home/types";

function SubmitCancelTicket() {
  const dispatch = useDispatch();
  const show = useSelector(selectSubmitCancelPolicyModal);
  const data = useSelector(selectCancelPassengerTotalBreakdown);
  const form = useSelector(selectBookingCancelForm);

  const loader = useSelector(selectCancelBooking);
  const selectedPassenger = useSelector(selectSetCancelPassengerList);
  const handleClose = () => {
    dispatch(actions.clearCancelPassengerList());
    dispatch(actions.clearCancelBookingForm());
    dispatch(actions.clearCancelPassengerTotalBreakdownForm());
    dispatch(actions.clearSetCancelPassengerListForm());
    dispatch(actions.toggleSubmitCancelPolicyModal());
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateBookingCancelFormValue({ key: name, value: value }));
  };
  const handleCancelBooking = () => {
    dispatch(
      actions.updateBookingCancelFormValue({
        key: "bookingId",
        value: data.passenger[0].bookingId,
      })
    );
    dispatch(
      actions.updateBookingCancelFormValue({
        key: "TicketIds",
        value: selectedPassenger.userId,
      })
    );
    dispatch(
      actions.doCancelBooking({
        callback() {
          dispatch(actions.clearCancelBookingForm());
          dispatch(actions.clearCancelPassengerTotalBreakdownForm());
          dispatch(actions.clearSetCancelPassengerListForm());
          dispatch(actions.toggleSubmitCancelPolicyModal());
          dispatch(
            actions.doGetBookingList({
              callback() {},
            })
          );
        },
      })
    );
  };
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    if (open == false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleRadioFieldChange = (name: string, value: any) => {
    dispatch(actions.updateBookingCancelFormValue({ key: name, value: value }));
  };
  return (
    <div>
      <Modal open={show}>
        <Box sx={style}>
          <Grid container rowGap={{ xs: 2, sm: 3 }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid xs={12} md={12} lg={12}>
                  <DetailsHeading>Basic Details: </DetailsHeading>
                  <Box display={"flex"}>
                    <TitleHeading>
                      {data.FlightDetails.source} to&nbsp;
                      {data.FlightDetails.destination}&nbsp;
                      <span style={{ fontWeight: 400 }}>
                        (
                        {moment(data.FlightDetails.tripDate).format(
                          "ddd,DD MMM yyyy"
                        )}
                        )
                      </span>
                    </TitleHeading>
                  </Box>
                  {data.passenger.map((data) => {
                    return (
                      <Box display={"flex"}>
                        <PolicyTypo>
                          {data.FirstName} {data.LastName}&nbsp;
                          <>
                            (
                            {data.Gender === GenderEnum.MALE
                              ? "Male"
                              : data.Gender === GenderEnum.FEMALE
                              ? "Female"
                              : "Other"}
                            )
                          </>
                        </PolicyTypo>
                      </Box>
                    );
                  })}
                </Grid>
                <Grid xs={12} md={12} lg={12} mt={"20px"}>
                  <DetailsHeading>Fare Breakdown :</DetailsHeading>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box display={"flex"} justifyContent="space-between">
                        <PolicyTypo> Airline Fare : </PolicyTypo>
                        <PolicyTypo>
                          <CurrencyFormat value={data?.Fare?.AirlineFare} />
                        </PolicyTypo>
                      </Box>
                      <Box display={"flex"} justifyContent="space-between">
                        <PolicyTypo> Trivzy Convenience Fee : </PolicyTypo>
                        <PolicyTypo>
                          <CurrencyFormat
                            value={data?.Fare?.ConvenienceFeeAmount}
                          />
                        </PolicyTypo>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent="space-between"
                        mt={2}
                      >
                        <TitleHeading>Total Paid</TitleHeading>
                        <TitleHeading>
                          <CurrencyFormat value={data?.Fare?.TotalAmount} />
                        </TitleHeading>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display={"flex"} justifyContent="space-between">
                        <TitleHeading>Deductions</TitleHeading>
                        <TitleHeading>
                          <CurrencyFormat
                            value={data?.Fare?.TotalCancellationCharge}
                          />
                        </TitleHeading>
                      </Box>
                      <TitleHeading sx={{ borderBottom: "1px solid #d5d5d5" }}>
                        <PolicyTypo sx={{ color: "#9e9b9b" }}>
                          Cancellation & Service Charges
                        </PolicyTypo>
                      </TitleHeading>
                      <Box display={"flex"} justifyContent="space-between">
                        <PolicyTypo> Airline Cancellation Fare: : </PolicyTypo>
                        <PolicyTypo>
                          <CurrencyFormat
                            value={data?.Fare?.AirlineCancellationFee}
                          />
                        </PolicyTypo>
                      </Box>
                      <Box display={"flex"} justifyContent="space-between">
                        <PolicyTypo> Trivzy Cancellation Fee : </PolicyTypo>
                        <PolicyTypo>
                          <CurrencyFormat
                            value={data?.Fare?.TrivzyCancellationFee}
                          />
                        </PolicyTypo>
                      </Box>
                      {/* <PolicyTypo sx={{ mt: 1, color: "#9e9b9b" }}>
                        
                      </PolicyTypo> */}
                      <Box display={"flex"} justifyContent="space-between">
                        <PolicyTypo>
                          Non-Refundable Convenience Fee :
                        </PolicyTypo>
                        <PolicyTypo>
                          <CurrencyFormat
                            value={data?.Fare?.ConvenienceFeeAmount}
                          />
                        </PolicyTypo>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent="space-between"
                        mt={2}
                      >
                        <TitleHeading>Refund Amount</TitleHeading>
                        <TitleHeading sx={{ mr: 0 }}>
                          <CurrencyFormat
                            value={data?.Fare?.RefundableAmount}
                          />
                        </TitleHeading>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={6}
                className=""
                style={{ marginTop: "20px" }}
              >
                <DetailsHeading>Choose reason for cancellation</DetailsHeading>
                {RadioData.map((data, index) => {
                  return (
                    <div key={index} style={{ marginTop: "5px" }}>
                      <RadioButtonNew
                        label={data.name}
                        value={data.id}
                        name={"Remarks"}
                        forChecked={form.Remarks}
                        handleFieldChange={() =>
                          handleRadioFieldChange("Remarks", data.id)
                        }
                        className=""
                      />
                    </div>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className="textCenter"
              sx={{ columnGap: "28px" }}
            >
              <WhiteButton
                handleClick={handleClose}
                loading={false}
                title="Cancel"
              />
              <BookButton
                handleClick={handleCancelBooking}
                loading={loader}
                title="Submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default SubmitCancelTicket;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
  padding: "35px 40px",
  borderRadius: 3,
  // height: ,
  outline: 0,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "95%",
    padding: "20px 20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "auto",
    width: "70%",
    padding: "30px 30px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: "auto",
    width: "80%",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    height: "auto",
    width: "60%",
  },
};

const SpaceBox1 = styled(Box)(({ theme }) => ({
  marginBottom: 20,
}));
const FilterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const NameTypo = styled(Typography)(({ theme }) => ({
  color: "#050505",
  fontSize: 14,
  fontWeight: 600,
}));
const DetailsHeading = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 10,
  borderBottom: "1px solid #d5d5d5",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const TitleHeading = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: 14,
  fontWeight: 600,

  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const TooltipHeading = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 16,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const CancellationHeadingTypo = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: 12,
  fontWeight: 600,
  borderBottom: "1px dashed #b3b3b3",
  paddingBottom: 2,
  paddingRight: 12,
  marginBottom: 14,
  width: "max-content",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    width: "auto",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const PolicyTypo = styled(Typography)(({ theme }) => ({
  color: "#050505",
  fontSize: 14,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));

const RadioData = [
  { id: RemarksTypeEnum["My Plan Changed"], name: "My Plan Changed" },
  {
    id: RemarksTypeEnum["I directly cancelled with airline"],
    name: "I directly cancelled with airline",
  },
  {
    id: RemarksTypeEnum["Flight rescheduled by airline"],
    name: "Flight rescheduled by airline",
  },
  {
    id: RemarksTypeEnum["Flight cancelled by airline"],
    name: "Flight cancelled by airline",
  },
  {
    id: RemarksTypeEnum["due to medical emergency"],
    name: "Due to medical emergency",
  },
];
