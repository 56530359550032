import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage/Index";
import Booking from "./Pages/booking/Index";
import BookingConfirm from "./Pages/bookingConfirm/Index";
import FAQ from "./Pages/faq/Index";
import FlightListing from "./Pages/flightListing/Index";
import Offers from "./Pages/offers/Index";
import PrivacyPolicy from "./Pages/privacyPolicy/Index";
import Profile from "./Pages/profile/Index";
import RefundPolicy from "./Pages/refundPolicy/Index";
import TMC from "./Pages/tmc/Index";
import TravellersDenDetails from "./Pages/travellersDenDetails/Index";
import TravellersDen from "./Pages/travellersden/Index";
import BookingDoneLoading from "./Pages/BookingDoneLoading";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/booking-confirm" element={<BookingConfirm />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/flight-listing" element={<FlightListing />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/tmc" element={<TMC />} />
          <Route path="/travellers-den" element={<TravellersDen />} />
          <Route
            path="/travellers-den-details/:id"
            element={<TravellersDenDetails />}
          />
          <Route path="/booking-loading" element={<BookingDoneLoading />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
