import * as React from "react";
import {
  CardMedia,
  styled,
  Button,
  Typography,
  CardContent,
  Card,
  Box,
  Skeleton,
} from "@mui/material";

interface props {
  image: string;
  title: string;
  description: string;
  loading: boolean;
}

export default function BasicCard(props: props) {
  return (
    <>
      {props.loading ? (
        <Card sx={style}>
          <CardMedia>
            <Skeleton width="90%" height={"100%"} animation="wave" />
          </CardMedia>
          <CardContent sx={{ p: 3, pt: 2 }}>
            <Title gutterBottom>
              <Skeleton width="90%" height={40} animation="wave" />
            </Title>
            <DescriptionTypo>
              <Skeleton width="90%" height={40} animation="wave" />
            </DescriptionTypo>
          </CardContent>
        </Card>
      ) : (
        <Card sx={style}>
          <CardMedia>
            <img src={`${props.image}`} alt="" width={"100%"} />
          </CardMedia>
          <CardContent sx={{ p: 3, pt: 2 }}>
            <Title gutterBottom>{props.title}</Title>
            <DescriptionTypo
              dangerouslySetInnerHTML={{
                __html: `${props.description.length > 700
                  ? props.description.slice(0, 700) + "..."
                  : props.description
                  }`,
              }}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
}
const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
  color: "rgba(0, 0, 0, 1)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const DescriptionTypo = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  marginBottom: 12,
  color: "rgba(0, 0, 0, 1)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const style = {
  borderRadius: "15px",
  minWidth: 285,
  boxShadow: "0px 4px 2px 0px rgba(210, 210, 210, 0.37)",
  border: "1px solid rgba(0, 0, 0, 0.1)",
};
