import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { set } from "lodash";
import { IDiscount, requestDetailInterface, selectedFlightDataInterface } from "./types";

export const useClinicSlice = createSlice({
  name: "bookingState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    toggleBookingTimer: (state, action: PayloadAction<boolean>) => {
      // state.timerModal = !state.timerModal;
      state.timerModal = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSaveTraveler: (state, action: PayloadAction<boolean>) => {
      state.saveTraveler = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },

    doFlightSelect: (
      state,
      action: PayloadAction<{
        ResultIndex: string;
        ReturnResultIndex: string;
        TraceId: string;
        callback: () => void;
      }>
    ) => {},
    setSelectedFlightSearchList: (state, action: PayloadAction<any>) => {
      state.selectedFlightData = action.payload;
    },
    setFlightBookingId: (state, action: PayloadAction<string>) => {
      state.flightBookingId = action.payload;
    },
    setBookingTimer: (state, action: PayloadAction<string>) => {
      state.bookingTimer = action.payload;
    },
    setRazorePayFormData: (state, action: PayloadAction<any>) => {
      state.RazorePayForm = action.payload;
    },

    setSelectedFlightReturnData: (
      state,
      action: PayloadAction<selectedFlightDataInterface>
    ) => {
      state.selectedFlightReturnData = action.payload;
    },
    setRequestDetail: (
      state,
      action: PayloadAction<requestDetailInterface>
    ) => {
      state.requestDetail = action.payload;
    },
    doFlightSelectBookingPayment: (
      state,
      action: PayloadAction<{
        bookingId: string;
        callback: () => void;
      }>
    ) => {},
    updateSelectedFlightCardFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `selectedFlightCard.${action.payload.key}`,
        action.payload.value
      );
    },
    updateCouponFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `couponForm.${action.payload.key}`, action.payload.value);
    },
    doAddCouponCode: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    setCouponDiscount: (state, action: PayloadAction<IDiscount>) => {
      state.couponDiscount = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
