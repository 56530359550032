import {
  Container,
  Box,
  styled,
  Typography,
  AppBar,
  IconButton,
  Toolbar,
  CssBaseline,
  Drawer,
  Grid,
} from "@mui/material";
import React from "react";
import { actions } from "../../Redux/Login/slice";
import { actions as actionsSearch } from "../../Redux/flightSearch/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectIsLogin,
  SelectToken,
  selectUserDataForm,
} from "../../Redux/Login/selector";
import LoginModal from "../LoginModal";
import RoundedButton from "../Chip/RoundedButton";
import ProfileSelector from "./ProfileSelector";
import { Link } from "react-router-dom";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  hasBorder: boolean;
  maxWidth: string;
}
const drawerWidth = "50%";
const navItems = [
  { page: "Offers", linkTo: "/" },
  { page: "Travellers Den", linkTo: "/" },
];

function Navbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };
  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch(actions.toggleOpenLogin(true));
  };

  const userDetails = useSelector(selectUserDataForm);
  const isLogin = useSelector(SelectIsLogin);
  const loginToken = useSelector(SelectToken);

  React.useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(actions.setToken(token || ""));

    return () => {};
  }, []);
  React.useEffect(() => {
    console.log("isLogin", isLogin);

    return () => {};
  }, [isLogin]);

  React.useEffect(() => {
    fetchMe();

    return () => {};
  }, [loginToken]);

  const fetchMe = () => {
    dispatch(
      actions.doGetProfile({
        token: loginToken || "",
        callback: () => {},
      })
    );
  };

  const drawer = (
    <Box onClick={handleDrawerOpen}>
      <Container>
        <Grid container>
          {navItems.map((data: any) => (
            <Grid item xs={12} md={3}>
              {data.page}
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const handleClearSearch = () => {
    dispatch(actionsSearch.clearSearchFlightForm());
  };
  return (
    <>
      <Box>
        <CssBaseline />
        <AppBar
          position="static"
          component="nav"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            pt: { xs: 2, md: 2 },
            pb: 2,
            boxShadow: "0 5px 24px rgba(0,0,0,.06)",

            // props.hasBorder == true ? "0 2px 24px rgba(0,0,0,.04)" : 0
          }}
        >
          <Toolbar sx={{ minHeight: "auto !important" }}>
            <Container
              sx={{
                display: "flex",
                maxWidth: `${props.maxWidth}`,
                px: { xs: "0px !important", lg: "inherit !important" },
              }}
            >
              <NewLink to={"/"}>
                <IconButton
                  disableRipple
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleClearSearch}
                  sx={{
                    p: 0,
                    ml: "-5px",
                    "&: hover": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <img
                    src="/images/brandIcon.svg"
                    alt="Trivzy"
                    className="brandIcon"
                  />
                </IconButton>
              </NewLink>

              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                }}
              >
                <NewLink to={"/offers"}>
                  <NavbarText>Offers</NavbarText>
                </NewLink>
                <NewLink to={"/travellers-den"}>
                  <NavbarText>Travellers Den</NavbarText>
                </NewLink>
                {!isLogin ? (
                  <RoundedButton
                    fontSize="14px"
                    onClick={handleOpen}
                    backgroundColor="rgba(219, 56, 60, 0.05)"
                    color="#DE3151"
                    title="Login"
                    marginRight="0px"
                  />
                ) : (
                  <ProfileSelector profileData={userDetails} />
                )}
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                }}
              >
                {!isLogin ? (
                  <RoundedButton
                    fontSize="14px"
                    onClick={handleOpen}
                    backgroundColor="rgba(219, 56, 60, 0.05)"
                    color="#DE3151"
                    title="Login"
                  />
                ) : (
                  <ProfileSelector profileData={userDetails} />
                )}
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
        <Box component={"nav"} sx={{ display: "none" }}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              // display: { xs: "block", md: "none" },
              zIndex: 10,
              "& .MuiDrawer-paper": {
                backgroundColor: "rgba(255, 255, 255, 0.15)",
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                backgroundColor: "rgba(255, 255, 255, 0.15)",
              }}
            >
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  // justifyContent: "right",
                  padding: 2,
                  width: "60px",
                  marginTop: "15px",
                  marginLeft: { xs: "2.5%", md: "3%", lg: "2.5%" },
                  "&: hover": {
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                {/* <img src="/images/closeDrawer.svg" alt="" width={"100%"} /> */}
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: "flex",
                  mr: { xs: 1, sm: 13 },
                  mt: 1.5,
                  alignItems: "center",
                }}
              >
                {drawer}
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>
      <LoginModal />
    </>
  );
}

export default Navbar;

const NavbarText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  color: "rgba(37, 37, 37, 1)",
  cursor: "pointer",
  marginRight: 40,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));

const NewLink = styled(Link)({
  textDecoration: "none",
});
