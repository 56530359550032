import {
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  styled,
  Box,
  Typography,
} from "@mui/material";

import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
// import { useStyles } from "./style";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { selectGenForm } from "../../Redux/flightSearch/selector";
import { actions } from "../../Redux/flightSearch/slice";

interface Props {
  space?: boolean;
}

function Index(props: Props) {
  const [personName, setPersonName] = React.useState<any>([]);
  const dispatch = useDispatch();
  const form = useSelector(selectGenForm);
  const handleChange = (event: any, value: any, type: any) => {
    if (event.key == "adults") {
      dispatch(
        actions.updateGenFormValue({
          value: type == 1 ? value + 1 : value == 1 ? 1 : value - 1,
          key: event.key,
        })
      );
    } else {
      dispatch(
        actions.updateGenFormValue({
          value: type == 1 ? value + 1 : value - 1,
          key: event.key,
        })
      );
    }
  };
  React.useEffect(() => {
    var data = Object.values(form);
    setPersonName([data]);
  }, [form]);

  const handleChangeFields = (event: any) => {};

  //   const style = useStyles();

  return (
    <div>
      <FormControl fullWidth className={"style.muiSelector"}>
        <Select
          multiple
          //   sx={{ width: { xs: "100% !important", md: "100%" } }}
          displayEmpty
          value={personName}
          onChange={handleChangeFields}
          input={props.space ? <BootstrapInput1 /> : <BootstrapInput />}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "& .MuiSelect-icon": {
              display: "none !important",
            },
            "& .MuiSelect-select": {
              paddingRight: "16.5px !important",
            },
            " fieldset": {
              border: "none !important",
              outline: "none !important",
            },
          }}
          className="fw-semibold"
          renderValue={(selected: any) => {
            if (selected?.length == 0) {
              return <em>Select Passengers</em>;
            }
            return selected?.map((item: any, i: number) => {
              return item
                .map((it: number, k: number) => {
                  return it?.toString() + " " + passengersData[k]?.name;
                })
                .join(", ");
            });
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {passengersData.map((data, i: number) => {
            return (
              <MenuItem
                value={data?.id}
                key={i}
                className="bg-white"
                disableRipple
                disableTouchRipple
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <FormatOptionLabel
                  key={data.id}
                  passengersData={data}
                  onChange={handleChange}
                  value={get(form, data.key, "")}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default Index;

const FormatOptionLabel = ({ passengersData, onChange, value }: any) => {
  React.useEffect(() => {}, [passengersData]);
  return (
    <Box
      className="textAlign"
      sx={{ justifyContent: "space-between", width: "100%" }}
    >
      <Box>
        <PassengerCategoryTypo>{passengersData?.name}</PassengerCategoryTypo>
        <YearsTypo>{passengersData?.ageCategory}</YearsTypo>
      </Box>
      <AddBox className="textCenter">
        {value == 0 ? (
          <Remove
            sx={{
              mr: 1,
              color: "#D5D5D5",
              fontSize: "16px",
            }}
          />
        ) : (
          <Remove
            sx={{
              mr: 1,
              color: "rgba(222, 49, 81, 1)",
              fontSize: "16px",
            }}
            onClick={() => {
              onChange(passengersData, value, 0);
            }}
          />
        )}
        <PassengerCategoryTypo>{value}</PassengerCategoryTypo>
        <Add
          sx={{ ml: 1, color: "rgba(222, 49, 81, 1)", fontSize: "16px" }}
          onClick={() => {
            onChange(passengersData, value, 1);
          }}
        />
      </AddBox>
    </Box>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const passengersData = [
  {
    id: 0,
    name: "Adults",
    ageCategory: `(12+ yrs)`,
    key: "adults",
  },
  {
    id: 1,
    name: "Children",
    ageCategory: `(2-12 yrs)`,
    key: "children",
  },
  {
    id: 2,
    name: "Infants",
    ageCategory: `(Below 2 yrs)`,
    key: "infants",
  },
];

const BootstrapInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 12,
    padding: "9.5px 14px 9.5px 0px",
  },
}));
const BootstrapInput1 = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 12,
    padding: "11.5px 14px 6px 14px",
  },
}));
const YearsTypo = styled(Typography)(({ theme }) => ({
  fontSize: 8,
  color: "rgba(37, 37, 37, 0.6)",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {},
}));
const PassengerCategoryTypo = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: "rgba(37, 37, 37, 1)",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const AddBox = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(222, 49, 81, 0.1)",
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  borderRadius: 8,
  padding: "0px 8px",
  marginLeft: 10,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
  },
}));
