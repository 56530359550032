import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB6Cessa_qDABxgO63eTJD3rhE2Z9o2BBo",
  authDomain: "trivzy-702c3.firebaseapp.com",
  projectId: "trivzy-702c3",
  storageBucket: "trivzy-702c3.appspot.com",
  messagingSenderId: "515903791557",
  appId: "1:515903791557:web:490984ec46fb97a78b6bac",
  measurementId: "G-0VBBNRSPVV",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => signInWithPopup(auth, provider);

export default app;
