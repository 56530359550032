import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useStyles } from "./style";

interface props {
  type: string;
  name: string;
  placeHolder: string;
  value: string;
  className: string;
  onChange: any;
  disabled: any;
}

export default function Index(props: props) {
  const style = useStyles();
  // fix mouse scroll
  const textFieldRef = React.useRef(null);
  React.useEffect(() => {
    const handleWheel = (e: any) => {
      e.preventDefault();
    };
    //@ts-ignore
    textFieldRef.current?.addEventListener("wheel", handleWheel);

    return () => {
      //@ts-ignore
      textFieldRef.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener("wheel", function (event: any) {
      event.currentTarget.blur();

      //@ts-ignore
      if (window.document.activeElement.type === "number") {
        event.preventDefault();
        event.target.blur();
      }
    });
    window.addEventListener("keydown", function (event: any) {
      event.currentTarget.blur();

      if (event.keyCode === 40) {
        event.preventDefault();
        event.target.blur();
      }
    });
    window.addEventListener("mousewheel", function (event: any) {
      //@ts-ignore
      if (window.document.activeElement.type === "number") {
        event.preventDefault();
        event.target.blur();
      }
    });
  }, []);

  return (
    <Box
      component="form"
      // sx={{
      //   "& > :not(style)": { m: 1 },
      // }}
      noValidate
      autoComplete="off"
    >
      <TextField
        multiline rows={3}
        fullWidth
        size="small"
        variant="outlined"
        type={props.type}
        name={props.name}
        placeholder={props.placeHolder}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        className={`${props.className} ${style.muiTextField}`}
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "12.5px 14px !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px !important",
            borderColor: "#e5e5e5 !important",
          },
        }}
      />
    </Box>
  );
}
