import Card from "../../Component/Card";
import MainLayout from "../../Component/Layout/MainLayout";
import {
  Container,
  styled,
  Grid,
  SelectChangeEvent,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import Selector from "../../Component/Selector/index2";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBlogDetails,
  selectBlogList,
  selectLoading,
  selectPageNo,
  selectTotalPage,
} from "../../Redux/Home/selector";
import { actions } from "../../Redux/Home/slice";
import { BlogCategoryEnum } from "../../Redux/Home/types";
import Pagination from "../../Component/Pagination";
import { Link } from "react-router-dom";

function TravellersDen() {
  const handleChange = (evt: SelectChangeEvent) => {
    dispatch(actions.setBlogFilter(evt.target.value));
  };
  const handlePlus = (value: number) => {
    dispatch(actions.setPageNo(value + 1));
  };
  const handleMinus = (value: number) => {
    dispatch(actions.setPageNo(value - 1));
  };
  const handlePageChange = (event: any, value: number) => {
    dispatch(actions.setPageNo(value));
  };
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const list = useSelector(selectBlogList);
  const form = useSelector(selectBlogDetails);
  const pageNo = useSelector(selectPageNo);
  const TotalPage = useSelector(selectTotalPage);
  React.useEffect(() => {
    dispatch(actions.doGetBlogList());
    return () => {};
  }, [form.category]);
  return (
    <>
      <MainLayout>
        <Container sx={{ py: 4 }}>
          <Grid container>
            <Grid item xs={7} sm={9} md={10} />
            <Grid item xs={5} sm={3} md={2}>
              <Selector
                name={"category"}
                value={form.category}
                onChange={handleChange}
                data={filterData}
                disabled={false}
                placeholder={""}
              />
            </Grid>
          </Grid>
        </Container>
        <NewContaier>
          {list.length > 0 ? (
            <Grid container columnSpacing={10} rowSpacing={6}>
              {list.map((data, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Link
                    to={`/travellers-den-details/${data._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card
                      image={"/images/flight.png"}
                      title={data.title}
                      description={data.description}
                      loading={loading}
                    />
                  </Link>
                </Grid>
              ))}

              <Pagination
                count={TotalPage}
                handleChange={handlePageChange}
                handleMinus={handleMinus}
                handlePlus={handlePlus}
                pageNo={pageNo}
              />
            </Grid>
          ) : (
            <Box sx={{ textAlign: "center", my: 5, py: 5 }}>
              <Typography
                sx={{ color: "#ccad2f", fontSize: 12, fontWeight: 500 }}
              ></Typography>
            </Box>
          )}
        </NewContaier>
      </MainLayout>
    </>
  );
}

export default TravellersDen;

const filterData = [
  {
    _id: BlogCategoryEnum.All,
    item: "All",
  },
  {
    _id: BlogCategoryEnum.TRENDING,
    item: "Trending",
  },
  {
    _id: BlogCategoryEnum.NON_TRENDING,
    item: "Non Trending",
  },
];
const NewContaier = styled(Container)(({ theme }) => ({
  paddingBottom: 70,
  minHeight: "calc(100vh - 205px)",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: 40,
  },
}));
