import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, IconButton, styled } from "@mui/material";
import theme from "../../Style/theme";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../Redux/Login/slice";
import { SelectLoginModal } from "../../Redux/Login/selector";
import Close from "@mui/icons-material/Cancel";
import WhiteButton from "../BookButton/whiteButton";
import BookButton from "../BookButton";

interface Props {
  show: boolean;
  handleClose: any;
  handleDelete: any;
  deleteId: string;
  loading: boolean;
}
export default function Index(props: Props) {
  const open = useSelector(SelectLoginModal);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.toggleOpenDelete());
  };

  return (
    <>
      <Modal open={props.show}>
        <Box sx={style}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleClose}>
                  <Close
                    sx={{
                      color: "rgba(222, 49, 81, 1)",
                      cursor: "pointer",
                      "&: hover": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  />
                </IconButton>
              </Box>
              <DeleteText>Do you want to delete?</DeleteText>
            </Grid>
            <Grid xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    paddingRight: 15,
                  }}
                >
                  <WhiteButton
                    handleClick={props.handleClose}
                    loading={false}
                    title="Cancel"
                  />
                </Grid>
                <Grid item xs={6}>
                  <BookButton
                    handleClick={() => props.handleDelete(props.deleteId)}
                    loading={props.loading}
                    title="Delete"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
  padding: "10px 20px",
  borderRadius: 3,
  height: 200,
  outline: 0,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "95%",
    padding: "20px 20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "auto",
    width: "70%",
    padding: "30px 30px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: "auto",
    width: "80%",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    height: "auto",
    width: "60%",
  },
};

const DeleteText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 500,
  color: "rgba(37, 37, 37, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // marginTop: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    display: "block",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 18,
    display: "block",
  },
}));
