import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "../Selector/style";
import { actions } from "../../Redux/flightSearch/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectAirportList,
  SelectLoading,
  selectForm,
} from "../../Redux/flightSearch/selector";
import { useLocation } from "react-router-dom";
import { AirportListInterface } from "../../Redux/flightSearch/types";
import theme from "../../Style/theme";

const FlightListingAirportSearch: React.FC = () => {
  const airportList = useSelector(SelectAirportList);
  const isLoading = useSelector(SelectLoading);
  const [listSearch, setListSearch] = useState(0);
  const form = useSelector(selectForm);
  const dispatch = useDispatch();
  const location = useLocation();
  const smd = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const destination = params.get("destination");
    const source = params.get("source");
    const sourceLocation = params.get("sourceLocation");
    const destinationLocation = params.get("destinationLocation");
    if (destination) {
      setDestinationValue({
        airportDesc: "",
        airportCode: destination,
        city: destinationLocation,
      });
    }
    if (source) {
      setSourceValue({
        airportDesc: "",
        airportCode: source,
        city: sourceLocation,
      });
    }
  }, [location.search]);
  const sourceId = `autoCompleteTextField${"From"}`;
  const destinationId = `autoCompleteTextField${"To"}`;
  const [sourceValue, setSourceValue] = React.useState<any | null>(null);
  const [destinationValue, setDestinationValue] = React.useState<any | null>(
    null
  );
  const style = useStyles();
  const handleSelectAirportSource = (newValue: any) => {
    if (newValue != null && newValue != undefined) {
      setSourceValue(newValue);
      dispatch(
        actions.updateFormValue({
          key: "source",
          value: newValue?.airportCode,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "sourceLocation",
          value: newValue?.city,
        })
      );
      dispatch(actions.doGetAirportList(" "));
    }
    return;
  };
  const handleSelectAirportDestination = (newValue: any) => {
    if (newValue != null && newValue != undefined) {
      setDestinationValue(newValue);
      dispatch(
        actions.updateFormValue({
          key: "destination",
          value: newValue?.airportCode,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "destinationLocation",
          value: newValue?.city,
        })
      );
      dispatch(actions.doGetAirportList(" "));
    }
    return;
  };

  const handleSearch = (evt: any, value: any, type: number) => {
    dispatch(actions.doGetAirportList(value));
    setListSearch(type);
  };
  const handleInterChange = () => {
    const temp = sourceValue;
    setSourceValue(destinationValue);
    setDestinationValue(temp);
    dispatch(
      actions.updateFormValue({ key: "source", value: form.destination })
    );
    dispatch(
      actions.updateFormValue({ key: "destination", value: form.source })
    );
  };
  useEffect(() => {
    if (listSearch !== 0) {
      setTimeout(() => {
        setListSearch(0);
      }, 1000);
    }

    return () => {};
  }, [listSearch]);
  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12} sm={5.3}>
        <Grid container className="border">
          <Grid item xs={2} sm={3} md={3} className="textCenter">
            <img src="/images/aeroplane-takeoff.svg" alt="" />
          </Grid>
          <Grid item xs={10} sm={9} md={9}>
            <Autocomplete
              fullWidth
              size="small"
              loading={listSearch == 1 && isLoading}
              className={style.MuiAutoComplete}
              autoHighlight
              onInputChange={(e, value) => handleSearch(e, value, 1)}
              value={sourceValue}
              filterOptions={(x) => x}
              onChange={(event, newValue) => {
                setSourceValue(newValue);
                if (newValue) {
                  handleSelectAirportSource(newValue);
                }
              }}
              isOptionEqualToValue={(option, newValue) => {
                return option.airportCode === newValue.airportCode;
              }}
              options={airportList}
              getOptionLabel={(option: AirportListInterface) => {
                return `${
                  option?.airportCode == sourceValue
                    ? `${option.airportCode} ${option.city} ${option.airportDesc}`
                    : `${option.airportCode} ${option.city}`
                }`;
              }}
              componentsProps={{
                popper: {
                  sx: { width: { xs: "100%", sm: "auto !important" } },
                },
              }}
              PaperComponent={({ children }) => <Paper>{children}</Paper>}
              sx={{
                borderRadius: 4,
                "& .MuiAutocomplete-input": {
                  padding: "7.5px 2.5px !important",
                },
              }}
              renderOption={(renderProps, option: any, { selected }) => {
                return (
                  <li
                    {...renderProps}
                    style={{
                      cursor: "pointer",
                      fontSize: 14,
                      marginTop: 4,
                      textTransform: "capitalize",
                    }}
                    key={option?._id}
                  >
                    {option?.airportCode == destinationValue ? (
                      <>
                        <CityCodeTypo>{`${option.airportCode}`}</CityCodeTypo>
                        {option.city.toLowerCase()} -{" "}
                        {option.airportDesc?.length > (smd ? 15 : 21)
                          ? option.airportDesc.slice(0, smd ? 15 : 21) + "..."
                          : option.airportDesc}
                      </>
                    ) : (
                      <>
                        <CityCodeTypo>{`${option.airportCode}`}</CityCodeTypo>
                        {option.city.toLowerCase()} -{" "}
                        {option.airportDesc?.length > (smd ? 15 : 21)
                          ? option.airportDesc.slice(0, smd ? 15 : 21) + "..."
                          : option.airportDesc}
                      </>
                    )}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    borderRadius: "4px !important",
                    backgroundColor: "#fff",
                    position: "relative",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0px !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {listSearch == 1 && isLoading ? (
                          <CircularProgress
                            sx={{ color: "#D2D3E0" }}
                            size={20}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  id={sourceId}
                  placeholder={"From"}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={1.4} className="textCenter">
        <Box className="bidirectionalIconBox">
          <Box sx={{ position: "relative" }}>
            <img
              src="/images/arrow-swap.svg"
              alt=""
              className="ArrowIcon"
              onClick={handleInterChange}
            />
            <img
              src="/images/circel.svg"
              alt=""
              className="circleIcon"
              onClick={handleInterChange}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5.3}>
        <Grid container className="border">
          <Grid item xs={2} sm={3} md={3} className="textCenter">
            <img src="/images/aeroplane-landing.svg" alt="" />
          </Grid>
          <Grid item xs={10} sm={9} md={9}>
            <Autocomplete
              fullWidth
              size="small"
              loading={listSearch == 2 && isLoading}
              className={style.MuiAutoComplete}
              autoHighlight
              onInputChange={(e, value) => handleSearch(e, value, 2)}
              value={destinationValue}
              filterOptions={(x) => x}
              onChange={(event, newValue) => {
                setDestinationValue(newValue);
                if (newValue) {
                  handleSelectAirportDestination(newValue);
                }
              }}
              isOptionEqualToValue={(option, newValue) => {
                return option.airportCode === newValue.airportCode;
              }}
              options={airportList}
              getOptionLabel={(option: AirportListInterface) => {
                return `${
                  option?.airportCode == destinationValue
                    ? `${option.airportCode} ${option.city} ${option.airportDesc}`
                    : `${option.airportCode} ${option.city}`
                }`;
              }}
              componentsProps={{
                popper: {
                  sx: { width: { xs: "100%", sm: "auto !important" } },
                },
              }}
              PaperComponent={({ children }) => <Paper>{children}</Paper>}
              sx={{
                borderRadius: 4,
                "& .MuiAutocomplete-input": {
                  padding: "7.5px 2.5px !important",
                },
              }}
              renderOption={(renderProps, option: any, { selected }) => {
                return (
                  <li
                    {...renderProps}
                    style={{
                      cursor: "pointer",
                      fontSize: 14,
                      marginTop: 4,
                      textTransform: "capitalize",
                    }}
                    key={option?._id}
                  >
                    {option?.airportCode == destinationValue ? (
                      <>
                        <CityCodeTypo>{`${option.airportCode}`}</CityCodeTypo>
                        {option.city.toLowerCase()} -{" "}
                        {option.airportDesc?.length > (smd ? 15 : 21)
                          ? option.airportDesc.slice(0, smd ? 15 : 21) + "..."
                          : option.airportDesc}
                      </>
                    ) : (
                      <>
                        <CityCodeTypo>{`${option.airportCode}`}</CityCodeTypo>
                        {option.city.toLowerCase()} -{" "}
                        {option.airportDesc?.length > (smd ? 15 : 21)
                          ? option.airportDesc.slice(0, smd ? 15 : 21) + "..."
                          : option.airportDesc}
                      </>
                    )}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    borderRadius: "4px !important",
                    backgroundColor: "#fff",
                    position: "relative",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0px !important",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {listSearch == 2 && isLoading ? (
                          <CircularProgress
                            sx={{ color: "#D2D3E0" }}
                            size={20}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  id={destinationId}
                  placeholder={"To"}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FlightListingAirportSearch;
const CityCodeTypo = styled(Box)(({ theme }) => ({
  color: "rgba(37, 37, 37, 1)",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  borderRadius: 6,
  padding: "4px 8px",
  cursor: "pointer",
  fontSize: 12,
  marginRight: 10,
  "&:hover": {
    backgroundColor: "rgba(219, 56, 60, 1)",
    color: "#fff",
  },
  [theme.breakpoints.down("sm")]: {},
}));
