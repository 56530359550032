import Api from "./Api";

//googleLogin
const googleLogin = async (data: any) => {
  return Api.post(`/user/google/login`, data);
};
// user profile
const MeProfileRequest = async (data?: any) => {
  return Api.get(`/user/me`, {
    headers: {
      Authorization: "Bearer " + (data ? data : localStorage.getItem("token")),
    },
  });
};
const ProfileUpdate = async (data: any) => {
  return Api.patch(`/user/profile/update`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetUserProfileRequest = async (data?: any) => {
  return Api.get(`/user/profile`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
//  Add traveller

const AddTravellers = async (data: any) => {
  return Api.post(`/passenger/create`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetTravellers = async () => {
  return Api.get(`/passenger`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetByIdTravellers = async (id: any) => {
  return Api.get(`/passenger/get/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const UpdateTravellers = async (data: any) => {
  return Api.put(`/passenger/update`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const DeleteTravellers = async (data: any) => {
  return Api.delete(`/passenger/delete/${data}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const GetCountryList = async () => {
  return Api.get(`/user/user/get/country`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
// flight Search
const SearchFlight = async (data: any) => {
  return Api.post(`/airports/available/flight`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};

const GetAirportList = async (filter: string) => {
  return Api.get(`/airports/search?keyword=${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
//Booking
const BookingFlightSelect = async (data: any) => {
  return Api.post(`/airports/get-tax-details`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const BookingFlightSelectPayment = async (data: any) => {
  return Api.post(`/payments/create/payment`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetFlightBooking = async (data: any) => {
  return Api.get(`/airports/get/all-flight-booking${data}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const CancelBooking = async (data: any) => {
  return Api.post(`/airports/cancel-flight-ticket`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const CancelBookingPassengerList = async (id: string) => {
  return Api.get(`/airports/get/passenger-list?bookingId=${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const CancelBookingCharges = async (data: any) => {
  return Api.post(`airports/flight/cancellation-charges`, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetTMCData = async () => {
  return Api.get(`/policy/get`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const UserContactUs = async (data: any) => {
  return Api.post(`/contact-us/create`, data);
};
const GetBlogData = async (filter: any) => {
  return Api.get(`/blog/list${filter}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetBlogById = async (id: string) => {
  return Api.get(`/blog/get/${id}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetOffersRequest = async (filter: any) => {
  return Api.get(`/offer/alloffer${filter}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetFaqRequest = async (filter: any) => {
  return Api.get(`/faq/allfaq${filter}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const GetCouponList = async (data: any) => {
  return Api.get(`/coupon/user/allCoupon?${data}`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const getStateList = async () => {
  return Api.get(`/user/user/get/states`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
const HomePageBanner = async () => {
  return Api.get(`homepage/banner/list`, {
    headers: {},
  });
};
const AddCoupon = async (data: any) => {
  return Api.post(`payments/apply-coupon `, data, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
};
export {
  GetFlightBooking,
  ProfileUpdate,
  googleLogin,
  MeProfileRequest,
  GetUserProfileRequest,
  AddTravellers,
  GetTravellers,
  GetByIdTravellers,
  UpdateTravellers,
  DeleteTravellers,
  GetAirportList,
  SearchFlight,
  BookingFlightSelect,
  BookingFlightSelectPayment,
  GetCountryList,
  CancelBooking,
  GetTMCData,
  UserContactUs,
  GetBlogData,
  GetBlogById,
  GetOffersRequest,
  GetFaqRequest,
  CancelBookingPassengerList,
  CancelBookingCharges,
  GetCouponList,
  getStateList,
  HomePageBanner,
  AddCoupon,
};
