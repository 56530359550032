import { fontSize } from "@mui/system";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  muiRadio: {
    "& .MuiButtonBase-root": {
      padding: "0 9px 0 0!important",
    },
    "& .muiRadio.css-ahj2mt-MuiTypography-roo": {
      fontSize: "14px",
    },
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
    "& .MuiFormControlLabel-labelPlacementEnd": {
      marginLeft: "0px",
    },

    "& .css-9l3uo3": {
      fontSize: "12px",
      fontWeight: "500",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
    },
  },
});
