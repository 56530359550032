// import { fontSize } from "@mui/system";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    MuiAutoComplete: {
        "& .MuiOutlinedInput-root": {
            zIndex: "3 !important",
            padding: "5px 5px 5px 0px !important",
            fontSize: "12px !important"
            // height: "100% !important",
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none !important',
          },
        '& .MuiTextField-root': {
            background:"none !important",
          },
    },
});