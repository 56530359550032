import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
  Container,
  styled,
} from "@mui/material";
import MainLayout from "../../Component/Layout/MainLayout";
import Selector from "../../Component/Selector/index2";
import BookingTab from "../../Component/Profile/BookingTab";
import ProfileTab from "../../Component/Profile/ProfileTab";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBookingList,
  selectUserDataForm,
  selectStatus,
  selectBookingLoading,
} from "../../Redux/Login/selector";
import { actions } from "../../Redux/Login/slice";
import TravellerList from "../../Component/Profile/TravellerList";

import { FlightBookingDetailFilterEnum } from "../../Redux/Login/types";
import { createSearchParams, useNavigate } from "react-router-dom";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Profile() {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const [bookingLoading, setBookingLoading] = React.useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFilterChange = (evt: any) => {
    const { value } = evt.target;
    dispatch(actions.setStatus(value));
    setBookingLoading(true);
    // dispatch(actions.setBookingLoading(true));
  };
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDataForm);
  React.useEffect(() => {
    dispatch(actions.doGetUserProfile());
    return () => {};
  }, []);

  const status = useSelector(selectStatus);
  const loading = useSelector(selectBookingLoading);

  React.useEffect(() => {
    dispatch(
      actions.doGetBookingList({
        callback() {
          setBookingLoading(false);
        },
      })
    );
    dispatch(actions.setPageNo(1));
    return () => {};
  }, [status]);

  const handleSupport = () => {
    window.location.href =
      "mailto:support@gmail.com?subject=Subject&body=message%20goes%20here";
  };
  const params = new URLSearchParams(window.location.search);
  const index = params.get("index");
  React.useEffect(() => {
    setValue(Number(index));
  }, []);
  const tabSwitch = (index: number, tabName: string) => {
    const params = {
      index: String(index),
      tab: tabName,
    };
    navigate({
      pathname: "/profile",
      search: `?${createSearchParams(params)}`,
    });
  };

  React.useEffect(() => {
    let token = localStorage.getItem("token");

    if (!token) {
      navigate("/");
    }
    return () => {};
  }, []);

  return (
    <MainLayout hasFooter={false}>
      <HeadingContainer>
        <Grid container>
          {value == 0 && (
            <>
              <Grid item xs={7} sm={9} md={10} className="textAlign">
                <TitleTypo>
                  Hello {userDetails?.fullName.split(" ")[0]} 👋
                </TitleTypo>
              </Grid>
              <Grid item xs={5} sm={3} md={2}>
                <Selector
                  name={"status"}
                  value={status}
                  onChange={handleFilterChange}
                  data={filterData}
                  disabled={false}
                  placeholder={"Select Booking Type"}
                />
              </Grid>
            </>
          )}
        </Grid>
        {value !== 0 && (
          <Grid container>
            <Grid item xs={12} sm={3.6} md={3.5} lg={3.3} className="textAlign">
              <TitleTypo>
                Hello {userDetails?.fullName.split(" ")[0]} 👋
              </TitleTypo>
            </Grid>
            <Grid item xs={12} md={8.5} lg={8.7}>
              {value == 1 && (
                <TabHeadingTypo className="textAlign">
                  We will use this information to make booking smooth 😀
                </TabHeadingTypo>
              )}
              {value == 2 && (
                <TabHeadingTypo className="textAlign">
                  Save your fellow travellers information
                </TabHeadingTypo>
              )}
            </Grid>
          </Grid>
        )}
      </HeadingContainer>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={2}>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="vertical"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <NewTab
                label="Booking"
                sx={{ mt: 0 }}
                {...a11yProps(0)}
                onClick={() => tabSwitch(0, "booking")}
              />
              <NewTab
                label="Profile Info"
                {...a11yProps(1)}
                onClick={() => tabSwitch(1, "profile")}
              />
              <NewTab
                label="Add Travellers"
                {...a11yProps(2)}
                onClick={() => tabSwitch(2, "travelers")}
              />
              <NewTab
                label="Support"
                {...a11yProps(3)}
                onClick={handleSupport}
              />
            </Tabs>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="horizontal"
              sx={{ display: { xs: "block", md: "none" }, width: "100%" }}
            >
              <NewTab
                label="Booking"
                {...a11yProps(0)}
                onClick={() => tabSwitch(0, "booking")}
              />
              <NewTab
                label="Profile Info"
                {...a11yProps(1)}
                onClick={() => tabSwitch(1, "profile")}
              />
              <NewTab
                label="Add Travellers"
                {...a11yProps(2)}
                onClick={() => tabSwitch(2, "travelers")}
              />
              <NewTab
                label="Support"
                {...a11yProps(3)}
                onClick={handleSupport}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={10} sx={{ pl: { xs: 0, md: 4 } }}>
            <CustomTabPanel value={value} index={0}>
              <BookingTab loading={bookingLoading} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ProfileTab />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TravellerList />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  );
}

const filterData = [
  {
    _id: FlightBookingDetailFilterEnum.Upcoming,
    item: "Upcoming",
  },
  {
    _id: FlightBookingDetailFilterEnum.Past,
    item: "Past",
  },
  {
    _id: FlightBookingDetailFilterEnum.Cancel,
    item: "Cancel",
  },
  {
    _id: FlightBookingDetailFilterEnum.Payment_failed,
    item: "Payment Failed",
  },
  {
    _id: FlightBookingDetailFilterEnum.Refund,
    item: "Refund Amount",
  },
];
const TitleTypo = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: 24,
  color: "rgba(0, 0, 0, 1)",
  overflowWrap: "anywhere",
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 22,
  },
}));
const TabHeadingTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  color: "rgba(0, 0, 0, 1)",
  marginTop: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const HeadingContainer = styled(Container)(({ theme }) => ({
  marginTop: 35,
  marginBottom: 32,
  [theme.breakpoints.down("sm")]: {
    marginTop: 20,
    marginBottom: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginTop: 40,
    marginBottom: 8,
  },
}));
const NewTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  alignItems: "start",
  fontWeight: 600,
  fontSize: 19,
  color: "rgba(0, 0, 0, 1)",
  // marginTop: 10,
  borderBottom: "1px solid #e5e5e5",
  // marginBottom: 22,
  minHeight: "auto !important",
  "&.MuiTab-root.Mui-selected": {
    color: "#DE3151",
    border: "1px solid rgba(222, 49, 81, 1)",
    backgroundColor: "rgba(219, 56, 60, 0.05)",
    borderRadius: 7,
    padding: "11px 16px",
  },
  "& .MuiTouchRipple-root": {
    borderRadius: 7,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
