import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  sliceKey as AuthSliceKey,
  reducer as AuthReducer,
} from "../../Redux/Login/slice";
import { AuthRepoSaga } from "../../Redux/Login/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as BookingSliceKey,
  reducer as BookingReducer,
} from "../../Redux/Booking/slice";
import { BookingRepoSaga } from "../../Redux/Booking/saga";
import {
  sliceKey as FlightSearchSliceKey,
  reducer as FlightSearchReducer,
} from "../../Redux/flightSearch/slice";
import { FlightSearchRepoSaga } from "../../Redux/flightSearch/saga";
import {
  sliceKey as HomeSliceKey,
  reducer as HomeReducer,
} from "../../Redux/Home/slice";
import { HomeRepoSaga } from "../../Redux/Home/saga";
function MainLayout({
  children,
  hasBorder = true,
  hasFooter = true,
  maxWidth,
}: any) {
  useInjectReducer({ key: AuthSliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  useInjectReducer({ key: BookingSliceKey, reducer: BookingReducer });
  useInjectSaga({ key: BookingSliceKey, saga: BookingRepoSaga });
  useInjectSaga({ key: FlightSearchSliceKey, saga: FlightSearchRepoSaga });
  useInjectReducer({ key: FlightSearchSliceKey, reducer: FlightSearchReducer });
  useInjectReducer({ key: HomeSliceKey, reducer: HomeReducer });
  useInjectSaga({ key: HomeSliceKey, saga: HomeRepoSaga });
  return (
    <div>
      <Navbar hasBorder={hasBorder} maxWidth={maxWidth} />
      <div style={{ position: "relative" }}>{children}</div>
      {/* <Footer hasFooter={hasFooter} /> */}
    </div>
  );
}

export default MainLayout;
