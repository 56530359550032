import { Provider } from "react-redux";
import Injector from "./Injectors/Injectors";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { configureAppStore } from "../store/configureStore";
interface Props {
  children: any;
}
export function Application({ children }: Props) {
  const store = configureAppStore();
  return (
    <Provider store={store}>
       <ToastContainer />
      <Injector>{children}</Injector>
    </Provider>
  );
}
