import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import { actions as action } from "../../Redux/Login/slice";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

import {
  selectRequestDetail,
  selectSaveTraveler,
} from "../../Redux/Booking/selector";
import { actions } from "../../Redux/Booking/slice";
import CheckButton from "../CheckButton";
import {
  GenderEnum,
  PassengerPaxTypeEnum,
  TravellersInterface,
} from "../../Redux/Login/types";
import AddTravellerTab from "../Profile/AddTravellerTab/BookingTraveller";
import { toast } from "react-toastify";
import { requestDetailInterface } from "../../Redux/Booking/types";
import { useState } from "react";
import Chips from "../Chip";
import TravellerSkeletonLoading from "./travellerSkeletonLoading";
// import RoundedButton from "../Chip/RoundedButton";

interface Props {
  passengerData: TravellersInterface[];
  loading: boolean;
  requestData: requestDetailInterface;
}

function TravellerDetails(props: Props) {
  const dispatch = useDispatch();
  const requestData = useSelector(selectRequestDetail);
  const [viewAll, setViewAll] = useState(-1);

  const travellerSelect = (
    i: any,
    id: any,
    FirstName: any,
    LastName: any,
    PaxType: any,
    DateOfBirth: any,
    Gender: any,
    PassportNo: any,
    PassportExpiry: any,
    AddressLine1: any,
    AddressLine2: any,
    City: any,
    CountryCode: any,
    CellCountryCode: any,
    ContactNo: any,
    Nationality: any,
    Email: any,
    IsLeadPax: any,
    FFAirlineCode: any,
    FFNumber: any,
    GSTCompanyAddress: any,
    GSTCompanyContactNumber: any,
    GSTCompanyName: any,
    GSTNumber: any,
    GSTCompanyEmail: any,
    checked: boolean
  ) => {
    if (checked == false) {
      dispatch(
        action.selectTravellerForBooking({
          index: i,
          id: id,
          FirstName: FirstName,
          LastName: LastName,
          PaxType: PaxType,
          DateOfBirth: DateOfBirth,
          Gender: Gender,
          PassportNo: PassportNo,
          PassportExpiry: PassportExpiry,
          AddressLine1: AddressLine1,
          AddressLine2: AddressLine2,
          City: City,
          CountryCode: CountryCode,
          CellCountryCode: CellCountryCode,
          ContactNo: ContactNo,
          Nationality: Nationality,
          Email: Email,
          IsLeadPax: IsLeadPax,
          FFAirlineCode: FFAirlineCode,
          FFNumber: FFNumber,
          GSTCompanyAddress: GSTCompanyAddress,
          GSTCompanyContactNumber: GSTCompanyContactNumber,
          GSTCompanyName: GSTCompanyName,
          GSTNumber: GSTNumber,
          GSTCompanyEmail: GSTCompanyEmail,
          honorifics: "Mr",
          fullName: "",
          type: 0,
          _id: "",
          checked: checked,
          Title: "Mr",
        })
      );
    } else {
      const datalist = props?.passengerData?.filter(
        (data: any) => data.checked == true
      );
      const adult = datalist?.filter(
        (data) => data.PaxType == PassengerPaxTypeEnum.Adult
      );
      const Child = datalist?.filter(
        (data) => data.PaxType == PassengerPaxTypeEnum.Child
      );
      const Infant = datalist?.filter(
        (data) => data.PaxType == PassengerPaxTypeEnum.Infant
      );

      if (PaxType == PassengerPaxTypeEnum.Adult) {
        if (requestData.adults > adult.length) {
          dispatch(
            action.selectTravellerForBooking({
              index: i,
              id: id,
              FirstName: FirstName,
              LastName: LastName,
              PaxType: PaxType,
              DateOfBirth: DateOfBirth,
              Gender: Gender,
              PassportNo: PassportNo,
              PassportExpiry: PassportExpiry,
              AddressLine1: AddressLine1,
              AddressLine2: AddressLine2,
              City: City,
              CountryCode: CountryCode,
              CellCountryCode: CellCountryCode,
              ContactNo: ContactNo,
              Nationality: Nationality,
              Email: Email,
              IsLeadPax: IsLeadPax,
              FFAirlineCode: FFAirlineCode,
              FFNumber: FFNumber,
              GSTCompanyAddress: GSTCompanyAddress,
              GSTCompanyContactNumber: GSTCompanyContactNumber,
              GSTCompanyName: GSTCompanyName,
              GSTNumber: GSTNumber,
              GSTCompanyEmail: GSTCompanyEmail,
              honorifics: "Mr",
              fullName: "",
              type: 0,
              _id: "",
              checked: checked,
              Title: "Mr",
            })
          );
        } else {
          toast.error(
            `You can not select more then ${requestData.adults} adults`
          );
        }
      } else if (PaxType == PassengerPaxTypeEnum.Child) {
        if (requestData.children > Child.length) {
          dispatch(
            action.selectTravellerForBooking({
              index: i,
              id: id,
              FirstName: FirstName,
              LastName: LastName,
              PaxType: PaxType,
              DateOfBirth: DateOfBirth,
              Gender: Gender,
              PassportNo: PassportNo,
              PassportExpiry: PassportExpiry,
              AddressLine1: AddressLine1,
              AddressLine2: AddressLine2,
              City: City,
              CountryCode: CountryCode,
              CellCountryCode: CellCountryCode,
              ContactNo: ContactNo,
              Nationality: Nationality,
              Email: Email,
              IsLeadPax: IsLeadPax,
              FFAirlineCode: FFAirlineCode,
              FFNumber: FFNumber,
              GSTCompanyAddress: GSTCompanyAddress,
              GSTCompanyContactNumber: GSTCompanyContactNumber,
              GSTCompanyName: GSTCompanyName,
              GSTNumber: GSTNumber,
              GSTCompanyEmail: GSTCompanyEmail,
              honorifics: "Mr",
              fullName: "",
              type: 0,
              _id: "",
              checked: checked,
              Title: "Mr",
            })
          );
        }
      } else if (PaxType == PassengerPaxTypeEnum.Infant) {
        if (requestData.infants > Infant.length) {
          dispatch(
            action.selectTravellerForBooking({
              index: i,
              id: id,
              FirstName: FirstName,
              LastName: LastName,
              PaxType: PaxType,
              DateOfBirth: DateOfBirth,
              Gender: Gender,
              PassportNo: PassportNo,
              PassportExpiry: PassportExpiry,
              AddressLine1: AddressLine1,
              AddressLine2: AddressLine2,
              City: City,
              CountryCode: CountryCode,
              CellCountryCode: CellCountryCode,
              ContactNo: ContactNo,
              Nationality: Nationality,
              Email: Email,
              IsLeadPax: IsLeadPax,
              FFAirlineCode: FFAirlineCode,
              FFNumber: FFNumber,
              GSTCompanyAddress: GSTCompanyAddress,
              GSTCompanyContactNumber: GSTCompanyContactNumber,
              GSTCompanyName: GSTCompanyName,
              GSTNumber: GSTNumber,
              GSTCompanyEmail: GSTCompanyEmail,
              honorifics: "Mr",
              fullName: "",
              type: 0,
              _id: "",
              checked: checked,
              Title: "Mr",
            })
          );
        }
      }
    }
  };
  const travellerUpdate = (
    i: any,
    id: any,
    FirstName: any,
    LastName: any,
    PaxType: any,
    DateOfBirth: any,
    Gender: any,
    PassportNo: any,
    PassportExpiry: any,
    AddressLine1: any,
    AddressLine2: any,
    City: any,
    CountryCode: any,
    CellCountryCode: any,
    ContactNo: any,
    Nationality: any,
    Email: any,
    IsLeadPax: any,
    FFAirlineCode: any,
    FFNumber: any,
    GSTCompanyAddress: any,
    GSTCompanyContactNumber: any,
    GSTCompanyName: any,
    GSTNumber: any,
    GSTCompanyEmail: any,
    checked: boolean
  ) => {
    if (checked == false) {
      dispatch(
        action.selectTravellerForBooking({
          index: i,
          id: id,
          FirstName: FirstName,
          LastName: LastName,
          PaxType: PaxType,
          DateOfBirth: DateOfBirth,
          Gender: Gender,
          PassportNo: PassportNo,
          PassportExpiry: PassportExpiry,
          AddressLine1: AddressLine1,
          AddressLine2: AddressLine2,
          City: City,
          CountryCode: CountryCode,
          CellCountryCode: CellCountryCode,
          ContactNo: ContactNo,
          Nationality: Nationality,
          Email: Email,
          IsLeadPax: IsLeadPax,
          FFAirlineCode: FFAirlineCode,
          FFNumber: FFNumber,
          GSTCompanyAddress: GSTCompanyAddress,
          GSTCompanyContactNumber: GSTCompanyContactNumber,
          GSTCompanyName: GSTCompanyName,
          GSTNumber: GSTNumber,
          GSTCompanyEmail: GSTCompanyEmail,
          honorifics: "Mr",
          fullName: "",
          type: 0,
          _id: "",
          checked: checked,
          Title: "Mr",
        })
      );
      dispatch(action.toggleOpenPassenger());
    } else {
      const datalist = props?.passengerData?.filter(
        (data: any) => data.checked == true
      );
      const adult = datalist?.filter(
        (data) => data.PaxType == PassengerPaxTypeEnum.Adult
      );
      const Child = datalist?.filter(
        (data) => data.PaxType == PassengerPaxTypeEnum.Child
      );
      const Infant = datalist?.filter(
        (data) => data.PaxType == PassengerPaxTypeEnum.Infant
      );

      if (PaxType == PassengerPaxTypeEnum.Adult) {
        dispatch(
          action.selectTravellerForBooking({
            index: i,
            id: id,
            FirstName: FirstName,
            LastName: LastName,
            PaxType: PaxType,
            DateOfBirth: DateOfBirth,
            Gender: Gender,
            PassportNo: PassportNo,
            PassportExpiry: PassportExpiry,
            AddressLine1: AddressLine1,
            AddressLine2: AddressLine2,
            City: City,
            CountryCode: CountryCode,
            CellCountryCode: CellCountryCode,
            ContactNo: ContactNo,
            Nationality: Nationality,
            Email: Email,
            IsLeadPax: IsLeadPax,
            FFAirlineCode: FFAirlineCode,
            FFNumber: FFNumber,
            GSTCompanyAddress: GSTCompanyAddress,
            GSTCompanyContactNumber: GSTCompanyContactNumber,
            GSTCompanyName: GSTCompanyName,
            GSTNumber: GSTNumber,
            GSTCompanyEmail: GSTCompanyEmail,
            honorifics: "Mr",
            fullName: "",
            type: 0,
            _id: "",
            checked: checked,
            Title: "Mr",
          })
        );
        dispatch(action.toggleOpenPassenger());
      } else if (PaxType == PassengerPaxTypeEnum.Child) {
        dispatch(
          action.selectTravellerForBooking({
            index: i,
            id: id,
            FirstName: FirstName,
            LastName: LastName,
            PaxType: PaxType,
            DateOfBirth: DateOfBirth,
            Gender: Gender,
            PassportNo: PassportNo,
            PassportExpiry: PassportExpiry,
            AddressLine1: AddressLine1,
            AddressLine2: AddressLine2,
            City: City,
            CountryCode: CountryCode,
            CellCountryCode: CellCountryCode,
            ContactNo: ContactNo,
            Nationality: Nationality,
            Email: Email,
            IsLeadPax: IsLeadPax,
            FFAirlineCode: FFAirlineCode,
            FFNumber: FFNumber,
            GSTCompanyAddress: GSTCompanyAddress,
            GSTCompanyContactNumber: GSTCompanyContactNumber,
            GSTCompanyName: GSTCompanyName,
            GSTNumber: GSTNumber,
            GSTCompanyEmail: GSTCompanyEmail,
            honorifics: "Mr",
            fullName: "",
            type: 0,
            _id: "",
            checked: checked,
            Title: "Mr",
          })
        );
        dispatch(action.toggleOpenPassenger());
      } else if (PaxType == PassengerPaxTypeEnum.Infant) {
        dispatch(
          action.selectTravellerForBooking({
            index: i,
            id: id,
            FirstName: FirstName,
            LastName: LastName,
            PaxType: PaxType,
            DateOfBirth: DateOfBirth,
            Gender: Gender,
            PassportNo: PassportNo,
            PassportExpiry: PassportExpiry,
            AddressLine1: AddressLine1,
            AddressLine2: AddressLine2,
            City: City,
            CountryCode: CountryCode,
            CellCountryCode: CellCountryCode,
            ContactNo: ContactNo,
            Nationality: Nationality,
            Email: Email,
            IsLeadPax: IsLeadPax,
            FFAirlineCode: FFAirlineCode,
            FFNumber: FFNumber,
            GSTCompanyAddress: GSTCompanyAddress,
            GSTCompanyContactNumber: GSTCompanyContactNumber,
            GSTCompanyName: GSTCompanyName,
            GSTNumber: GSTNumber,
            GSTCompanyEmail: GSTCompanyEmail,
            honorifics: "Mr",
            fullName: "",
            type: 0,
            _id: "",
            checked: checked,
            Title: "Mr",
          })
        );
        dispatch(action.toggleOpenPassenger());
      }
    }
  };

  const saveTraveller = useSelector(selectSaveTraveler);
  let token = localStorage.getItem("token");
  const handleViewAll = (id: number) => {
    setViewAll(id);
  };

  return (
    <Box>
      <FilterBoxMain>
        <FilterBox>
          <img
            src="/images/ContactDetails.svg"
            width={20}
            height={20}
            alt="contact details"
          />
          <HeadingReview>Traveller Details</HeadingReview>
        </FilterBox>
      </FilterBoxMain>
      <Box>
        <SubHeading>
          Passenger details match your Government Issued Identity Proof
        </SubHeading>
      </Box>
      {Number(requestData?.adults) > 0 && (
        <Box>
          <HeadingReviewCate mb={3}>Adult</HeadingReviewCate>
          {props?.loading ? (
            <>
              {Array.from({ length: requestData?.adults || 1 }).map((data) => (
                <TravellerSkeletonLoading />
              ))}
            </>
          ) : (
            <>
              {viewAll == 1 ? (
                <>
                  {props?.passengerData
                    ?.filter(
                      (data, UpperIndex) =>
                        data.PaxType === PassengerPaxTypeEnum.Adult
                    )
                    .map((data, filteredIndex: number, array) => {
                      const originalIndex = props?.passengerData.findIndex(
                        (element) => {
                          return element._id == data._id;
                        }
                      );
                      return (
                        <TravellerBox key={originalIndex}>
                          <Grid container>
                            <Grid item xs={11.3} className="textAlign">
                              <CheckButton
                                checked={data.checked ? true : false}
                                onClick={(e: any) =>
                                  travellerSelect(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data.checked
                                      ? data.checked == true
                                        ? false
                                        : true
                                      : true
                                  )
                                }
                              />
                              <NameTypo>
                                {data.FirstName} {data.LastName}
                              </NameTypo>
                              <NameTypo>
                                (
                                {data.Gender == GenderEnum.MALE
                                  ? " Male "
                                  : data.Gender == GenderEnum.FEMALE
                                  ? " Female "
                                  : " Other "}
                                )
                              </NameTypo>
                            </Grid>
                            <Grid
                              item
                              xs={0.7}
                              className="textAlign"
                              sx={{ justifyContent: "end" }}
                            >
                              <IconButton
                                sx={{
                                  p: 0,
                                  "&: hover": {
                                    backgroundColor: "transparent !important",
                                  },
                                }}
                                onClick={(e: any) =>
                                  travellerUpdate(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data?.checked == true ? true : false
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "rgba(222, 49, 81, 1)",
                                    mr: 1,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TravellerBox>
                      );
                    })}
                </>
              ) : (
                <>
                  {props?.passengerData
                    ?.filter(
                      (data, UpperIndex) =>
                        data.PaxType === PassengerPaxTypeEnum.Adult
                    )
                    .slice(0, requestData?.adults)
                    .map((data, filteredIndex: number, array) => {
                      const originalIndex = props?.passengerData.findIndex(
                        (element) => {
                          return element._id == data._id;
                        }
                      );
                      return (
                        <TravellerBox key={originalIndex}>
                          <Grid container>
                            <Grid item xs={11.3} className="textAlign">
                              <CheckButton
                                checked={data.checked ? true : false}
                                onClick={(e: any) =>
                                  travellerSelect(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data.checked
                                      ? data.checked == true
                                        ? false
                                        : true
                                      : true
                                  )
                                }
                              />
                              <NameTypo>
                                {data.FirstName} {data.LastName}
                              </NameTypo>
                              <NameTypo>
                                (
                                {data.Gender == GenderEnum.MALE
                                  ? " Male "
                                  : data.Gender == GenderEnum.FEMALE
                                  ? " Female "
                                  : " Other "}
                                )
                              </NameTypo>
                            </Grid>
                            <Grid
                              item
                              xs={0.7}
                              className="textAlign"
                              sx={{ justifyContent: "end" }}
                            >
                              <IconButton
                                sx={{
                                  p: 0,
                                  "&: hover": {
                                    backgroundColor: "transparent !important",
                                  },
                                }}
                                onClick={(e: any) =>
                                  travellerUpdate(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data?.checked == true ? true : false
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "rgba(222, 49, 81, 1)",
                                    mr: 1,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TravellerBox>
                      );
                    })}
                </>
              )}
              {props?.passengerData?.filter(
                (data, UpperIndex) =>
                  data?.PaxType === PassengerPaxTypeEnum.Adult
              )?.length > requestData?.adults && (
                <>
                  {viewAll == 1 ? (
                    <Chips
                      variant="primary"
                      label="Show Less"
                      paddingX={8}
                      sx={viewAllStyle}
                      onClick={() => handleViewAll(0)}
                    />
                  ) : (
                    <Chips
                      variant="primary"
                      label="View All"
                      paddingX={8}
                      sx={viewAllStyle}
                      onClick={() => handleViewAll(1)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Box>
      )}
      {Number(requestData?.children) > 0 && (
        <Box>
          <HeadingReviewCate mb={3}>Child</HeadingReviewCate>
          {props?.loading ? (
            <>
              {Array.from({ length: requestData?.children || 1 }).map(
                (data) => (
                  <TravellerSkeletonLoading />
                )
              )}
            </>
          ) : (
            <>
              {viewAll == 2 ? (
                <>
                  {props?.passengerData
                    ?.filter(
                      (data) => data.PaxType == PassengerPaxTypeEnum.Child
                    )
                    .map((data, filteredIndex: number, array) => {
                      const originalIndex = props?.passengerData.findIndex(
                        (element) => {
                          // Customize this condition based on your requirements
                          return element._id == data._id;
                        }
                      );
                      return (
                        <TravellerBox key={originalIndex}>
                          <Grid container>
                            <Grid item xs={11.3} className="textAlign">
                              <CheckButton
                                checked={data.checked ? true : false}
                                onClick={(e: any) =>
                                  travellerSelect(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data.checked
                                      ? data.checked == true
                                        ? false
                                        : true
                                      : true
                                  )
                                }
                              />
                              <NameTypo>
                                {" "}
                                {data.FirstName} {data.LastName}
                              </NameTypo>
                              <NameTypo>
                                (
                                {data.Gender == GenderEnum.MALE
                                  ? " Male "
                                  : data.Gender == GenderEnum.FEMALE
                                  ? " Female "
                                  : " Other "}
                                )
                              </NameTypo>
                            </Grid>
                            <Grid
                              item
                              xs={0.7}
                              className="textAlign"
                              sx={{ justifyContent: "end" }}
                            >
                              <IconButton
                                sx={{
                                  p: 0,
                                  "&: hover": {
                                    backgroundColor: "transparent !important",
                                  },
                                }}
                                onClick={(e: any) =>
                                  travellerUpdate(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data?.checked == true ? true : false
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "rgba(222, 49, 81, 1)",
                                    mr: 1,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TravellerBox>
                      );
                    })}
                </>
              ) : (
                <>
                  {props?.passengerData
                    ?.filter(
                      (data) => data.PaxType == PassengerPaxTypeEnum.Child
                    )
                    .slice(0, requestData?.children)
                    .map((data, filteredIndex: number, array) => {
                      const originalIndex = props?.passengerData.findIndex(
                        (element) => {
                          // Customize this condition based on your requirements
                          return element._id == data._id;
                        }
                      );
                      return (
                        <TravellerBox key={originalIndex}>
                          <Grid container>
                            <Grid item xs={11.3} className="textAlign">
                              <CheckButton
                                checked={data.checked ? true : false}
                                onClick={(e: any) =>
                                  travellerSelect(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data.checked
                                      ? data.checked == true
                                        ? false
                                        : true
                                      : true
                                  )
                                }
                              />
                              <NameTypo>
                                {" "}
                                {data.FirstName} {data.LastName}
                              </NameTypo>
                              <NameTypo>
                                (
                                {data.Gender == GenderEnum.MALE
                                  ? " Male "
                                  : data.Gender == GenderEnum.FEMALE
                                  ? " Female "
                                  : " Other "}
                                )
                              </NameTypo>
                            </Grid>
                            <Grid
                              item
                              xs={0.7}
                              className="textAlign"
                              sx={{ justifyContent: "end" }}
                            >
                              <IconButton
                                sx={{
                                  p: 0,
                                  "&: hover": {
                                    backgroundColor: "transparent !important",
                                  },
                                }}
                                onClick={(e: any) =>
                                  travellerUpdate(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data?.checked == true ? true : false
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "rgba(222, 49, 81, 1)",
                                    mr: 1,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TravellerBox>
                      );
                    })}
                </>
              )}

              {props?.passengerData?.filter(
                (data, UpperIndex) =>
                  data?.PaxType === PassengerPaxTypeEnum.Child
              )?.length > requestData?.children && (
                <>
                  {viewAll == 2 ? (
                    <Chips
                      variant="primary"
                      label="Show Less"
                      paddingX={8}
                      sx={viewAllStyle}
                      onClick={() => handleViewAll(0)}
                    />
                  ) : (
                    <Chips
                      variant="primary"
                      label="View All"
                      paddingX={8}
                      sx={viewAllStyle}
                      onClick={() => handleViewAll(2)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Box>
      )}
      {Number(requestData?.infants) > 0 && (
        <Box>
          <HeadingReviewCate mb={3}>Infant</HeadingReviewCate>
          {props?.loading ? (
            <>
              {Array.from({ length: requestData?.infants || 1 }).map((data) => (
                <TravellerSkeletonLoading />
              ))}
            </>
          ) : (
            <>
              {viewAll == 3 ? (
                <>
                  {props?.passengerData
                    ?.filter(
                      (data, UpperIndex) =>
                        data.PaxType === PassengerPaxTypeEnum.Infant
                    )
                    .map((data, filteredIndex: number, array) => {
                      const originalIndex = props?.passengerData.findIndex(
                        (element) => {
                          // Customize this condition based on your requirements
                          return element._id == data._id;
                        }
                      );
                      return (
                        <TravellerBox key={originalIndex}>
                          <Grid container>
                            <Grid item xs={11.3} className="textAlign">
                              <CheckButton
                                checked={data.checked ? true : false}
                                onClick={(e: any) =>
                                  travellerSelect(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data.checked
                                      ? data.checked == true
                                        ? false
                                        : true
                                      : true
                                  )
                                }
                              />
                              <NameTypo>
                                {" "}
                                {data.FirstName} {data.LastName}
                              </NameTypo>
                              <NameTypo>
                                (
                                {data.Gender == GenderEnum.MALE
                                  ? " Male "
                                  : data.Gender == GenderEnum.FEMALE
                                  ? " Female "
                                  : " Other "}
                                )
                              </NameTypo>
                            </Grid>
                            <Grid
                              item
                              xs={0.7}
                              className="textAlign"
                              sx={{ justifyContent: "end" }}
                            >
                              <IconButton
                                sx={{
                                  p: 0,
                                  "&: hover": {
                                    backgroundColor: "transparent !important",
                                  },
                                }}
                                onClick={(e: any) =>
                                  travellerUpdate(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data?.checked == true ? true : false
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "rgba(222, 49, 81, 1)",
                                    mr: 1,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TravellerBox>
                      );
                    })}
                </>
              ) : (
                <>
                  {props?.passengerData
                    ?.filter(
                      (data, UpperIndex) =>
                        data.PaxType === PassengerPaxTypeEnum.Infant
                    )
                    .slice(0, requestData?.infants)
                    .map((data, filteredIndex: number, array) => {
                      const originalIndex = props?.passengerData.findIndex(
                        (element) => {
                          // Customize this condition based on your requirements
                          return element._id == data._id;
                        }
                      );
                      return (
                        <TravellerBox key={originalIndex}>
                          <Grid container>
                            <Grid item xs={11.3} className="textAlign">
                              <CheckButton
                                checked={data.checked ? true : false}
                                onClick={(e: any) =>
                                  travellerSelect(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data.checked
                                      ? data.checked == true
                                        ? false
                                        : true
                                      : true
                                  )
                                }
                              />
                              <NameTypo>
                                {" "}
                                {data.FirstName} {data.LastName}
                              </NameTypo>
                              <NameTypo>
                                (
                                {data.Gender == GenderEnum.MALE
                                  ? " Male "
                                  : data.Gender == GenderEnum.FEMALE
                                  ? " Female "
                                  : " Other "}
                                )
                              </NameTypo>
                            </Grid>
                            <Grid
                              item
                              xs={0.7}
                              className="textAlign"
                              sx={{ justifyContent: "end" }}
                            >
                              <IconButton
                                sx={{
                                  p: 0,
                                  "&: hover": {
                                    backgroundColor: "transparent !important",
                                  },
                                }}
                                onClick={(e: any) =>
                                  travellerUpdate(
                                    originalIndex, // Use the original index
                                    data._id,
                                    data.FirstName,
                                    data.LastName,
                                    data.PaxType,
                                    data.DateOfBirth,
                                    data.Gender,
                                    data.PassportNo,
                                    data.PassportExpiry,
                                    data.AddressLine1,
                                    data.AddressLine2,
                                    data.City,
                                    data.CountryCode,
                                    data.CellCountryCode,
                                    data.ContactNo,
                                    data.Nationality,
                                    data.Email,
                                    data.IsLeadPax,
                                    data.FFAirlineCode,
                                    data.FFNumber,
                                    data.GSTCompanyAddress,
                                    data.GSTCompanyContactNumber,
                                    data.GSTCompanyName,
                                    data.GSTNumber,
                                    data.GSTCompanyEmail,
                                    data?.checked == true ? true : false
                                  )
                                }
                              >
                                <EditIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "rgba(222, 49, 81, 1)",
                                    mr: 1,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TravellerBox>
                      );
                    })}
                </>
              )}

              {props?.passengerData?.filter(
                (data, UpperIndex) =>
                  data?.PaxType === PassengerPaxTypeEnum.Infant
              )?.length > requestData?.infants && (
                <>
                  {viewAll == 3 ? (
                    <Chips
                      variant="primary"
                      label="Show Less"
                      paddingX={8}
                      sx={viewAllStyle}
                      onClick={() => handleViewAll(0)}
                    />
                  ) : (
                    <Chips
                      variant="primary"
                      label="View All"
                      paddingX={8}
                      sx={viewAllStyle}
                      onClick={() => handleViewAll(3)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Box>
      )}

      <HeadingReview1
        sx={{ mt: 1 }}
        onClick={() => dispatch(action.toggleOpenPassenger())}
      >
        + ADD NEW PASSENGER
      </HeadingReview1>

      <AddTravellerTab />
      {token && (
        <SubHeadingWithColor>
          <CheckButton
            onClick={() => dispatch(actions.setSaveTraveler(!saveTraveller))}
            checked={saveTraveller}
          />
          Add these travellers to My Traveller List.
        </SubHeadingWithColor>
      )}
    </Box>
  );
}

export default TravellerDetails;
const TravellerBox = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #e5e5e5",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "5px 5px",
  marginBottom: 15,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FilterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const SpaceBox1 = styled(Box)(({ theme }) => ({
  marginBottom: 20,
}));
const HeadingReviewCate = styled(Typography)(({ theme }) => ({
  marginBottom: 0,
  color: "#DE3151",
  fontSize: 18,
  fontWeight: 600,
  marginLeft: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FilterBoxMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // marginBottom: 10,
  marginTop: 16,
}));
const HeadingReview = styled(Typography)(({ theme }) => ({
  color: "#DE3151",
  fontSize: 18,
  fontWeight: 600,
  marginLeft: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
const HeadingReview1 = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: "#DE3151",
  fontSize: 18,
  fontWeight: 600,
  marginLeft: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    // marginBottom: 20,
    // marginTop: 30,
  },
  [theme.breakpoints.between("sm", "md")]: {
    // marginBottom: 30,
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 14,
  fontWeight: 500,
  // marginTop: 20,
  marginBottom: 20,
}));
const SubHeadingWithColor = styled(Box)(({ theme }) => ({
  color: "#252525",
  fontSize: 16,
  marginTop: 10,
  fontWeight: 600,
  paddingTop: 10,
  paddingBottom: 10,
  display: "flex",
  alignItems: "center",
}));
const NameTypo = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  marginRight: 5,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));
const viewAllStyle = {
  fontSize: 12,
  fontWeight: 600,
  margin: "0px 0px 10px 8px",
};
