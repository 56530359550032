import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Button,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import theme from "../../Style/theme";
import { useDispatch, useSelector } from "react-redux";
import { auth, signInWithGoogle } from "../Firebase.utils";
import { actions } from "../../Redux/Login/slice";
import { SelectLoginModal, selectTmc } from "../../Redux/Login/selector";
import { toast } from "react-toastify";
import Close from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

export default function Index() {
 
  const navigate = useNavigate();
  const open = useSelector(SelectLoginModal);
  const tmc = useSelector(selectTmc);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.toggleOpenLogin(false));
  };
  const handleNavigate = (path: string) => {
    dispatch(actions.toggleOpenLogin(false));
    navigate(`/${path}`);
  };

  // google login
  let unsubscribeFromAuth: any;
  React.useEffect(() => {
    unsubscribeFromAuth = auth.onAuthStateChanged((user: any) => {
      if (user) {
        responseGoogle(user.providerData);
      }
    });

    return () => {
      unsubscribeFromAuth();
      auth.signOut();
    };
  }, []);
  const responseGoogle = (response: any) => {
    let googleData = {
      email: response[0].email,
      username: response[0].displayName,
      profilePic: response[0].photoURL,
      googleId: response[0].uid,
    };

    dispatch(
      actions.doGoogleLogin({
        data: googleData,
        callback: (dataToken) => {
          auth.signOut();
          handleClose();
          window.location.reload();
          // setTimeout(() => {
          dispatch(
            actions.doGetProfile({
              token: dataToken,
              callback: (data) => {},
            })
          );
          // }, 1000);
        },
      })
    );
  };
  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Grid
            container
            rowGap={{ xs: 3, md: 0 }}
            sx={{ height: "100%", position: "relative" }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", md: "none" },
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <IconButton
                  sx={{
                    p: 0,
                  }}
                  onClick={handleClose}
                >
                  <Close
                    sx={{
                      color: "rgba(222, 49, 81, 1)",
                      cursor: "pointer",
                      "&: hover": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: "100%",
                  overflow: "hidden",
                  width: "100%",
                  borderRadius: {
                    xs: "12px 12px 0px 0px",
                    md: "12px 0px 0px 12px",
                  },
                  position: "relative",
                }}
                // className="loginModalImage"
              >
                <img
                  src={
                    "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60"
                  }
                  alt={"Destination Image"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  // className="loginModalImage"
                />
                <OverLayerBox>
                  <ImageTypo>
                    Ready to Onboard your next flight with us?
                  </ImageTypo>
                </OverLayerBox>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: "center", padding: "20px 20px" }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: "end",
                }}
              >
                <IconButton onClick={handleClose}>
                  <Close
                    sx={{
                      color: "rgba(222, 49, 81, 1)",
                      cursor: "pointer",
                      "&: hover": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  />
                </IconButton>
              </Box>
              <Box
                sx={{
                  height: { xs: "100%", md: "calc(100% - 80px)" },
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: { xs: 0, md: 2 },
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                  }}
                >
                  <IconButton
                    sx={{
                      p: 0,
                      "&: hover": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  >
                    <img
                      src="/images/brandIcon.svg"
                      alt="Trivzy"
                      className="brandIcon"
                    />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: { xs: "auto", md: "calc(100% - 20px)" },
                    }}
                  >
                    <LoginButton
                      onClick={() => {
                        if (tmc == false) {
                          toast.error("Please select terms and conditions");
                          return;
                        } else {
                          signInWithGoogle().catch((error: any) => {});
                        }
                      }}
                    >
                      <img
                        src="/images/googleLogo.svg"
                        alt="Google"
                        style={{ width: "24px", marginRight: 20 }}
                      />
                      Login with Google
                    </LoginButton>
                    <PolicyTypo>
                      By proceeding, you agree to Trivzy's
                      <span
                        onClick={() => handleNavigate("privacy-policy")}
                        style={TandCTypo}
                      >
                        &nbsp;Privacy Policy&nbsp;
                      </span>
                      and
                      <span
                        onClick={() => handleNavigate("tmc")}
                        style={TandCTypo}
                      >
                        &nbsp;T&Cs
                      </span>
                    </PolicyTypo>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
const images = [
  {
    label: "Destination Image",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    label: "Destination Image",
    imgPath: "/images/destinationImages.png",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
  },
  {
    label: "Goč, Serbia",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  backgroundColor: "#fff",
  // boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
  outline: 0,
  borderRadius: 3,
  height: 400,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "95%",
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "auto",
    width: "70%",
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: "80%",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    width: "60%",
  },
};
const LoginButton = styled(Button)(({ theme }) => ({
  textTransform: "unset",
  fontSize: 18,
  fontWeight: 500,
  color: "rgba(37, 37, 37, 1)",
  cursor: "pointer",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  borderRadius: 8,
  padding: "10px 24px",
  marginTop: 20,
  // marginTop: 80,
  backgroundColor: "rgba(255, 255, 255, 1)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const PolicyTypo = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 500,
  color: "rgba(37, 37, 37, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // marginTop: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
    display: "block",
    marginTop: 20,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 10,
    display: "block",
    marginTop: 20,
  },
}));
const OverLayerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  inset: "0 0 0 0",
  // top: 0,
  background:
    "linear-gradient(0.8deg, rgba(0, 0, 0, 0.74) 0.69%, rgba(217, 217, 217, 0) 100%)",
  height: "100%",
  width: "100%",
  padding: 20,
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
  borderRadius: 12,
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "100%",
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "100%",
  },
}));
const ImageTypo = styled(Typography)(({ theme }) => ({
  fontSize: 28,
  fontWeight: 700,
  color: "#fff",
  textAlign: "center",
  marginBottom: 50,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    marginBottom: 0,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 24,
    marginBottom: 0,
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: 24,
    marginBottom: 25,
  },
}));
const TandCTypo = {
  color: "rgba(222, 49, 81, 1)",
  cursor: "pointer",
};
