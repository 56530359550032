import { Button, CircularProgress } from "@mui/material";
import React from "react";
import theme from "../../Style/theme";
interface Props {
  loading: boolean;
  handleClick: any;
  title: string;
}
function Index(props: Props) {
  return (
    <div>
      <Button
        onClick={props.handleClick}
        disabled={props.loading}
        sx={{
          fontSize: "16px",
          color: "#fff",
          padding: "12px 10px",
          width: 165,
          cursor: "pointer",
          backgroundColor: "rgba(219, 56, 60, 1)",
          textTransform: "none",
          borderRadius: 2,
          "&:hover": {
            backgroundColor: "rgba(219, 56, 60, 1)",
            boxShadow: "none",
          },
          boxShadow: "none",
          [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            width: "auto",
            padding: "12px 16px",
          },
          [theme.breakpoints.between("sm", "md")]: {
            fontSize: "16px",
            width: 150,
          },
        }}
      >
        {props.loading ? (
          <CircularProgress
            sx={{
              width: "30px !important",
              height: "30px !important",
              color: "#fff",
            }}
          />
        ) : (
          `${props.title}`
        )}
      </Button>
    </div>
  );
}

export default Index;
