export interface IUserDataInterface {
  fullName: string;
  profilePic: string;
  email: string;
  id: string;
  // googleId: string;
}
export interface UserProfileInterface {
  email: string;
  fullName: string;
  pinCode: string;
  state: string;
  dob: string;
  address: string;
  maritalStatus: UserMaritalStatusEnum;
  gender: GenderEnum;
  phoneNo: string;
}
export interface TravellersInterface {
  honorifics: string; // not needed Title
  Email: string;
  fullName: string; // not needed
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  PassportNo: string;
  PassportExpiry: string;
  CountryCode: string;
  CellCountryCode: string; // mobile starting ode
  ContactNo: string;
  isNoValid: boolean;
  type: number;
  Gender: GenderEnum;
  _id: string;
  Title: PassengerTitleEnum;
  checked: boolean;
  PaxType: PassengerPaxTypeEnum;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  Nationality: string;
  IsLeadPax: true;
  FFAirlineCode: null;
  FFNumber: string;
  GSTCompanyAddress: string;
  GSTCompanyContactNumber: string;
  GSTCompanyName: string;
  GSTNumber: string;
  GSTCompanyEmail: string;
  PassportIssueDate?: string;
}
export interface UserBookingListInterface {
  _id: string;
  id: string;
  tripDate: string;
  returnDate: string;
  userID: string;
  BookingId: string;
  PNR: string;
  stops: number;
  Fare: [
    {
      TicketId: number;
      TicketNumber: string;
      bookingStatus: number;
    }
  ];
  bookingDetailsRequest: {
    AirlineCode: string;
    AirlineLogo: string;
    AirlineName: string;
    FlightNumber: string;
    FareClass: string;
    OriginAirportName: string;
    OriginCityName: string;
    DepTime: string;
    DestinationAirportName: string;
    DestinationCityName: string;
    ArrTime: string;
  };
  statusFilter: FlightBookingDetailFilterEnum.Upcoming;
  retrunbookingDetailsRequest: {};
}
export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}

export enum UserMaritalStatusEnum {
  MARRIED = 1,
  UNMARRIED = 2,
}

export enum FlightBookingDetailFilterEnum {
  Past = 0,
  Upcoming = 1,
  Cancel = 2,
  Payment_failed = 3,
  Refund = 4,
}

export enum PassengerTitleEnum {
  MR = "Mr",
  MRS = "Mrs",
  MS = "Ms",
  MSTR = "Mstr",
}

export enum PassengerPaxTypeEnum {
  Adult = 1,
  Child = 2,
  Infant = 3,
}
export enum RemarksTypeEnum {
  "My Plan Changed" = 1,
  "I directly cancelled with airline" = 2,
  "Flight rescheduled by airline" = 3,
  "Flight cancelled by airline" = 4,
  "due to medical emergency" = 5,
}
export interface BookingCancelInterface {
  bookingId: string;
  Remarks: RemarksTypeEnum;
  TicketIds: string[];
}

export interface ContactUsInterface {
  name: string;
  email: string;
  phoneNo: string;
  description: string;
}
export interface CancelPassengerListFareInterface {
  TrivzyCancellationFee: number;
  TotalAmount: number;
  ConvenienceFeeAmount: number;
  TotalCancellationCharge: number;
  RefundableAmount: number;
  AirlineFare: number;
  AirlineCancellationFee: number;
}
export interface CancelPassengerListInterface {
  Fare: CancelPassengerListFareInterface;
  _id: string;
  bookingId: string;
  FirstName: string;
  LastName: string;
  PaxType: PassengerPaxTypeEnum;
  DateOfBirth: string;
  Gender: GenderEnum;
  ticketId: number;
  isSelected: boolean;
}
export interface SetCancelPassengerListInterface {
  bookingId: string;
  userId: any[];
}
export interface CancelPassengerTotalPassengerBreakdownInterface {
  _id: string;
  bookingId: string;
  FirstName: string;
  LastName: string;
  PaxType: PassengerPaxTypeEnum;
  DateOfBirth: string;
  Gender: GenderEnum;
  ticketId: number;
}
export interface CancelPassengerTotalFareBreakdownInterface {
  AirlineCancellationFee: number;
  AirlineFare: number;
  ConvenienceFeeAmount: number;
  RefundableAmount: number;
  TotalAmount: number;
  TotalCancellationCharge: number;
  TrivzyCancellationFee: number;
}
export interface CancelFlightDetailsBreakdownInterface {
  tripDate: string;
  source: string;
  destination: string;
}
export interface StateInterface {
  _id: string;
  name: string;
}
export interface CancelPassengerTotalBreakdownInterface {
  passenger: CancelPassengerTotalPassengerBreakdownInterface[];
  Fare: CancelPassengerTotalFareBreakdownInterface;
  FlightDetails: CancelFlightDetailsBreakdownInterface;
}
export interface AuthState {
  userData: IUserDataInterface;
  form: UserProfileInterface;
  travellerForm: TravellersInterface;
  travellerList: TravellersInterface[];
  loading: boolean;
  search: string;
  loginModal: boolean;
  deleteModal: boolean;
  passengerModal: boolean;
  token: string | null;
  isLogin: boolean;
  tmc: boolean;
  EditIndex: number;
  status: FlightBookingDetailFilterEnum;
  countryList: [];
  pageNo: number;
  pageSize: number;
  totalRow: number;
  totalPage: number;
  hasMore: boolean;
  bookingList: UserBookingListInterface[];
  bookingCancel: BookingCancelInterface;
  bookingLoading: boolean;
  showUseDetailsModal: boolean;
  submitCancelPolicyModal: boolean;
  cancelPassengerList: CancelPassengerListInterface[];
  setCancelPassengerList: SetCancelPassengerListInterface;
  CancelPassengerTotalBreakdown: CancelPassengerTotalBreakdownInterface;
  cancelBooking: boolean;
  cancelBookingTotalPassenger: number;
  buttonLoading: boolean;
  stateList: StateInterface[];
}

export type InitialAuthState = AuthState;
