import * as React from "react";
import {
  CardMedia,
  styled,
  Button,
  Typography,
  CardContent,
  CardActions,
  Card,
  Box,
  Skeleton,
} from "@mui/material";
import moment from "moment";

interface props {
  image: string;
  couponCode: string;
  offUpto: string;
  loading: boolean;
}

export default function CouponCard(props: props) {
  return (
    <>
      {props?.loading ? (
        <Card sx={style}>
          <CardMedia sx={{ height: 200 }}>
            <Skeleton height={"100%"} animation="wave" />
          </CardMedia>
          <CardContent sx={{ p: 1 }}>
            <CouponCodeTypo gutterBottom>
              <Skeleton height={40} animation="wave" />
            </CouponCodeTypo>
            <OffUptoTypo>
              <Skeleton width={"90%"} height={40} animation="wave" />
            </OffUptoTypo>
          </CardContent>
        </Card>
      ) : (
        <Card sx={style}>
          <CardMedia>
            {props.image.length <= 0 ? (
              <Typography>Image</Typography>
            ) : (
              <img src={`${props.image}`} alt="offer image" width={"100%"} />
            )}
          </CardMedia>
          <CardActions sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            <CouponCodeTypo className="textCenter">
              {props.couponCode}
              {/* <img
                src="/images/copy.svg"
                alt=""
                style={{
                  width: "23px",
                  height: "23px",
                  marginLeft: 6,
                  cursor: "pointer",
                }}
                onClick={() => navigator.clipboard.writeText(props?.couponCode)}
              /> */}
            </CouponCodeTypo>
            <OffUptoTypo>{props.offUpto}</OffUptoTypo>
          </CardActions>
        </Card>
      )}
    </>
  );
}
const CouponCodeTypo = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: "#DE3151",
  fontWeight: 700,
  textTransform: "uppercase",
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const OffUptoTypo = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const style = {
  borderRadius: "15px",
  padding: 2.5,
  boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
};
