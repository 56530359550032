import { BlogCategoryEnum, ContactUsTypeEnum, HomeState } from ".";

export const initialState: HomeState = {
  loading: false,
  form: {
    name: "",
    email: "",
    phoneNo: "",
    description: "",
    type: ContactUsTypeEnum.CONTACT_US,
  },
  tmcData: {
    privacyPolicyDescription: "",
    tmcDescription: "",
    refundPolicyDescription: "",
  },
  contactModal: false,
  blogList: [],
  blogDetails: {
    _id: "",
    title: "",
    description: "",
    category: BlogCategoryEnum.All,
    image: "",
  },
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  totalPage: 0,
  offerList: [],
  faqList: [],
  homeBanner: [],
};
