import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as action } from "../Booking/slice";
import { AxiosResponse } from "axios";
import {
  SelectFlightSearchList,
  SelectReturnFlightSearchList,
  selectForm,
  selectGenForm,
} from "./selector";
import CatchBlockFunction from "../../Component/CatchError";
import { toast } from "react-toastify";
import {
  FlightSearchGenInterface,
  FlightSearchInterface,
  FlightSearchListInterface,
  TripTypesEnum,
} from "./types";
import {
  GetAirportList,
  GetCouponList,
  SearchFlight,
} from "../../utils/request";

export function* doGetAirportListRequest(action: { payload: string }) {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      GetAirportList,
      action.payload.length > 0 ? action.payload : "del"
    );
    yield put(actions.setLoading(false));

    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setAirportList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doSearchFlightRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: FlightSearchInterface = yield select(selectForm);
  const genForm: FlightSearchGenInterface = yield select(selectGenForm);

  // if (form.source.length === 0) {
  //   toast.error("Please select source");
  //   return;
  // }
  // if (form.destination.length === 0) {
  //   toast.error("Please select destination");
  //   return;
  // }
  // if (form.arrivalDate.length === 0) {
  //   toast.error("Please select arrivalDate");
  //   return;
  // }
  // if (form.travelClass === 0) {
  //   toast.error("Please select travel class");
  //   return;
  // }

  const data = {
    adults: genForm.adults,
    children: genForm.children,
    infants: genForm.infants,
    destination: form.destination,
    flightType: 1,
    journeyDate: form.journeyDate,
    returnDate: form.tripType == TripTypesEnum.ROUNDTRIP ? form.returnDate : "",
    source: form.source,
    travelClass: form.travelClass,
    tripType: form.tripType,
    arrivalFilter: null,
    stopsFilter: form.stopsFilter,
    priceFilter: form.priceFilter,
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(SearchFlight, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setFlightBookingId(response.data.data.data.booking_id));
    if (form.tripType == TripTypesEnum.ROUNDTRIP) {
      yield put(
        actions.setFlightSearchList(response.data.data.data.Results[0])
      );
      yield put(
        actions.setReturnFlightSearchList(response.data.data.data.Results[1])
      );
    } else {
      yield put(
        actions.setFlightSearchList(response.data.data.data.Results[0])
      );
    }

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* setReturnFlightSearchListRequest() {
  yield delay(500);
  try {
    const arrivalList: FlightSearchListInterface[] = yield select(
      SelectFlightSearchList
    );
    const returnList: FlightSearchListInterface[] = yield select(
      SelectReturnFlightSearchList
    );

    console.log(arrivalList, "arrivalList");

    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnResultIndex",
        value: returnList[0]?.ResultIndex,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnAirlineName",
        value: returnList[0]?.AirlineName,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnAirlineLogo",
        value: "",
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnAirlineCode",
        value: returnList[0]?.AirlineCode,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnFlightNumber",
        value: returnList[0]?.FlightNumber,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnDepTime",
        value: returnList[0]?.DepTime,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnSourceCityName",
        value: returnList[0]?.SourceCity,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnDuration",
        value: returnList[0]?.Duration,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnStop",
        value: returnList[0].Segments.length,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnArrTime",
        value: returnList[0]?.ArrTime,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnDestinationCityName",
        value: returnList[0]?.DestinationCity,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "returnPrice",
        value: returnList[0]?.PublishedFare,
      })
    );

    // arrival

    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "resultIndex",
        value: arrivalList[0]?.ResultIndex,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "airlineName",
        value: arrivalList[0]?.AirlineName,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "airlineLogo",
        value: "",
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "airlineCode",
        value: arrivalList[0]?.AirlineCode,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "flightNumber",
        value: arrivalList[0]?.FlightNumber,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "depTime",
        value: arrivalList[0]?.DepTime,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "sourceCityName",
        value: arrivalList[0]?.SourceCity,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "duration",
        value: arrivalList[0]?.Duration,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "stop",
        value: arrivalList[0].Segments.length,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "arrTime",
        value: arrivalList[0]?.ArrTime,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "destinationCityName",
        value: arrivalList[0]?.DestinationCity,
      })
    );
    yield put(
      action.updateSelectedFlightCardFormValue({
        key: "price",
        value: arrivalList[0]?.PublishedFare,
      })
    );
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetCouponListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetCouponList,
      `?pageNo=${1}&pageSize=${10000000}`
    );
    // yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setCouponList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* FlightSearchRepoSaga() {
  yield takeLatest(actions.doSearchFlight, doSearchFlightRequest);
  yield takeLatest(actions.doGetAirportList, doGetAirportListRequest);
  yield takeLatest(
    actions.setReturnFlightSearchList,
    setReturnFlightSearchListRequest
  );
  // coupon list
  yield takeLatest(actions.doGetCouponList, doGetCouponListRequest);
}
