import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { actions as contactActions } from "../Home/slice";
import { AxiosResponse } from "axios";

import CatchBlockFunction from "../../Component/CatchError";
import {
  MeProfileRequest,
  ProfileUpdate,
  googleLogin,
  AddTravellers,
  GetTravellers,
  GetByIdTravellers,
  UpdateTravellers,
  DeleteTravellers,
  GetCountryList,
  GetFlightBooking,
  CancelBooking,
  CancelBookingPassengerList,
  CancelBookingCharges,
  getStateList,
} from "../../utils/request";
import { toast } from "react-toastify";
import {
  selectBookingCancelForm,
  selectBookingList,
  selectForm,
  selectPageNo,
  selectPageSize,
  selectSetCancelPassengerList,
  selectStatus,
  selectTravellerForm,
} from "./selector";
import {
  BookingCancelInterface,
  FlightBookingDetailFilterEnum,
  SetCancelPassengerListInterface,
  TravellersInterface,
  UserBookingListInterface,
  UserProfileInterface,
} from "./types";
import ContactUs from "../../Component/ContactUs";
import { ContactUsTypeEnum } from "../Home/types";

// google login
export function* doGoogleLoginRequest(action: {
  payload: { data: any; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(
      googleLogin,
      action.payload.data
    );

    if (response && !response.data.status) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setUpdateToken(response.data.token));
    yield put(actions.setLoading(false));
    yield put(actions.setIsLogin(true));
    // toast.success(response.data.message);
    yield call(action.payload.callback(response.data.token));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetMeProfileRequest(action: {
  payload: { token: string; callback: any };
}) {
  yield delay(500);
  if (action.payload.token) {
    try {
      // yield put(actions.setLoading(true));
      const response: AxiosResponse = yield call(MeProfileRequest);
      if (response && !response.data) {
        toast.error(response.data.message);
        return;
      }
      yield put(
        actions.setProfileData({
          fullName: response.data.data.fullName,
          profilePic: response.data.data.profilePic,
          email: response.data.data.email,
          id: response.data.data._id,
        })
      );
      yield put(actions.setIsLogin(true));
      // yield put(actions.setLoading(true));
      yield call(
        action?.payload?.callback({
          role: response.data.role,
          id: response.data.id,
        })
      );
    } catch (error: any) {
      // yield put(actions.setLoading(false));
      CatchBlockFunction(error);
    }
  } else {
    // yield put(actions.setLoading(false));
    yield put(actions.setIsLogin(false));
  }
}
export function* doProfileUpdateRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);

  const form: UserProfileInterface = yield select(selectForm);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(ProfileUpdate, form);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    // yield put(actions.setUpdateToken(response.data.token));
    yield put(actions.setButtonLoading(false));

    yield call(action.payload.callback(response.data.token));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetUserProfileRequest() {
  yield delay(500);
  try {
    // yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(MeProfileRequest);
    // yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setUserProfileData({
        email: response.data.data.email,
        fullName: response.data.data.fullName,
        pinCode: response.data.data.pinCode,
        state: response.data.data.state,
        dob: response.data.data.dob,
        address: response.data.data.address,
        maritalStatus: response.data.data.maritalStatus,
        gender: response.data.data.gender,
        phoneNo: response.data.data.phoneNo,
      })
    );
  } catch (error: any) {
    // yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

// traveller Section
export function* doAddTravellerRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  try {
    const form: TravellersInterface = yield select(selectTravellerForm);

    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(AddTravellers, form);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    // toast.success(response.data.message);
    yield call(action.payload.callback(response.data.token));
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetTravellerRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(GetTravellers);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    // toast.success(response.data.message);
    yield put(actions.setTravelleList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetByIdTravellerRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));

    const response: AxiosResponse = yield call(
      GetByIdTravellers,
      action.payload.id
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTravellerData(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateTravellerRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  try {
    const form: TravellersInterface = yield select(selectTravellerForm);
    if (form.fullName.length === 0) {
      toast.error("Please enter your name");
      return;
    }
    if (form.Email.length === 0) {
      toast.error("Please enter your email");
      return;
    }

    if (form.DateOfBirth.length === 0) {
      toast.error("Please enter your dob");
      return;
    }
    if (form.AddressLine1.length === 0) {
      toast.error("Please enter your Address");
      return;
    }

    if (form.CountryCode.length === 0) {
      toast.error("Please Select your Country");
      return;
    }
    yield put(actions.setButtonLoading(true));

    const response: AxiosResponse = yield call(UpdateTravellers, form);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteTravellerRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      DeleteTravellers,
      action.payload.id
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetCountryRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetCountryList);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setCountryList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetBookingListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const Status: FlightBookingDetailFilterEnum = yield select(selectStatus);
  const list: any[] = yield select(selectBookingList);
  const pageNo: number = yield select(selectPageNo);
  const pageSize: number = yield select(selectPageSize);
  try {
    yield put(actions.setBookingLoading(true));
    const response: AxiosResponse = yield call(
      GetFlightBooking,
      `?statusFilter=${Status}&pageNo=${pageNo}&pageSize=${pageSize}`
    );
    yield put(actions.setBookingLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    if (pageNo == 1) {
      yield put(actions.setBookingList(response.data.data.results));
    } else {
      const concatData: any = list.concat(response.data.data.results);
      yield put(
        actions.setTotalRow(
          response.data.data.meta ? response.data.data.meta.total : 0
        )
      );
      yield put(actions.setTotalPage(response.data.data.meta.totalPages));
      yield put(actions.setBookingList(concatData));
    }

    yield put(
      actions.setHasMore(
        response.data.data.meta.totalPages > response.data.data.meta.page
      )
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setBookingLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doCancelBookingRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: BookingCancelInterface = yield select(selectBookingCancelForm);
  try {
    yield put(actions.setCancelBooking(true));

    const response: AxiosResponse = yield call(CancelBooking, form);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setCancelBooking(false));
    yield call(action.payload.callback());
  } catch (error: any) {
    if (error?.response?.data?.message == "Something went wrong!") {
      yield put(contactActions.toggleOpenContactModal());
    }
    yield put(actions.setCancelBooking(false));
    CatchBlockFunction(error);
  }
  <ContactUs type={ContactUsTypeEnum.CONTACT_US} />;
}

// cancel booking passenger list
export function* doGetCancelPassengerListRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      CancelBookingPassengerList,
      action.payload.id
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(
      actions.setCancelPassengerList(response.data.data.getPassengerList)
    );
    yield put(
      actions.setCancelBookingTotalPassenger(response.data.data.totalPassenger)
    );
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetCancelChargesRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: SetCancelPassengerListInterface = yield select(
    selectSetCancelPassengerList
  );
  try {
    yield put(actions.setCancelBooking(true));
    const response: AxiosResponse = yield call(CancelBookingCharges, form);
    yield put(actions.setCancelBooking(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setCancelPassengerTotalBreakdown(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setCancelBooking(false));
    CatchBlockFunction(error);
  }
}
export function* doGetStateListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(getStateList);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setStateList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* AuthRepoSaga() {
  yield takeLatest(actions.doGoogleLogin, doGoogleLoginRequest);
  yield takeLatest(actions.doGetProfile, doGetMeProfileRequest);
  yield takeLatest(actions.doProfileUpdate, doProfileUpdateRequest);
  yield takeLatest(actions.doGetUserProfile, doGetUserProfileRequest);

  // traveler
  yield takeLatest(actions.doAddTraveller, doAddTravellerRequest);
  yield takeLatest(actions.doGetTraveller, doGetTravellerRequest);
  yield takeLatest(actions.doGetByIdTraveller, doGetByIdTravellerRequest);
  yield takeLatest(actions.doUpdateTraveller, doUpdateTravellerRequest);
  yield takeLatest(actions.doDeleteTraveller, doDeleteTravellerRequest);
  // country list
  yield takeLatest(actions.doGetCountryList, doGetCountryRequest);
  //user booking list
  yield takeLatest(actions.doGetBookingList, doGetBookingListRequest);
  yield takeLatest(actions.doCancelBooking, doCancelBookingRequest);
  yield takeLatest(
    actions.doGetCancelPassengerList,
    doGetCancelPassengerListRequest
  );
  yield takeLatest(actions.doGetCancelCharges, doGetCancelChargesRequest);
  yield takeLatest(actions.doGetStateList, doGetStateListRequest);
}
