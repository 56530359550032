import BookButton from "../../BookButton";
import DateRangePicker from "../../DataPicker";
import Selector from "../../Selector";
import TextInputField from "../../TextInputField";
import {
  selectButtonLoading,
  selectForm,
  selectStateList,
  selectUserDataForm,
} from "../../../Redux/Login/selector";
import { actions } from "../../../Redux/Login/slice";
import { GenderEnum, UserMaritalStatusEnum } from "../../../Redux/Login/types";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as AuthSliceKey,
  reducer as AuthReducer,
} from "../../../Redux/Login/slice";
import { AuthRepoSaga } from "../../../Redux/Login/saga";
import NewDatePicker from "../../DataPicker/NewDatePicker";
import { toast } from "react-toastify";
import { ExpandMore } from "@mui/icons-material";

function Index() {
  useInjectReducer({ key: AuthSliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const loading = useSelector(selectButtonLoading);
  const userDetails = useSelector(selectUserDataForm);
  const stateList = useSelector(selectStateList);
  const handleChangeDate = (val: any) => {
    dispatch(
      actions.updateFormValue({
        key: "dob",
        value: DateTime.fromJSDate(val?.$d).toISO(),
      })
    );
  };
  useInjectReducer({ key: AuthSliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  const handleSelectChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  const handleChange = (evt: any) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: name == "phoneNo" ? value.slice(0, 10) : value }));
  };
  const handleClick = () => {
    dispatch(
      actions.updateFormValue({ key: "fullName", value: form?.fullName.trim() })
    );
    dispatch(
      actions.updateFormValue({ key: "address", value: form?.address.trim() })
    );
    if (form.fullName.length === 0) {
      toast.error("Please enter full name");
      return;
    }
    if (form.dob.length === 0) {
      toast.error("Please select date of birth");
      return;
    }
    if (form.address.length === 0) {
      toast.error("Please enter address");
      return;
    }
    if (form.pinCode.length === 0) {
      toast.error("Please enter pincode");
      return;
    }
    if (form?.phoneNo?.length > 0) {
      if (!/^[6-9]\d{9}$/.test(form?.phoneNo)) {
        toast.error("Enter valid contact number");
        return;
      }
    }
    if (form.state.length === 0) {
      toast.error("Please enter state");
      return;
    }
    dispatch(
      actions.doProfileUpdate({
        callback: () => {},
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      actions.updateFormValue({ key: "email", value: userDetails.email })
    );
    dispatch(
      actions.updateFormValue({ key: "fullName", value: userDetails.fullName })
    );

    return () => {};
  }, []);

  React.useEffect(() => {
    dispatch(actions.doGetStateList());
    return () => {};
  }, []);

  return (
    <>
      <NewBox>
        <Grid container spacing={{ xs: 0, sm: 3.5 }} rowGap={{ xs: 2, sm: 0 }}>
          <Grid item xs={12} sm={6} md={6}>
            <TextInputField
              type={"text"}
              name={"fullName"}
              placeHolder={"Full Name"}
              value={form.fullName}
              className={""}
              onChange={handleChange}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="border">
              <NewDatePicker
                name={"dob"}
                onChange={handleChangeDate}
                disabled={false}
                value={form.dob}
                disablePast={false}
                disableFuture={true}
                minDate={null}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "16px !important",
                    padding: "11.5px 0px 11.5px 0px !important",
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="border">
              <Selector
                data={GenderStatusData}
                name={"gender"}
                placeholder={"Select gender"}
                value={form.gender}
                onChange={handleSelectChange}
                className={""}
                disabled={undefined}
                bootstrapInputClass="profileSelector"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="border">
              <Selector
                data={MaritalStatusData}
                name={"maritalStatus"}
                placeholder={"Select marital status"}
                value={form.maritalStatus}
                onChange={handleSelectChange}
                className={""}
                disabled={undefined}
                bootstrapInputClass="profileSelector"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextInputField
              type={"text"}
              name={"address"}
              placeHolder={"Address"}
              value={form.address}
              className={""}
              onChange={handleChange}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextInputField
              type={"number"}
              name={"pinCode"}
              placeHolder={"pinCode"}
              value={form.pinCode}
              className={""}
              onChange={handleChange}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextInputField
              type={"number"}
              name={"phoneNo"}
              placeHolder={"Contact No."}
              value={form?.phoneNo}
              className={""}
              onChange={handleChange}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="border">
              <Select
                fullWidth
                size="small"
                value={form?.state}
                name={"state"}
                onChange={handleChange}
                IconComponent={ExpandMore}
                input={<BootstrapInput />}
                displayEmpty
                renderValue={
                  form?.state !== "" || undefined
                    ? undefined
                    : () => `${"Select state"}`
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  " fieldset": {
                    border: "none !important",
                    outline: "none !important",
                  },
                  textTransform: "capitalize",
                  maxHeight: "150px",
                }}
                className="fw-semibold"
                variant="outlined"
              >
                {stateList?.map((item: any) => (
                  <MenuItem
                    key={item._id}
                    value={item.name}
                    className="selectorText fw-semibold fs-13"
                    sx={{ maxHeight: "150px", textTransform: "capitalize" }}
                  >
                    {item?.name.length > 45
                      ? item.name.slice(0, 45) + "..."
                      : item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} className="textCenter" mt={2}>
            <BookButton
              handleClick={handleClick}
              loading={loading}
              title="Save"
            />
          </Grid>
        </Grid>
      </NewBox>
    </>
  );
}

export default Index;
const MaritalStatusData = [
  {
    id: UserMaritalStatusEnum.MARRIED,
    name: "Married",
  },
  {
    id: UserMaritalStatusEnum.UNMARRIED,
    name: "Un-Married",
  },
];

const GenderStatusData = [
  {
    id: GenderEnum.MALE,
    name: "Male",
  },
  {
    id: GenderEnum.FEMALE,
    name: "Female",
  },
  {
    id: GenderEnum.OTHER,
    name: "Other",
  },
];

const NewBox = styled(Box)(({ theme }) => ({
  marginRight: 176,
  marginLeft: 96,
  padding: "24px 0px 0px 0px",
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    marginLeft: 0,
    padding: "0px 0px 30px 0px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginRight: 0,
    marginLeft: 0,
    padding: "0px 0px 30px 0px",
  },
}));
const BootstrapInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiInputBase-input": {
    // fontSize: 12,
    padding: "11.5px 14px 11.5px 14px",
  },
}));
