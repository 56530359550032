import { Box, Grid, Skeleton, Typography, styled } from "@mui/material";
import React from "react";
import RoundedButton from "../Chip/RoundedButton";
import TextInputField from "../TextInputField";
import { useDispatch, useSelector } from "react-redux";
import { selectForm } from "../../Redux/Booking/selector";
import { actions } from "../../Redux/Booking/slice";
import { actions as actionsLogin } from "../../Redux/Login/slice";
import { selectUserDataForm } from "../../Redux/Login/selector";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "../PhoneInput";
import LoginModal from "../LoginModal";

interface Props {
  loading: boolean;
}

function ContactDetails(props: Props) {
  const form = useSelector(selectForm);
  const [showError, setShowError] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(
      actions.updateFormValue({
        key: name,
        value: name == "mobileNumber" ? value.slice(0, 10) : value,
      })
    );
    if (name == "email") {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        setShowError(0);
      } else {
        setShowError(4);
      }
    }
  };
  const handleNumberFieldChange = (value: string, name: string) => {
    let isValid;
    if (value) {
      isValid = isValidPhoneNumber(value);
    }
    dispatch(
      actions.updateFormValue({
        key: "isNoValid",
        value: isValid,
      })
    );
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  const userdata = useSelector(selectUserDataForm);
  const [firstPart, secondPart] = userdata?.fullName.split(" ");

  const [token, setToken] = React.useState("");
  React.useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token as string);
    if (token) {
      dispatch(actions.updateFormValue({ key: "firstName", value: firstPart }));
      dispatch(actions.updateFormValue({ key: "lastName", value: secondPart }));
      dispatch(
        actions.updateFormValue({ key: "email", value: userdata.email })
      );
    }
    return () => {};
  }, [token, userdata]);

  return (
    <>
      {" "}
      <FilterBoxMain>
        <FilterBox>
          <img
            src="/images/ContactDetails.svg"
            width={20}
            height={20}
            alt="contact details"
          />
          <HeadingReview>Contact Details</HeadingReview>
        </FilterBox>
        {!token && (
          <FilterBox>
            <RoundedButton
              onClick={undefined}
              backgroundColor="rgba(0, 119, 26, 0.05)"
              color={"#00771A"}
              title="Login to book faster"
              fontSize="12px"
              marginRight="0px"
            />
          </FilterBox>
        )}
      </FilterBoxMain>
      <Box>
        <SubHeading>
          Your booking details will be sent to this email address
        </SubHeading>
        {/* <SubHeading>This is where your E-ticket will be sent</SubHeading> */}
      </Box>
      {props.loading ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={6} spacing={2}>
            <Box sx={{ pt: 0.5 }}>
              <Box sx={{ display: "flex" }}>
                <Skeleton width="50%" height={50} animation="wave" />
                <Skeleton
                  width="50%"
                  height={50}
                  animation="wave"
                  sx={{ marginLeft: 2 }}
                />
              </Box>
              <Skeleton animation="wave" height={50} />
              <Skeleton animation="wave" height={50} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={6} spacing={2}>
            {!token && (
              <SpaceBox>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <TextInputField
                      type={"text"}
                      name={"firstName"}
                      placeHolder={"First name *"}
                      value={form.firstName}
                      className={""}
                      onChange={handleChange}
                      disabled={token ? true : false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      paddingLeft: { sm: 1 },
                      marginTop: { xs: 1.5, sm: "auto" },
                    }}
                  >
                    <TextInputField
                      type={"text"}
                      name={"lastName"}
                      placeHolder={"Last name *"}
                      className={""}
                      value={form.lastName}
                      onChange={handleChange}
                      disabled={token ? true : false}
                    />
                  </Grid>
                </Grid>
              </SpaceBox>
            )}

            <SpaceBox>
              <TextInputField
                type={"number"}
                name={"mobileNumber"}
                placeHolder={"Contact number"}
                value={form.mobileNumber}
                className={""}
                onChange={handleChange}
                disabled={false}
              />
              {/* <PhoneInput
                countryCode="IN"
                handleChange={handleNumberFieldChange}
                name="mobileNumber"
                value={form.mobileNumber}
              /> */}
            </SpaceBox>
            <SpaceBox>
              <TextInputField
                type={"text"}
                name={"email"}
                placeHolder={"Email here *"}
                className={""}
                value={form.email}
                disabled={token ? true : false}
                onChange={handleChange}
                sx={showError == 4 ? errorFieldStyle : undefined}
              />
              {showError == 4 && (
                <ErrorTypo>Enter valid email address</ErrorTypo>
              )}
            </SpaceBox>
            {/* <SubHeading1>
              Your booking details will be sent to this email address
            </SubHeading1> */}
          </Grid>
        </Grid>
      )}
      <LoginModal />
    </>
  );
}

export default ContactDetails;

const FilterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const SpaceBox = styled(Box)(({ theme }) => ({
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "space-between",
  marginBottom: 10,
}));
const FilterBoxMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  // marginBottom: 0,
  marginTop: 16,
}));
const HeadingReview = styled(Typography)(({ theme }) => ({
  color: "#DE3151",
  fontSize: 18,
  fontWeight: 600,
  marginLeft: 8,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    // marginBottom: 20,
    // marginTop: 30,
  },
  [theme.breakpoints.between("sm", "md")]: {
    // marginBottom: 30,
  },
}));
const SubHeading = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 14,
  fontWeight: 500,
  // marginTop: 20,
  marginBottom: 20,
}));
const SubHeading1 = styled(Typography)(({ theme }) => ({
  color: "#929292",
  fontSize: 15,
  fontWeight: 600,
  marginTop: 20,
  marginBottom: 20,
}));
const ErrorTypo = styled(Typography)(({ theme }) => ({
  color: "#ff4d4f",
  fontSize: 12,
}));
const errorFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px !important",
    borderColor: "#ff4d4f !important",
  },
};
