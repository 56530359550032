export interface ContactUsInterface {
  name: string;
  email: string;
  phoneNo: string;
  description: string;
  type: ContactUsTypeEnum;
}

export enum ContactUsTypeEnum {
  CONTACT_US = 1,
  CANCEL_BOOKING_QUERY = 2,
}
export interface PolicyInterface {
  privacyPolicyDescription: string;
  tmcDescription: string;
  refundPolicyDescription: string;
}
export interface BlogInterface {
  _id: string;
  title: string;
  description: string;
  category: BlogCategoryEnum;
  image: string;
}
export interface OffersInterface {
  _id: string;
  title: string;
  description: string;
  category: OffersCategoryEnum;
  valid: string;
  image: string;
}

export enum BlogCategoryEnum {
  All = 0,
  TRENDING = 1,
  NON_TRENDING = 2,
}

export enum OffersCategoryEnum {
  HOTEL = 0,
  FLIGHT = 1,
  TRIPS = 2,
}
export interface FaqInterface {
  _id: string;
  question: string;
  answer: string;
}
export interface HomeState {
  form: ContactUsInterface;
  tmcData: PolicyInterface;
  blogList: BlogInterface[];
  blogDetails: BlogInterface;
  loading: boolean;
  contactModal: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  totalPage: number;
  offerList: OffersInterface[];
  faqList: FaqInterface[];
  homeBanner:[]
}

export type InitialHomeState = HomeState;
