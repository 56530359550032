import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextInputField from "../../Component/TextInputField";
import { Card, CardContent, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCouponDiscount,
  selectCouponForm,
  SelectFlightBookingId,
} from "../../Redux/Booking/selector";
import { actions } from "../../Redux/Booking/slice";
import { actions as couponActions } from "../../Redux/flightSearch/slice";
import { toast } from "react-toastify";
import { SelectCouponList } from "../../Redux/flightSearch/selector";
import { CouponDiscountTypeEnum } from "../../Redux/flightSearch/types";
import CurrencyFormat from "../../Component/CurrencyFormat";

function CouponBox({ loading }: { loading: boolean }) {
  const form = useSelector(selectCouponForm);
  const couponData = useSelector(selectCouponDiscount);
  const bookingId = useSelector(SelectFlightBookingId);
  const couponList = useSelector(SelectCouponList);
  useEffect(() => {
    dispatch(couponActions.doGetCouponList());

    return () => {};
  }, []);

  const dispatch = useDispatch();
  const regex = /^[a-zA-Z0-9]*$/;
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (regex.test(value)) {
      dispatch(
        actions.updateCouponFormValue({
          key: name,
          value: value?.toUpperCase(),
        })
      );
    }
  };
  const handleClick = (code?: string) => {
    dispatch(
      actions.updateCouponFormValue({
        key: "bookingId",
        value: bookingId,
      })
    );
    if (code) {
      dispatch(
        actions.updateCouponFormValue({
          key: "couponCode",
          value: code,
        })
      );
      dispatch(
        actions.doAddCouponCode({
          callback() {},
        })
      );
    } else {
      if (couponData?.couponCode?.length > 0) {
        dispatch(
          actions.updateCouponFormValue({
            key: "couponCode",
            value: "",
          })
        );
      } else if (
        couponData?.couponCode?.length == 0 &&
        form?.couponCode?.length == 0
      ) {
        toast.error("Please fill coupon code");
        return;
      }
      dispatch(
        actions.doAddCouponCode({
          callback() {},
        })
      );
    }
  };
  return (
    <>
      {loading ? (
        <>
          {/* <Coupon className="flightCardBorder">
            <ApplyCoupon>
              <Skeleton animation="wave" height={30} width={"100%"} />
            </ApplyCoupon>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
              className="flightCardBorder"
              px={2}
            >
              <Skeleton width="60%" height={30} animation="wave" />
              <Skeleton width="20%" height={30} animation="wave" />
            </Box>
          </Coupon> */}
        </>
      ) : (
        <Coupon className="flightCardBorder">
          <ApplyCoupon>Apply coupon or gift card</ApplyCoupon>
          <Box display="flex" alignItems="center" className="flightCardBorder">
            <TextInputField
              type={""}
              name={"couponCode"}
              placeHolder={"Coupon code"}
              value={form.couponCode}
              className={""}
              onChange={handleChange}
              disabled={false}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              maxLength={8}
            />
            <CustomButton disabled={loading} onClick={() => handleClick()}>
              {couponData?.couponCode?.length > 0 ? "Remove" : "Apply"}
            </CustomButton>
          </Box>
          <Box
            sx={{
              height: 200,
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            {couponList.map((data) => {
              return (
                <Card
                  sx={{
                    margin: "auto",
                    mt: 2,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <CouponCard>
                        <CouponCode>{data.title}</CouponCode>
                      </CouponCard>
                      <CustomButton
                        disabled={loading}
                        onClick={() => handleClick(data.title)}
                      >
                        {couponData?.couponCode == data.title
                          ? "Remove"
                          : "Apply"}
                      </CustomButton>
                    </Box>

                    <Description sx={{ mt: 1 }}>
                      Flat &nbsp;
                      {data.couponDiscountType ==
                      CouponDiscountTypeEnum.PERCENTAGE ? (
                        <>{data.couponDiscount}&nbsp;%</>
                      ) : (
                        <>
                          <CurrencyFormat value={data.couponDiscount} />
                        </>
                      )}
                      &nbsp; off.
                    </Description>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        </Coupon>
      )}
    </>
  );
}

export default CouponBox;
const Coupon = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: "16px 24px 16px 24px",
  marginTop: 16,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const ApplyCoupon = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 18,
  fontWeight: 600,
  paddingBottom: 16,
  display: "flex",
  alignItems: "center",
}));
const CustomButton = styled(Button)({
  backgroundColor: "transparent",
  fontSize: 13,
  fontWeight: 600,
  color: "#DE3151",
  border: "none",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "&:focus": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
});
const CouponCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 1),
  backgroundColor: "#ecf3ff",
  borderRadius: theme.shape.borderRadius,
}));

const CouponCode = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
}));
const Description = styled(Typography)(({ theme }) => ({
  color: "hsl(0, 0%, 50%)",
  fontSize: 15,
  fontWeight: 600,
  display: "flex",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
