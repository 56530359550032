import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import ContactUs from "../ContactUs";
import { Link } from "react-router-dom";
import { actions } from "../../Redux/Home/slice";
import { ContactUsTypeEnum } from "../../Redux/Home/types";

const NewLink = styled(Link)({
  textDecoration: "none",
});

function Footer({
  hasFooter = true,
  homepage = false,
}: {
  hasFooter?: boolean;
  homepage?: boolean;
}) {
  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch(actions.toggleOpenContactModal());
  };
  return (
    <Box sx={{ display: `${hasFooter == true ? "block" : "none"}`, mt: 2 }}>
      <Container>
        <Grid container rowGap={2} pb={1}>
          <Grid item xs={12} md={"auto"}>
            <NewLink to={"/privacy-policy"}>
              <FooterText sx={homepage == true ? { color: "#fff" } : undefined}>
                Privacy Policy
              </FooterText>
            </NewLink>
          </Grid>
          <Grid item xs={12} md={"auto"}>
            <NewLink to={"/tmc"}>
              <FooterText sx={homepage == true ? { color: "#fff" } : undefined}>
                Terms & Conditions
              </FooterText>
            </NewLink>
          </Grid>
          <Grid item xs={12} md={"auto"}>
            <NewLink to={"/refund-policy"}>
              <FooterText sx={homepage == true ? { color: "#fff" } : undefined}>
                Refund Policy
              </FooterText>
            </NewLink>
          </Grid>
          <Grid item xs={12} md={"auto"}>
            <NewLink to={"/faq"}>
              <FooterText sx={homepage == true ? { color: "#fff" } : undefined}>
                FAQ's
              </FooterText>
            </NewLink>
          </Grid>
          <Grid item xs={12} md={"auto"}>
            <FooterText
              sx={homepage == true ? { color: "#fff" } : undefined}
              onClick={handleOpen}
            >
              Contact Us
            </FooterText>
          </Grid>
        </Grid>
        <ContactUs type={ContactUsTypeEnum.CONTACT_US} />
      </Container>
    </Box>
  );
}

export default Footer;
const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  color: "rgba(37, 37, 37, 1)",
  cursor: "pointer",
  marginRight: 48,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    marginRight: 0,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
    marginRight: 0,
  },
}));
