import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import moment from "moment";
import { log } from "console";
import { PassengerPaxTypeEnum } from "../../Redux/Login/types";
interface Props {
  value: string;
  type: any;
  name: string;
  onChange: any;
  disabled: any;
  placeholder?: string;
}
function calendarIcon() {
  return (
    <img
      src="/images/calendar.svg"
      alt=""
      // width={32}
    />
  );
}
export default function NewDatePicker(props: Props) {
  const today = new Date();

  // Calculate the date two years ago
  const twoYearsAgo = new Date(today);
  twoYearsAgo.setFullYear(today.getFullYear() - 2);
  const twelveYearsAgo = new Date(today);
  twelveYearsAgo.setFullYear(today.getFullYear() - 12);
  const adultYearsAgo = new Date(today);
  adultYearsAgo.setFullYear(today.getFullYear() - 100);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slots={{
            openPickerIcon: calendarIcon,
          }}
          slotProps={{
            inputAdornment: {
              position: "start",
            },
            layout: {
              sx: {
                "& .MuiButtonBase-root.Mui-selected": {
                  backgroundColor: "#DE3151 !important",
                },
                "& .MuiButtonBase-root:hover": {
                  backgroundColor: "#DE3151 !important",
                  color: "#fff",
                },
                "& .MuiPickersYear-yearButton.Mui-selected": {
                  backgroundColor: "#DE3151 !important",
                },
                "& .MuiPickersYear-yearButton:hover": {
                  backgroundColor: "#DE3151 !important",
                  color: "#fff",
                },
              },
            },
            textField: { placeholder: props?.placeholder },
          }}
          disableFuture={true}
          // minDate={
          //   //@ts-ignore
          //   props?.type == PassengerPaxTypeEnum.Infant
          //     ? dayjs(twoYearsAgo)
          //     : //@ts-ignore
          //     props?.type == PassengerPaxTypeEnum.Child
          //     ? dayjs(twelveYearsAgo)
          //     : //@ts-ignore
          //     props?.type == PassengerPaxTypeEnum.Adult
          //     ? dayjs(adultYearsAgo)
          //     : null
          // }
          // maxDate={
          //   //@ts-ignore
          //   props?.type == PassengerPaxTypeEnum.Infant
          //     ? dayjs(new Date())
          //     : //@ts-ignore
          //     props?.type == PassengerPaxTypeEnum.Child
          //     ? dayjs(twoYearsAgo)
          //     : //@ts-ignore
          //     props?.type == PassengerPaxTypeEnum.Adult
          //     ? dayjs(twelveYearsAgo)
          //     : null
          // }
          disabled={props?.disabled}
          value={props?.value ? dayjs(props?.value) : null}
          format="ddd, MMM D"
          onChange={props?.onChange}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0px !important",
            },
            "& .MuiInputBase-root-MuiOutlinedInput-root": {
              paddingLeft: "0px !important",
            },
            "& .MuiInputBase-input": {
              fontWeight: "400 !important",
              fontSize: "12px !important",
              padding: "12.5px 14px 12.5px 0 !important",
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}
