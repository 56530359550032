import MainLayout from "../../Component/Layout/MainLayout";
import { selectFaqList, selectLoading } from "../../Redux/Home/selector";
import { actions } from "../../Redux/Home/slice";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../Style/theme";

function FAQ() {
  const dispatch = useDispatch();
  const faqs = useSelector(selectFaqList);
  const loading = useSelector(selectLoading);

  React.useEffect(() => {
    dispatch(actions.doGetFaqList());
  }, []);

  return (
    <>
      <MainLayout>
        <Container sx={{ minHeight: "calc(100vh - 200px)", pb: 4 }}>
          <TitleTypo>Frequently Asked Questions</TitleTypo>
          {loading ? (
            <Box className="textCenter">
              <CircularProgress sx={loadingStyle} />
            </Box>
          ) : (
            <>
              {faqs.map((data: any, index: number) => (
                <Box sx={{ display: "flex", alignItems: "start", mt: 4 }}>
                  <QuestionTypo>{index + 1}.&nbsp;</QuestionTypo>
                  <Box key={data?._id}>
                    <QuestionTypo>{data?.question}</QuestionTypo>
                    <DescriptionTypo
                      dangerouslySetInnerHTML={{
                        __html: data?.answer?.split("\n")?.join("<br/>"),
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Container>
      </MainLayout>
    </>
  );
}

export default FAQ;
const TitleTypo = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: 28,
  color: "rgba(0, 0, 0, 1)",
  marginTop: 36,
  marginBottom: 30,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    marginTop: 22,
    marginBottom: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 24,
    marginTop: 26,
    marginBottom: 18,
  },
}));
const DescriptionTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  color: "#5E5E5E",
  textAlign: "unset",
  lineHeight: "29.3px",
  // paddingLeft: 23,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    // paddingLeft: 16,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
    // paddingLeft: 16,
  },
}));
const QuestionTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  color: "rgba(0, 0, 0, 1)",
  marginBottom: 10,
  textAlign: "unset",
  lineHeight: "29.3px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    marginBottom: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const loadingStyle = {
  color: "red",
  width: "48px",
  height: "48px",
  [theme.breakpoints.down("md")]: {
    width: "24px",
    height: "24px",
  },
};
