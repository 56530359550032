import Selector from "../../Selector/index2";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import React from "react";
import AirportSelector from "../../Selector/airportSelector";
import PersonSelector from "../../PersonSelector";
import { useDispatch, useSelector } from "react-redux";
import { selectForm } from "../../../Redux/flightSearch/selector";
import { actions } from "../../../Redux/flightSearch/slice";
import {
  TravelClassEnum,
  TripTypesEnum,
} from "../../../Redux/flightSearch/types";
import { DateTime } from "luxon";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as FlightSearchSliceKey,
  reducer as FlightSearchReducer,
} from "../../../Redux/flightSearch/slice";
import NewDatePicker from "../../DataPicker/NewDatePicker";
import { FlightSearchRepoSaga } from "../../../Redux/flightSearch/saga";
import FlightListingAirportSearch from "../../AirportSearch/FlightListingAirportSearch";
import AirportSearch from "../../AirportSearch";

interface Props {
  airportData: any;
  handleSearch: any;
}

function Index(props: Props) {
  useInjectSaga({ key: FlightSearchSliceKey, saga: FlightSearchRepoSaga });
  useInjectReducer({ key: FlightSearchSliceKey, reducer: FlightSearchReducer });
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const searchParams = new URLSearchParams(window.location.search);
  const source = searchParams.get("source");
  const destination = searchParams.get("destination");
  const returnDate = searchParams.get("returnDate");
  const handleSelectAirport = (
    value: {
      airportCode: string;
    } | null,
    name: string
  ) => {
    dispatch(
      actions.updateFormValue({
        key: name,
        value: value?.airportCode,
      })
    );
    dispatch(actions.doGetAirportList(" "));
  };

  const handleSelect = (evt: any) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
    if (name == "tripType" && value == 1) {
      dispatch(
        actions.updateFormValue({
          key: "returnDate",
          value: null,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "returnArrivalDate",
          value: DateTime.now().toISO(),
        })
      );
    }
  };
  const handleInterChange = () => {
    dispatch(
      actions.updateFormValue({ key: "source", value: form.destination })
    );
    dispatch(
      actions.updateFormValue({ key: "destination", value: form.source })
    );
  };
  const AnyTime = "00:00:00";
  const handleChangeDate = (val: any) => {
    const newValue = DateTime.fromJSDate(val?.$d).toISO();
    const d: any = newValue?.split("T");
    console.log(d[0], "concatDate");
    const concatDate = d[0].concat("T", AnyTime);
    console.log(concatDate, "concatDate");
    dispatch(
      actions.updateFormValue({
        key: "journeyDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "journeyArrivalDate",
        value: concatDate,
      })
    );
  };
  const handleChangeArrivalDate = (val: any) => {
    const newValue = DateTime.fromJSDate(val?.$d).toISO();
    const d: any = newValue?.split("T");
    console.log(d[0], "concatDate");
    const concatDate = d[0].concat("T", AnyTime);
    console.log(concatDate, "concatDate");
    dispatch(
      actions.updateFormValue({
        key: "returnDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "returnArrivalDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "tripType",
        value: TripTypesEnum.ROUNDTRIP,
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      actions.updateFormValue({
        key: "source",
        value: source,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "destination",
        value: destination,
      })
    );
    return () => {};
  }, [source, destination]);

  React.useEffect(() => {
    if (
      form.tripType == TripTypesEnum.ROUNDTRIP &&
      form.returnDate < form.journeyDate
    ) {
      dispatch(
        actions.updateFormValue({
          key: "returnDate",
          value: form.journeyDate,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "returnArrivalDate",
          value: form.journeyDate,
        })
      );
    }
    return () => {};
  }, [form.journeyDate, form.tripType == TripTypesEnum.ROUNDTRIP]);
  return (
    <>
      <Grid container columnGap={1.5} rowGap={1.5}>
        <Grid item xs={12} sm={2.5} md={1.5} lg={1.3}>
          <Selector
            data={routeData}
            name={"tripType"}
            value={form.tripType}
            onChange={handleSelect}
            disabled={undefined}
            placeholder={""}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={3.7}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <FlightListingAirportSearch />
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, position: "relative" }}
          >
            <AirportSearch />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} lg={2.7}>
          {/* {form.tripType == TripTypesEnum.ONE_WAY ? (
            <Box className="dateAndTripBorder">
              <NewDatePicker
                name={"journeyDate"}
                onChange={handleChangeDate}
                disabled={undefined}
                value={form.journeyDate}
                disablePast={true}
                disableFuture={false}
                minDate={null}
              />
            </Box>
          ) : ( */}
          <Grid
            container
            className="dateAndTripBorder"
            sx={{ position: "relative" }}
          >
            <Grid item xs={5.6} sm={6}>
              <NewDatePicker
                name={"journeyDate"}
                onChange={handleChangeDate}
                disabled={undefined}
                value={form.journeyDate}
                disablePast={true}
                disableFuture={false}
                minDate={null}
              />
            </Grid>
            {/* {form.tripType == TripTypesEnum.ROUNDTRIP && ( */}
            <Grid
              item
              xs={5.9}
              sm={6}
              className="textCenter"
              sx={{ borderLeft: "1px solid #e5e5e5" }}
            >
              <NewDatePicker
                name={"returnDate"}
                onChange={handleChangeArrivalDate}
                disabled={undefined}
                value={form.returnDate}
                disablePast={true}
                disableFuture={false}
                minDate={
                  form?.tripType == TripTypesEnum.ROUNDTRIP ? form?.journeyDate : null
                }
                placeholder="Return"
              />
            </Grid>
            {/* )} */}
          </Grid>
          {/* // )} */}
        </Grid>
        <Grid item xs={12} sm={3.5} md={2.5} lg={1.5} className="border">
          <PersonSelector space={true} />
        </Grid>
        <Grid item xs={12} sm={3} md={2} lg={1.5}>
          <Selector
            data={FlightClassData}
            name={"travelClass"}
            value={form.travelClass}
            onChange={handleSelect}
            disabled={undefined}
            placeholder={"Select Flight Class"}
          />
        </Grid>
        <Grid item xs={12} sm={1.5} md={0.8} lg={0.6}>
          <SearchButton className="textCenter" onClick={props.handleSearch}>
            <img src="/images/search.svg" alt="" width={"20px"} />
          </SearchButton>
        </Grid>
      </Grid>
    </>
  );
}

export default Index;

const FlightClassData = [
  {
    _id: TravelClassEnum.ECONOMY,
    item: "Economy",
  },
  {
    _id: TravelClassEnum.PREMIUM_ECONOMY,
    item: "Premium Economy",
  },
  {
    _id: TravelClassEnum.BUSINESS,
    item: "Business Class",
  },
  {
    _id: TravelClassEnum.FIRST_CLASS,
    item: "First Class",
  },
];
const routeData = [
  {
    _id: TripTypesEnum.ONE_WAY,
    item: "One Way",
  },
  {
    _id: TripTypesEnum.ROUNDTRIP,
    item: "Two Way",
  },
];
const SearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 56, 60, 1)",
  borderRadius: 8,
  padding: 6.7,
  width: "100%",
  height: "100%",
  // height: "auto",
  "&:hover": {
    backgroundColor: "rgba(219, 56, 60, 1)",
  },
  [theme.breakpoints.down("sm")]: {
    // height: "100%",
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
const ReturnTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.87)",
  fontWeight: 700,
  padding: "16.5px",
  [theme.breakpoints.down("sm")]: {},
}));
const YearsTypo = styled(Typography)(({ theme }) => ({
  fontSize: 8,
  color: "rgba(37, 37, 37, 0.6)",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {},
}));
const PassengerCategoryTypo = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: "rgba(37, 37, 37, 1)",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const AddBox = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(222, 49, 81, 0.1)",
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  borderRadius: 8,
  padding: "0px 8px",
  marginLeft: 10,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
  },
}));
