import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Pagination, PaginationItem, Stack } from "@mui/material";
import React from "react";

interface Props {
  pageNo: number;
  count: number;
  handleChange: any;
  handlePlus: any;
  handleMinus: any;
}

function Index(props: Props) {
  return (
    <div>
      <Box textAlign={"center"} py={5}>
        {props.count >= 1 && (
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <Pagination
            variant="outlined"
            shape="rounded"
            size="large"
            className={"styles.muiPaginationItem"}
            count={props.count}
            page={props.pageNo}
            onChange={props.handleChange}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                components={{
                  next: () => (
                    <div
                      onClick={() => props.handlePlus(1)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      NEXT <KeyboardArrowRight />
                    </div>
                  ),
                  previous: () => (
                    <div
                      onClick={() => props.handleMinus(1)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <KeyboardArrowLeft /> PREV{" "}
                    </div>
                  ),
                }}
              />
            )}
          />
        </Stack>
        )} 
      </Box>
    </div>
  );
}

export default Index;
