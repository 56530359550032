import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { OutlinedInput, styled } from "@mui/material";
import { useStyles } from "./style";

interface Props {
  name: string;
  placeholder: string;
  value: any;
  onChange: any;
  className: string;
  data: any;
  disabled: any;
}

export default function BasicSelect(props: Props) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth sx={{ maxHeight: "150px" }}>
        <Select
          size="small"
          disabled={props.disabled}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          input={<BootstrapInput />}
          displayEmpty
          renderValue={
            props?.value !== "" || undefined
              ? undefined
              : () => `${props.placeholder}`
          }
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            " fieldset": {
              border: "none !important",
              outline: "none !important",
            },
            textTransform: "capitalize",
            maxHeight: "150px",
          }}
          className="fw-semibold"
          variant="outlined"
        >
          {props?.data?.map((item: any) => (
            <MenuItem
              key={item._id}
              value={item.code}
              className="selectorText fw-semibold fs-13"
              sx={{ maxHeight: "150px", textTransform: "capitalize" }}
            >
              {item?.name.length > 45
                ? item.name.slice(0, 45) + "..."
                : item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
const BootstrapInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 12,
    padding: "12.5px 14px 12.5px 14px",
  },
}));
