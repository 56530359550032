import RoundedButton from "../../Component/Chip/RoundedButton";
import MainLayout from "../../Component/Layout/MainLayout";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectToken } from "../../Redux/Login/selector";
import { actions } from "../../Redux/Login/slice";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/Api";

function BookingConfirm() {
  return (
    <>
      <MainLayout>
        <Container sx={{ minHeight: "calc(100vh - 200px)" }}>
          <MainBox>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "static",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "transparent",
                paddingTop: "calc(40vh - 100px)",
              }}
            >
              <CircularProgress />
            </Box>
            <br />
          </MainBox>{" "}
          <DescriptionTypo>
            Please wait for few second your booking in progress
          </DescriptionTypo>
        </Container>
      </MainLayout>
    </>
  );
}

export default BookingConfirm;
const MainBox = styled(Box)(({ theme }) => ({
  marginTop: 64,
  marginBottom: 64,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginTop: 32,
    marginBottom: 32,
    paddingRight: "0%",
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginTop: 32,
    marginBottom: 32,
    paddingRight: "0%",
  },
}));
const TitleTypo = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: 24,
  color: "rgba(0, 0, 0, 1)",
  marginBottom: 30,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 22,
  },
}));
const ImportantNoteBox = styled(Box)(({ theme }) => ({
  width: "max-content",
  marginTop: 35,
  marginBottom: 25,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 22,
  },
}));
const DescriptionTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  color: "rgba(37, 37, 37, 1)",
  marginBottom: 10,
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const redTextStyle = {
  color: "rgba(222, 49, 81, 1)",
  fontWeight: 700,
};
const userMailStyle = {
  color: "rgba(37, 37, 37, 1)",
  fontWeight: 700,
};
const NewLink = styled(Link)({
  textDecoration: "none",
});
