import { Button, CircularProgress, styled } from "@mui/material";
import React from "react";
interface Props {
  loading: boolean;
  handleClick: any;
  title: string;
  sx?: any;
}
function Index(props: Props) {
  return (
    <div>
      <NewButton
        onClick={props.handleClick}
        disabled={props.loading}
        sx={props?.sx}
      >
        {props.loading ? (
          <CircularProgress
            sx={{
              width: "30px !important",
              height: "30px !important",
              color: "#fff",
            }}
          />
        ) : (
          `${props.title}`
        )}
      </NewButton>
    </div>
  );
}

export default Index;
const NewButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  color: "#DB383C",
  padding: "6px 6px",
  width: 120,
  cursor: "pointer",
  backgroundColor: "#ffffff",
  textTransform: "none",
  border: "2px solid #DB383C",
  borderRadius: 8,
  "&:hover": {
    backgroundColor: "#ffffff",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    width: 80,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
    width: 90,
  },
}));
