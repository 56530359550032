import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Skeleton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {
  FlightBookingDetailFilterEnum,
  UserBookingListInterface,
} from "../../../Redux/Login/types";
import { DateTime } from "luxon";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPageNo,
  selectTotalPage,
  selectBookingCancelForm,
  SelectToken,
  selectStatus,
  SelectLoading,
  selectHasMore,
  selectBookingList,
} from "../../../Redux/Login/selector";
import Pagination from "../../Pagination";
import { actions } from "../../../Redux/Login/slice";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as AuthSliceKey,
  reducer as AuthReducer,
} from "../../../Redux/Login/slice";
import { AuthRepoSaga } from "../../../Redux/Login/saga";
import WhiteButton from "../../BookButton/whiteButton";
import BookButton from "../../BookButton";
import theme from "../../../Style/theme";
import { BASE_URL, path } from "../../../utils/Api";
import ShowUserDetails from "./ShowUserDetails";
import StatusChip from "./StatusChip";
import DownloadButton from "./DownloadButton";
import SkeletonLoading from "./SkeletonLoading";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../WindowHeight";
import { useStaticPicker } from "@mui/x-date-pickers/internals";

interface Props {
  loading: boolean;
}

function Index(props: Props) {
  useInjectReducer({ key: AuthSliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [show, setShow] = React.useState(false);
  const [bookingId, setBookingId] = React.useState("");
  const handleOpen = () => {
    setShow(true);
  };
  const handleCancel = () => {
    setShow(false);
    dispatch(actions.clearCancelBookingForm());
  };
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const pageNo = useSelector(selectPageNo);
  const hasMore = useSelector(selectHasMore);
  const cancelForm = useSelector(selectBookingCancelForm);
  const TotalPage = useSelector(selectTotalPage);
  const list = useSelector(selectBookingList);
  const statusFilter = useSelector(selectStatus);
  const cancelLoading = useSelector(SelectLoading);
  const calculateTimeDifference = (date1: string, date2: string) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);

    const duration = moment.duration(momentDate2.diff(momentDate1));

    const hours = duration.hours();
    const minutes = duration.minutes();

    const formattedDifference = `${hours}h ${minutes} m`;
    return formattedDifference;
  };

  React.useEffect(() => {
    dispatch(
      actions.doGetBookingList({
        callback() {},
      })
    );
    return () => {};
  }, [pageNo]);

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement>,
    id: string
  ) => {
    setBookingId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSupport = () => {
    window.location.href =
      "mailto:support@gmail.com?subject=Subject&body=message%20goes%20here";
  };
  const handleCancelBooking = () => {
    dispatch(
      actions.updateBookingCancelFormValue({
        key: "bookingId",
        value: bookingId,
      })
    );
    dispatch(
      actions.doCancelBooking({
        callback() {
          dispatch(actions.clearCancelBookingForm());
          dispatch(
            actions.doGetBookingList({
              callback() {},
            })
          );
          handleClose();
          handleCancel();
        },
      })
    );
  };
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateBookingCancelFormValue({ key: name, value: value }));
  };

  const isToken = useSelector(SelectToken);

  React.useEffect(() => {
    console.log(BASE_URL, "url");
    let token = localStorage.getItem("token");
    dispatch(actions.setToken(token || ""));

    return () => {};
  }, [isToken]);

  const handleDownloadTicket = (id: string) => {
    window.open(
      `${BASE_URL()}airports/export-ticket/${id}?token=${isToken}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleShowCancelDetails = (id: string) => {
    setAnchorEl(null);
    dispatch(
      actions.doGetCancelPassengerList({
        id: id,
        callback() {
          dispatch(actions.toggleShowUseDetailsModal(true));
        },
      })
    );
  };

  const fetchData = () => {
    dispatch(actions.setPageNo(pageNo + 1));
  };
  const { height } = useWindowDimensions();
  const [heightStyle, setHeightStyle] = useState(0);
  useEffect(() => {
    setHeightStyle(height / 1.3);

    return () => {};
  }, [heightStyle, height]);

  return (
    <>
      {props.loading ? (
        <SkeletonLoading />
      ) : (
        <>
          <div
            id="scrollableDiv"
            style={{ height: heightStyle, overflow: "auto" }}
          >
            <InfiniteScroll
              dataLength={list.length}
              next={fetchData}
              hasMore={hasMore}
              loader={
                <LoaderBox>
                  <CircularProgress
                    sx={{
                      width: "30px !important",
                      height: "30px !important",
                      margin: "0px 10px",
                      color: "#DE3151",
                    }}
                  />
                </LoaderBox>
              }
              height={heightStyle}
              scrollableTarget="scrollableDiv"
              className={`infinite-scroll-component`}
            >
              {list.map((data) => {
                return (
                  <FlightCard className="flightCardBorder" key={data._id}>
                    <Grid container sx={{ pl: { xs: 0, md: 3 } }}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            className="textAlign"
                            sx={{
                              justifyContent: {
                                xs: "space-between",
                                sm: "unset",
                              },
                            }}
                          >
                            <BookingTitleTypo className="textAlign">
                              Booking I’d&ensp;&nbsp;
                              <BookingTitleTypo
                                sx={{
                                  color: "#DE3151",
                                  fontWeight: 500,
                                }}
                              >
                                {data?.BookingId}
                              </BookingTitleTypo>
                            </BookingTitleTypo>
                            <img
                              src="/images/dotMenu.svg"
                              alt="Menu"
                              className="dotMenuMobileIcon"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(e) => handleClick(e, data.BookingId)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            className="textAlign"
                            sx={{ justifyContent: "space-between" }}
                          >
                            <BookingTitleTypo className="textAlign">
                              PNR&ensp;&nbsp;
                              <BookingTitleTypo
                                sx={{
                                  color: "#DE3151",
                                  fontWeight: 500,
                                }}
                              >
                                {data.PNR}
                              </BookingTitleTypo>
                            </BookingTitleTypo>
                            <BookingTitleTypo className="textAlign">
                              {DateTime?.fromISO(data?.tripDate)?.toFormat(
                                "dd MMM yyyy"
                              )}
                            </BookingTitleTypo>

                            <BookingTitleTypo
                              className="textAlign"
                              sx={{ pl: 3 }}
                            >
                              <StatusChip
                                variant={
                                  statusFilter ==
                                  FlightBookingDetailFilterEnum.Upcoming
                                    ? "upcoming"
                                    : statusFilter ==
                                      FlightBookingDetailFilterEnum.Cancel
                                    ? "Cancel"
                                    : statusFilter ==
                                      FlightBookingDetailFilterEnum.Past
                                    ? "Past"
                                    : statusFilter ==
                                      FlightBookingDetailFilterEnum.Payment_failed
                                    ? "Payment Failed"
                                    : statusFilter ==
                                      FlightBookingDetailFilterEnum.Refund
                                    ? "Refund"
                                    : "upcoming"
                                }
                              />
                            </BookingTitleTypo>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={3}
                            className="textAlign"
                            sx={{
                              justifyContent: {
                                xs: "end",
                                // sm: "space-between",
                              },
                            }}
                          >
                            <img
                              src="/images/dotMenu.svg"
                              alt="Menu"
                              className="dotMenuDesktopIcon"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={(e) => handleClick(e, data.BookingId)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} sm={4} md={3} sx={{ mt: 2 }}>
                            <Box className="textAlign">
                              <img
                                src={
                                  data?.bookingDetailsRequest?.AirlineLogo
                                    ? path() +
                                      data?.bookingDetailsRequest?.AirlineLogo
                                    : "/images/airplane.png"
                                }
                                alt="Logo"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginRight: 18,
                                }}
                              />
                              <Box>
                                <AirlineNameTypo>
                                  {data?.bookingDetailsRequest?.AirlineName}
                                </AirlineNameTypo>
                                <CountryCodeTypo>
                                  {data?.bookingDetailsRequest?.AirlineCode}{" "}
                                  {data?.bookingDetailsRequest?.FlightNumber}
                                </CountryCodeTypo>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={8} md={6} sx={{ mt: 2 }}>
                            <Grid container>
                              <Grid item xs={4}>
                                {" "}
                                <Box textAlign={"center"}>
                                  <FlightTimeTypo>
                                    {DateTime?.fromISO(
                                      data?.bookingDetailsRequest?.DepTime
                                    )?.toFormat("HH:mm")}
                                  </FlightTimeTypo>

                                  <DestinationTypo>
                                    {
                                      data?.bookingDetailsRequest
                                        ?.OriginCityName
                                    }
                                  </DestinationTypo>
                                  <DestinationTypo>
                                    {
                                      data?.bookingDetailsRequest
                                        ?.OriginAirportName
                                    }
                                  </DestinationTypo>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box textAlign={"center"}>
                                  <TimeTypo
                                    sx={{
                                      width: "fit-content",
                                      margin: "auto",
                                    }}
                                  >
                                    {calculateTimeDifference(
                                      data?.bookingDetailsRequest?.DepTime,
                                      data?.bookingDetailsRequest?.ArrTime
                                    )}
                                  </TimeTypo>
                                  <AirlineNameTypo
                                    sx={{
                                      color: "rgba(37, 37, 37, 0.7)",
                                    }}
                                  >
                                    {data?.stops == 1 ? (
                                      "non-stop"
                                    ) : (
                                      <>{data?.stops} stop</>
                                    )}
                                  </AirlineNameTypo>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box textAlign={"center"}>
                                  <FlightTimeTypo>
                                    {DateTime?.fromISO(
                                      data?.bookingDetailsRequest?.ArrTime
                                    )?.toFormat("HH:mm")}
                                  </FlightTimeTypo>
                                  <DestinationTypo>
                                    {
                                      data?.bookingDetailsRequest
                                        ?.DestinationCityName
                                    }
                                  </DestinationTypo>
                                  <DestinationTypo>
                                    {
                                      data?.bookingDetailsRequest
                                        ?.DestinationAirportName
                                    }
                                  </DestinationTypo>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>

                          {statusFilter ==
                            FlightBookingDetailFilterEnum.Upcoming && (
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={3}
                              sx={{
                                mt: 2,
                                display: { xs: "flex", md: "block" },
                                justifyContent: {
                                  xs: "space-between",
                                  md: "start",
                                },
                              }}
                            >
                              <Box sx={{ ml: { xs: 0, md: 3, lg: 4 } }}>
                                <DownloadButton
                                  variant="ticket"
                                  handleClick={() =>
                                    handleDownloadTicket(data.BookingId)
                                  }
                                />
                              </Box>
                              <Box
                                sx={{
                                  ml: { xs: 0, md: 3, lg: 4 },
                                  mt: { md: 1 },
                                }}
                              >
                                <DownloadButton
                                  variant="invoice"
                                  handleClick={() =>
                                    handleDownloadTicket(data.BookingId)
                                  }
                                />
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </FlightCard>
                );
              })}
            </InfiniteScroll>
          </div>
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {statusFilter == FlightBookingDetailFilterEnum.Upcoming && (
                <MenuItem
                  onClick={() => handleShowCancelDetails(bookingId)}
                  className="fw-semibold fs-12"
                  sx={{
                    minHeight: "auto !important",
                    color: "rgba(37, 37, 37, 1)",
                    "&: hover": {
                      backgroundColor: "rgba(219, 56, 60, 0.05) !important",
                      color: "#DE3151",
                    },
                  }}
                >
                  Cancel Booking
                </MenuItem>
              )}

              <MenuItem
                onClick={handleSupport}
                className="fw-semibold fs-12"
                sx={{
                  minHeight: "auto !important",
                  color: "rgba(37, 37, 37, 1)",
                  "&: hover": {
                    backgroundColor: "rgba(219, 56, 60, 0.05) !important",
                    color: "#DE3151",
                  },
                }}
              >
                Support
              </MenuItem>
            </Menu>
          </div>
          {show && (
            <Modal open={show}>
              <Box sx={style}>
                <Grid
                  container
                  spacing={{ xs: 0, sm: 3.5 }}
                  rowGap={{ xs: 2, sm: 0 }}
                >
                  <Grid item xs={12}>
                    <div className="border">
                      <TextField
                        rows={4}
                        multiline
                        fullWidth
                        size="small"
                        variant="outlined"
                        type={"text"}
                        name={"Remarks"}
                        placeholder={"please fill your cancel booking reason"}
                        value={cancelForm.Remarks}
                        onChange={handleChange}
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            padding: "12.5px 14px !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "8px !important",
                            borderColor: "rgba(0, 0, 0, 0.5) !important",
                          },
                          " fieldset": {
                            outline: "0px !important",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid rgba(222, 49, 81, 1) !important",
                          },
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    className=""
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                    mt={5}
                  >
                    <WhiteButton
                      handleClick={handleCancel}
                      loading={false}
                      title="Cancel"
                    />
                  </Grid>
                  <Grid item xs={6} className="" mt={5}>
                    <BookButton
                      handleClick={handleCancelBooking}
                      loading={false}
                      title="Submit"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          )}
        </>
      )}
      <ShowUserDetails />
      {cancelLoading && (
        <Modal open={cancelLoading}>
          <Box sx={loadingStyle}>
            <CircularProgress
              sx={{
                width: "40px !important",
                height: "40px !important",
                margin: "0px 10px",
                color: "#DE3151",
                textAlign: "center",
              }}
            />
          </Box>
        </Modal>
      )}
    </>
  );
}

export default Index;
const FlightCard = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: 16,
  // marginTop: 16,
  marginBottom: 8,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const BookingTitleTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 16,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 13,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const AirlineNameTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 14,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const CountryCodeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.7)",
  fontSize: 12,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FlightTimeTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 4,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const DestinationTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 12,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
const TimeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.6)",
  fontSize: 14,
  fontWeight: 600,
  paddingBottom: 4,
  marginBottom: 8,
  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
const DownloadTicketButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  border: "1px solid rgba(0, 119, 26, 1)",
  backgroundColor: "rgba(0, 119, 26, 0.05)",
  borderRadius: 35,
  width: 140,
  padding: "6px 12px",
  [theme.breakpoints.down("sm")]: {
    width: 140,
    padding: 6,
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: 150,
  },
}));
const DownloadTicketButtonText = styled(Box)(({ theme }) => ({
  color: "rgba(0, 119, 26, 1)",
  fontSize: 12,
  fontWeight: 700,
}));
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
  padding: "35px 40px",
  borderRadius: 3,
  // height: ,
  outline: 0,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "95%",
    padding: "20px 20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "auto",
    width: "70%",
    padding: "30px 30px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: "auto",
    width: "80%",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    height: "auto",
    width: "60%",
  },
};
const loadingStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: 0,
  border: 0,
  backgroundColor: "transparent",
};
const LoaderBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  bottom: 8,
  left: "50%",
  transform: "translateX(-50%)",
}));
