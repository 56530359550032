import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import {
  CancelPassengerListInterface,
  CancelPassengerTotalBreakdownInterface,
  GenderEnum,
  IUserDataInterface,
  PassengerPaxTypeEnum,
  PassengerTitleEnum,
  RemarksTypeEnum,
  TravellersInterface,
  UserProfileInterface,
} from "./types";
import { set } from "lodash";
import { DateTime } from "luxon";

export const useClinicSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    toggleOpenLogin: (state, action: PayloadAction<boolean>) => {
      state.loginModal = action.payload;
    },
    toggleOpenDelete: (state) => {
      state.deleteModal = !state.deleteModal;
    },
    toggleOpenPassenger: (state) => {
      state.passengerModal = !state.passengerModal;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setBookingLoading: (state, action: PayloadAction<boolean>) => {
      state.bookingLoading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setTmc: (state, action: PayloadAction<boolean>) => {
      state.tmc = action.payload;
    },
    // google login
    doGoogleLogin: (
      state,
      action: PayloadAction<{ data: any; callback: (data: any) => void }>
    ) => {},
    doGetProfile: (
      state,
      action: PayloadAction<{
        token: string;
        callback: (data: { role: number; id: string }) => void;
      }>
    ) => {},
    setUpdateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    setProfileData: (state, action: PayloadAction<IUserDataInterface>) => {
      state.userData = action.payload;
    },
    // profile update
    doProfileUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    doGetUserProfile: (state) => {},
    setUserProfileData: (
      state,
      action: PayloadAction<UserProfileInterface>
    ) => {
      state.form = action.payload;
    },

    //traveller section
    doAddTraveller: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    doUpdateTraveller: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    doGetByIdTraveller: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    doDeleteTraveller: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    updateTravellerFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `travellerForm.${action.payload.key}`, action.payload.value);
    },
    doGetTraveller: (state) => {},
    setTravellerData: (state, action: PayloadAction<TravellersInterface>) => {
      state.travellerForm = action.payload;
    },
    setTravelleList: (
      state,
      action: PayloadAction<Array<TravellersInterface>>
    ) => {
      state.travellerList = action.payload;
    },
    selectNewTravellerForBooking: (state, action: PayloadAction<any>) => {
      if (state.EditIndex > -1) {
        state.travellerList[state.EditIndex] = state.travellerForm;
        state.EditIndex = -1;
      } else {
        state.travellerList.push(state.travellerForm);
      }
      state.EditIndex = -1;
    },
    selectTravellerForBooking: (
      state,
      action: PayloadAction<{
        index: number;
        id: string;
        FirstName: string;
        LastName: string;
        PaxType: number;
        DateOfBirth: string;
        Gender: number;
        PassportNo: string;
        PassportExpiry: string;
        AddressLine1: string;
        AddressLine2: string;
        City: string;
        CountryCode: string;
        CellCountryCode: string;
        ContactNo: string;
        Nationality: string;
        Email: string;
        IsLeadPax: true;
        FFAirlineCode: null;
        FFNumber: string;
        GSTCompanyAddress: string;
        GSTCompanyContactNumber: string;
        GSTCompanyName: string;
        GSTNumber: string;
        GSTCompanyEmail: string;
        honorifics: string;
        fullName: string;
        type: number;
        _id: string;
        checked: boolean;
        Title: string;
      }>
    ) => {
      state.EditIndex = action.payload.index;
      state.travellerForm = state.travellerList[action.payload.index];
      state.travellerList[action.payload.index].checked =
        action.payload.checked;
    },

    clearTravellerForm: (state) => {
      state.travellerForm.Email = "";
      state.travellerForm.FirstName = "";
      state.travellerForm.LastName = "";
      state.travellerForm.DateOfBirth = "";
      state.travellerForm.PassportNo = "";
      state.travellerForm.PassportExpiry = "";
      state.travellerForm.CountryCode = "";
      state.travellerForm.CellCountryCode = "+91";
      state.travellerForm.ContactNo = "";
      state.travellerForm.type = 1;
      state.travellerForm.checked = false;
      state.travellerForm.Gender = GenderEnum.MALE;
      state.travellerForm._id = "";
      state.travellerForm.fullName = "";
      state.travellerForm.isNoValid = false;
      state.EditIndex = -1;
      state.travellerForm.honorifics = "Mr.";
      state.travellerForm.type = 0;
      state.travellerForm._id = "";
      state.travellerForm.Title = PassengerTitleEnum.MR;
      state.travellerForm.PaxType = PassengerPaxTypeEnum.Adult;
      state.travellerForm.AddressLine1 = "";
      state.travellerForm.AddressLine2 = "";
      state.travellerForm.City = "";
      state.travellerForm.Nationality = "";
      state.travellerForm.IsLeadPax = true;
      state.travellerForm.FFAirlineCode = null;
      state.travellerForm.FFNumber = "";
      state.travellerForm.GSTCompanyAddress = "";
      state.travellerForm.GSTCompanyContactNumber = "";
      state.travellerForm.GSTCompanyName = "";
      state.travellerForm.GSTNumber = "";
      state.travellerForm.GSTCompanyEmail = "";
      state.travellerForm.PassportIssueDate = "";
    },
    doGetCountryList: (state) => {},
    setCountryList: (state, action: PayloadAction<any>) => {
      state.countryList = action.payload;
    },

    //user Booking List
    doGetBookingList: (
      state,
      action: PayloadAction<{
        // status: string;
        callback: () => void;
      }>
    ) => {},
    setBookingList: (state, action: PayloadAction<[]>) => {
      state.bookingList = action.payload;
    },
    setStatus: (state, action: PayloadAction<any>) => {
      state.status = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setTotalPage: (state, action: PayloadAction<number>) => {
      state.totalPage = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    // cancel booking
    updateBookingCancelFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `bookingCancel.${action.payload.key}`, action.payload.value);
    },
    clearCancelBookingForm: (state) => {
      state.bookingCancel.Remarks = RemarksTypeEnum["My Plan Changed"];
      state.bookingCancel.TicketIds = [];
      state.bookingCancel.bookingId = "";
    },
    doCancelBooking: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    toggleShowUseDetailsModal: (state, action: PayloadAction<boolean>) => {
      state.showUseDetailsModal = action.payload;
    },
    toggleSubmitCancelPolicyModal: (state) => {
      state.submitCancelPolicyModal = !state.submitCancelPolicyModal;
    },
    doGetCancelPassengerList: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) => {},
    setCancelPassengerList: (
      state,
      action: PayloadAction<Array<CancelPassengerListInterface>>
    ) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = { ...action.payload[index], isSelected: false };
        state.cancelPassengerList.push(element);
      }
    },

    SetCancelPassengerListFormValue: (
      state,
      action: PayloadAction<{ index: any; isSelected: boolean }>
    ) => {
      state.cancelPassengerList[action.payload.index].isSelected = action
        .payload.isSelected
        ? false
        : true;
    },
    SetCancelPassengerListFormValueForAll: (
      state,
      action: PayloadAction<{ index: any; isSelected: boolean }>
    ) => {
      state.cancelPassengerList[action.payload.index].isSelected =
        action.payload.isSelected;
    },
    updateSetCancelPassengerListFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `setCancelPassengerList.${action.payload.key}`,
        action.payload.value
      );
    },
    doGetCancelCharges: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    setCancelPassengerTotalBreakdown: (
      state,
      action: PayloadAction<CancelPassengerTotalBreakdownInterface>
    ) => {
      state.CancelPassengerTotalBreakdown = action.payload;
    },
    clearCancelPassengerTotalBreakdownForm: (state) => {
      state.CancelPassengerTotalBreakdown.Fare.AirlineCancellationFee = 0;
      state.CancelPassengerTotalBreakdown.Fare.ConvenienceFeeAmount = 0;
      state.CancelPassengerTotalBreakdown.Fare.RefundableAmount = 0;
      state.CancelPassengerTotalBreakdown.Fare.TotalAmount = 0;
      state.CancelPassengerTotalBreakdown.Fare.TotalCancellationCharge = 0;
      state.CancelPassengerTotalBreakdown.Fare.TrivzyCancellationFee = 0;
      state.CancelPassengerTotalBreakdown.passenger = [];
    },
    clearCancelPassengerList: (state) => {
      state.cancelPassengerList = [];
    },
    clearSetCancelPassengerListForm: (state) => {
      state.setCancelPassengerList.bookingId = "";
      state.setCancelPassengerList.userId = [];
    },
    setCancelBooking: (state, action: PayloadAction<boolean>) => {
      state.cancelBooking = action.payload;
    },
    setCancelBookingTotalPassenger: (state, action: PayloadAction<number>) => {
      state.cancelBookingTotalPassenger = action.payload;
    },
    doGetStateList: (state) => {},
    setStateList: (state, action: PayloadAction<any>) => {
      state.stateList = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
