import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import ViewFlightInfoCard from "./viewFlightInfoCard";
import CurrencyFormat from "../../CurrencyFormat";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import BookButton from "../../BookButton";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as FlightSearchSliceKey,
  reducer as FlightSearchReducer,
} from "../../../Redux/flightSearch/slice";
import { FlightSearchRepoSaga } from "../../../Redux/flightSearch/saga";
import { createSearchParams, useNavigate } from "react-router-dom";
import RoundedButton from "../../Chip/RoundedButton";
import { path } from "../../../utils/Api";
import { CouponListInterface } from "../../../Redux/flightSearch/types";

type Props = {
  flightData: any;
  index: number;
  bookingId: string;
  travelClass: any;
  couponList: CouponListInterface[];
};

function FlightCard2({
  flightData,
  index,
  bookingId,
  travelClass,
  couponList,
}: Props) {
  const navigate = useNavigate();
  useInjectSaga({ key: FlightSearchSliceKey, saga: FlightSearchRepoSaga });
  useInjectReducer({ key: FlightSearchSliceKey, reducer: FlightSearchReducer });
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClick = () => {
    if (show == false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const inputString = flightData?.Duration;
  const resultList = inputString?.split(":");

  const dispatch = useDispatch();
  const handleSelectFlight = (ResultIndex: string, TraceId: string) => {
    setLoading(true);
    const params = {
      ResultIndex: ResultIndex,
      ReturnResultIndex: "",
      TraceId: TraceId,
    };
    navigate({
      pathname: "/booking",
      search: `?${createSearchParams(params)}`,
    });
  };
  const convertTimeString = (timeString: any) => {
    const [hours, minutes, seconds] = timeString?.split(":");
    const formattedTime = `${parseInt(hours, 10)}hr ${parseInt(minutes, 10)}m`;
    return formattedTime;
  };

  React.useEffect(() => {
    console.log("flightData?.Segments[index]", flightData?.Segments[index]);
    return () => {};
  }, []);

  return (
    <>
      <FlightCard
        key={index}
        className={`flightCardBorder ${show == true ? "hideBottomBorder" : ""}`}
      >
        <Grid container sx={{ pr: { xs: 0, md: 0 } }}>
          <Grid item xs={12}>
            <Grid container sx={{ mt: { md: 0.5 }, rowGap: { xs: 2, md: 0 } }}>
              <Grid item xs={12} sm={3} md={3}>
                <Box className="textAlign">
                  <img
                    src={
                      flightData?.AirlineLogo
                        ? path() + flightData?.AirlineLogo
                        : "/images/airplane.png"
                    }
                    alt="Logo"
                    style={{ width: "40px", height: "40px", marginRight: 18 }}
                  />
                  <Box>
                    <AirlineNameTypo>{flightData?.AirlineName}</AirlineNameTypo>
                    <CountryCodeTypo>
                      {" "}
                      {flightData?.AirlineCode}-{flightData?.FlightNumber}
                    </CountryCodeTypo>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} md={4.5}>
                <Box className="flightCardFlex">
                  <Box textAlign={"center"}>
                    <FlightTimeTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == 0 && (
                                <>
                                  {DateTime?.fromISO(
                                    details?.Origin?.DepTime
                                  )?.toFormat("HH:mm")}
                                </>
                              )}
                            </>
                          );
                        }
                      )}
                    </FlightTimeTypo>
                    <DestinationTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == 0 && (
                                <>{details.Origin.Airport.CityName}</>
                              )}
                            </>
                          );
                        }
                      )}
                    </DestinationTypo>
                  </Box>
                  <Box textAlign={"center"}>
                    <TimeTypo>
                      {convertTimeString(flightData?.Duration)}
                    </TimeTypo>
                    <AirlineNameTypo sx={{ color: "rgba(37, 37, 37, 0.7)" }}>
                      {flightData?.Segments.length == 1
                        ? "non-stop"
                        : flightData?.Segments.length == 2
                        ? "1 stop"
                        : flightData?.Segments.length == 2
                        ? "2+ stop"
                        : ""}
                    </AirlineNameTypo>
                  </Box>
                  <Box textAlign={"center"}>
                    <FlightTimeTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == flightData?.Segments.length - 1 && (
                                <>
                                  {DateTime?.fromISO(
                                    details?.Destination?.ArrTime
                                  )?.toFormat("HH:mm")}
                                </>
                              )}
                            </>
                          );
                        }
                      )}
                    </FlightTimeTypo>
                    <DestinationTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == flightData?.Segments.length - 1 && (
                                <>{details.Destination.Airport.CityName}</>
                              )}
                            </>
                          );
                        }
                      )}
                    </DestinationTypo>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4.5} className="flightCardFlex">
                <Box textAlign={"center"}>
                  <FlightTimeTypo>
                    <CurrencyFormat value={flightData?.PublishedFare} />
                  </FlightTimeTypo>
                  <DestinationTypo>Per Adult</DestinationTypo>
                </Box>
                <Box textAlign={"center"}>
                  <BookButton
                    loading={loading}
                    title="Book"
                    handleClick={() =>
                      handleSelectFlight(flightData?.ResultIndex, bookingId)
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={{ xs: 0, md: 2 }}>
            <Grid container sx={{ rowGap: 2 }}>
              <Grid item xs={12} md={4} lg={6} />
              <Grid
                item
                xs={12}
                md={5.33}
                lg={4.1}
                sx={{ float: "right" }}
                className="textAlign"
              >
                {index == 0 || index == 1 || index == 2 ? (
                  <RoundedButton
                    fontSize="12px"
                    onClick={undefined}
                    backgroundColor="rgba(255, 153, 0, 0.05)"
                    color="#FF9900"
                    title={"Seat Filing Fast"}
                  />
                ) : (
                  // <WarningButton>
                  //   <DownloadTicketButtonText sx={{ color: "#FF9900" }}>
                  //     Seat Filing Fast
                  //   </DownloadTicketButtonText>
                  // </WarningButton>
                  ""
                )}
                {/* {Number(couponList[index]?.couponDiscount) > 0 && (
                  <>
                    {index == 0 || index == 1 || index == 2 || index == 3 ? (
                      
                    ) : (
                      ""
                    )}
                  </>
                )} */}
                {couponList[index]?.description.length > 0 && (
                  <OfferButton>
                    <DownloadTicketButtonText sx={{ display: "flex" }}>
                      {couponList[index]?.description}
                    </DownloadTicketButtonText>
                  </OfferButton>
                )}
              </Grid>
              <Grid item xs={12} md={2.5} lg={"auto"} textAlign={"center"}>
                <DownloadTicketButtonText
                  sx={{ fontWeight: 600, color: "#DB383C", cursor: "pointer" }}
                  onClick={handleClick}
                >
                  {show == false ? "View Flight Info" : "Hide Flight Info"}
                </DownloadTicketButtonText>
                {(index == 0 || index == 1 || index == 2) && (
                  <DownloadTicketButtonText
                    sx={{ color: "rgba(0, 119, 26, 1)", mt: 1 }}
                  >
                    {flightData?.Segments[0]?.NoOfSeatAvailable}
                    {flightData?.Segments[0]?.NoOfSeatAvailable > 1
                      ? " Seats "
                      : " Seat "}
                    Available
                  </DownloadTicketButtonText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FlightCard>
      {show == true && (
        <>
          {flightData?.Segments.map((details: any, i: number) => {
            return (
              <ViewFlightInfoCard
                detailsData={details}
                index={i}
                travelClass={travelClass}
                stop={flightData?.Segments.length}
              />
            );
          })}
        </>
      )}
    </>
  );
}

export default FlightCard2;

const FlightCard = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: "24px 24px 16px 24px",
  // marginBottom: 16,
  marginTop: 8,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const AirlineNameTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 14,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const CountryCodeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.7)",
  fontSize: 12,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FlightTimeTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 4,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const DestinationTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 12,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const TimeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.6)",
  fontSize: 14,
  fontWeight: 600,
  paddingBottom: 4,
  marginBottom: 4,
  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const WarningButton = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(255, 153, 0, 1)",
  backgroundColor: "rgba(255, 153, 0, 0.05)",
  borderRadius: 35,
  padding: "6px 12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginRight: 10,
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginRight: 14,
  },
}));
const OfferButton = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(0, 119, 26, 1)",
  backgroundColor: "rgba(0, 119, 26, 0.05)",
  borderRadius: 35,
  padding: "6px 10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const DownloadTicketButtonText = styled(Box)(({ theme }) => ({
  color: "rgba(0, 119, 26, 1)",
  fontSize: 12,
  fontWeight: 700,
}));
