import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import { TextField, styled } from "@mui/material";

interface Props {
  value: string | null;
  minDate: any;
  name: string;
  onChange: any;
  disabled: any;
  disablePast: any;
  disableFuture: any;
  className?: string;
  sx?: any;
  placeholder?: string;
}

function calendarIcon() {
  return <img src="/images/calendar.svg" alt="calendar" />;
}

export default function NewDatePicker(props: Props) {
  const today = new Date();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <NewDatePicker2
        slots={{ openPickerIcon: calendarIcon }}
        slotProps={{
          inputAdornment: {
            position: "start",
          },
          layout: {
            sx: {
              "& .MuiButtonBase-root.Mui-selected": {
                backgroundColor: "#DE3151 !important",
              },
              "& .MuiButtonBase-root:hover": {
                backgroundColor: "#DE3151 !important",
                color: "#fff",
              },
              "& .MuiPickersYear-yearButton.Mui-selected": {
                backgroundColor: "#DE3151 !important",
              },
              "& .MuiPickersYear-yearButton:hover": {
                backgroundColor: "#DE3151 !important",
                color: "#fff",
              },
            },
          },
          textField: { placeholder: props?.placeholder },
        }}
        disablePast={props.disablePast}
        disableFuture={props.disableFuture}
        minDate={props.minDate ? dayjs(props.minDate) : dayjs("1900-12-15")}
        disabled={props.disabled}
        value={
          props?.value
            ? dayjs(props?.value)
            : props?.minDate
            ? dayjs(props?.minDate)
            : null
        }
        format="ddd, MMM D"
        onChange={props.onChange}
        sx={props?.sx}
      />
    </LocalizationProvider>
  );
}

const NewDatePicker2 = styled(DatePicker)({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "0px !important",
  },
  "& .MuiInputBase-root-MuiOutlinedInput-root": {
    paddingLeft: "0px !important",
  },
  "& .MuiInputBase-input": {
    fontWeight: "400 !important",
    fontSize: "12px !important",
    padding: "12.5px 0px 12.5px 0px !important",
  },
  "& .MuiOutlinedInput-root": {
    padding: "0px 14px 0px 13px !important",
  },
});
