import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import { useStyles } from "./style";

interface TableRadioButtonComponentProps {
  onClick: any;
  checked: boolean;
}

function Index(props: TableRadioButtonComponentProps) {
  const style = useStyles();

  return (
    <>
      <FormControlLabel
        sx={{
          fontWeight: 600,
          margin: "0px 4px 0px 0px",
        }}
        className={`${style.muiRadio}`}
        control={
          <Checkbox
            color="error"
            className={`${style.muiRadio}`}
            checked={props.checked ? (props.checked ? true : false) : false}
            defaultChecked={false}
          />
        }
        onClick={props.onClick}
        label=""
        labelPlacement="bottom"
      />
    </>
  );
}

export default Index;
