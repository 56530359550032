import React from "react";
import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import BookButton from "../../BookButton";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import CurrencyFormat from "../../CurrencyFormat";
import { selectSelectedFlightCard } from "../../../Redux/Booking/selector";
import { createSearchParams, useNavigate } from "react-router-dom";
import { path } from "../../../utils/Api";
function SelectedFlightCard({ bookingId }: any) {
  const data = useSelector(selectSelectedFlightCard);

  const convertTimeString = (timeString: any) => {
    const [hours, minutes, seconds] = timeString?.split(":");
    const formattedTime = `${parseInt(hours, 10)}hr ${parseInt(minutes, 10)}m`;
    return formattedTime;
  };
  const navigate = useNavigate();
  const params = {
    ResultIndex: data.resultIndex,
    ReturnResultIndex: data.returnResultIndex,
    TraceId: bookingId,
  };
  const handleSelectFlight = () => {
    navigate({
      pathname: "/booking",
      search: `?${createSearchParams(params)}`,
    });
  };
  React.useEffect(() => {
    console.log(data, "selected_data");

    return () => {};
  }, [data]);

  return (
    <Grid container spacing={0} paddingLeft={1.2}>
      <Grid item xs={4}>
        <FlightCard className={`flightSelectedCardBorder`}>
          <Grid container spacing={0} paddingRight={1} paddingLeft={1}>
            <Grid item xs={12} sm={3} md={3}>
              <Box className="textAlign">
                <img
                  src={
                    data?.AirlineLogo
                      ? path() + data?.AirlineLogo
                      : "/images/airplane.png"
                  }
                  alt="Logo"
                  style={{ width: "30px", height: "30px", marginRight: 18 }}
                />
              </Box>
              <AirlineNameTypo>{data.airlineName}</AirlineNameTypo>
            </Grid>
            <Grid item xs={12} sm={5} md={9} paddingRight={1} paddingLeft={1}>
              <Box className="flightCardFlex">
                <Box textAlign={"center"}>
                  <FlightTimeTypo>
                    {DateTime?.fromISO(data.depTime)?.toFormat("HH:mm")}
                  </FlightTimeTypo>
                  <DestinationTypo>{data.sourceCityName}</DestinationTypo>
                </Box>
                <Box textAlign={"center"} paddingRight={1} paddingLeft={1}>
                  <TimeTypo>
                    {data?.duration && convertTimeString(data?.duration)}
                  </TimeTypo>
                  <AirlineNameTypo sx={{ color: "rgba(37, 37, 37, 0.7)" }}>
                    {Number(data?.stop) == 1
                      ? "non-stop"
                      : Number(data?.stop) == 2
                      ? "1 stop"
                      : Number(data?.stop) > 2
                      ? "2+ stop"
                      : ""}
                  </AirlineNameTypo>
                </Box>
                <Box textAlign={"center"}>
                  <FlightTimeTypo>
                    {DateTime?.fromISO(data.arrTime)?.toFormat("HH:mm")}
                  </FlightTimeTypo>
                  <DestinationTypo>{data.destinationCityName}</DestinationTypo>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </FlightCard>
      </Grid>
      <Grid item xs={4}>
        <FlightCard className={`flightSelectedCardBorder`}>
          <Grid container spacing={0} paddingRight={1} paddingLeft={1}>
            <Grid item xs={12} sm={3} md={3}>
              <Box className="textAlign">
                <img
                  src={
                    data?.AirlineLogo
                      ? path() + data?.AirlineLogo
                      : "/images/airplane.png"
                  }
                  alt="Logo"
                  style={{ width: "30px", height: "30px", marginRight: 18 }}
                />
              </Box>
              <AirlineNameTypo>{data.returnAirlineName}</AirlineNameTypo>
            </Grid>
            <Grid item xs={12} sm={5} md={9} paddingRight={1} paddingLeft={1}>
              <Box className="flightCardFlex">
                <Box textAlign={"center"}>
                  <FlightTimeTypo>
                    {DateTime?.fromISO(data.returnDepTime)?.toFormat("HH:mm")}
                  </FlightTimeTypo>
                  <DestinationTypo>{data.returnSourceCityName}</DestinationTypo>
                </Box>
                <Box textAlign={"center"} paddingRight={1} paddingLeft={1}>
                  <TimeTypo>
                    {data?.returnDuration &&
                      convertTimeString(data?.returnDuration)}
                  </TimeTypo>
                  <AirlineNameTypo sx={{ color: "rgba(37, 37, 37, 0.7)" }}>
                    {Number(data?.returnStop) == 1
                      ? "non-stop"
                      : Number(data?.returnStop) == 2
                      ? "1 stop"
                      : Number(data?.returnStop) > 2
                      ? "2+ stop"
                      : ""}
                  </AirlineNameTypo>
                </Box>
                <Box textAlign={"center"}>
                  <FlightTimeTypo>
                    {DateTime?.fromISO(data.returnArrTime)?.toFormat("HH:mm")}
                  </FlightTimeTypo>
                  <DestinationTypo>
                    {data.returnDestinationCityName}
                  </DestinationTypo>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </FlightCard>
      </Grid>
      <Grid item xs={4}>
        <FlightCard className={`flightSelectedCardBorder`}>
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "end",
            }}
          >
            <Box
              className="textAlign"
              sx={{
                columnGap: 2,
              }}
            >
              <FlightPriceTypo>
                <CurrencyFormat value={data.price + data.returnPrice} />
              </FlightPriceTypo>
              <BookButton
                loading={false}
                title="Book"
                handleClick={handleSelectFlight}
              />
            </Box>
          </Box>
        </FlightCard>
      </Grid>
    </Grid>
  );
}

export default SelectedFlightCard;
const FlightCard = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: "24px 24px 16px 24px",
  // marginBottom: 16,
  height: "100%",
  minHeight: "100%",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const AirlineNameTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 10,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const AirlineGreen = styled(Typography)(({ theme }) => ({
  color: "#51b12e",
  fontSize: 13,
  fontWeight: 500,
}));
const FlightTimeTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const DestinationTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 12,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 9,
  },
}));
const TimeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.6)",
  fontSize: 12,
  fontWeight: 600,
  paddingBottom: 4,
  marginBottom: 4,
  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 9,
  },
}));
const FlightPriceTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 17,
  fontWeight: 700,
  marginBottom: 1,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 13,
  },
}));
