import {
  TravelClassEnum,
  TripTypesEnum,
} from "../../../Redux/flightSearch/types";

export interface ContactInterface {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
}
export interface RazorePayInterface {
  callback_url: string;
  amount: number;
  currency: string;
  key: string;
  paymentId: string;
  status: boolean;
}
export interface PassengersInterface {
  honorifics: string;
  Email: string;
  fullName: string;
  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  PassportNo: string;
  PassportExpiry: string;
  CountryCode: string;
  CellCountryCode: string;
  ContactNo: string;
  type: number;
  Gender: GenderEnum;
  _id: string;
  Title: PassengerTitleEnum;
  PaxType: PassengerPaxTypeEnum;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  Nationality: string;
  IsLeadPax: true;
  FFAirlineCode: null;
  FFNumber: string;
  GSTCompanyAddress: string;
  GSTCompanyContactNumber: string;
  GSTCompanyName: string;
  GSTNumber: string;
  GSTCompanyEmail: string;
  PassportIssueDate?: string;
}

export enum PassengerTitleEnum {
  MR = "Mr",
  MRS = "Mrs",
  MS = "Ms",
  MSTR = "Mstr",
}

export enum PassengerPaxTypeEnum {
  Adult = 1,
  Child = 2,
  Infant = 3,
}
export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
  OTHER = 3,
}
export enum UserMaritalStatusEnum {
  MARRIED = 1,
  UNMARRIED = 2,
}
export interface BookingPaymentInterface {
  bookingId: string;
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  Passengers: PassengersInterface[];
  PassengersForm: PassengersInterface;
}
export interface SelectedBookingInterface {
  resultIndex: string;
  airlineName: string;
  AirlineLogo: string;
  airlineCode: string;
  flightNumber: string;
  depTime: string;
  sourceCityName: string;
  duration: string;
  stop: string;
  arrTime: string;
  destinationCityName: string;
  price: number;
  returnResultIndex: string;
  returnAirlineName: string;
  returnAirlineLogo: string;
  returnAirlineCode: string;
  returnFlightNumber: string;
  returnDepTime: string;
  returnSourceCityName: string;
  returnDuration: string;
  returnStop: string;
  returnArrTime: string;
  returnDestinationCityName: string;
  returnPrice: number;
}

export interface FlightReturnDataInterface {
  journeyDate: string; // date "2023-12-15T08:00:00",
  journeyArrivalDate: string;
  returnArrivalDate: string;
  returnDate: string;
  travelClass: TravelClassEnum;
  destination: string;
  source: string;
  tripType: TripTypesEnum;
  airportSearch: string;
}
export interface selectedFlightDataFareInterface {
  Currency: string;
  BaseFare: number;
  Tax: number;
  OfferedFare: number;
  actualPublishedFare: number;
  markupTotal: number;
  ConvenienceFee: number;
  OtherCharges: number;
  TotalFare: number;
}
export interface selectedFlightDataFareBreakdownInterface {
  BaseFare: number;
  PerPaxFare: number;
  PassengerCount: number;
  PassengerType: number;
  Currency: string;
}
export interface selectedFlightDataSegmentsInterface {
  Baggage: string;
  CabinBaggage: string;
  CabinClass: number;
  SupplierFareClass: number;
  TripIndicator: number;
  SegmentIndicator: number;
  Airline: {
    AirlineCode: string;
    AirlineLogo: string;
    AirlineName: string;
    FlightNumber: string;
    FareClass: string;
    OperatingCarrier: string;
  };
  Origin: {
    Airport: {
      AirportCode: string;
      AirportName: string;
      Terminal: string;
      CityCode: string;
      CityName: string;
      CountryCode: string;
      CountryName: string;
    };
    DepTime: string;
  };
  Destination: {
    Airport: {
      AirportCode: string;
      AirportName: string;
      Terminal: string;
      CityCode: string;
      CityName: string;
      CountryCode: string;
      CountryName: string;
    };
    ArrTime: string;
  };
  Duration: number;
  GroundTime: number;
  Mile: number;
  StopOver: boolean;
  FlightInfoIndex: string;
  StopPoint: string;
  StopPointArrivalTime: string;
  StopPointDepartureTime: string;
  Craft: string;
  Remark: number;
  IsETicketEligible: boolean;
  FlightStatus: string;
  Status: string;
  FareClassification: {
    Type: string;
  };
}
export interface selectedFlightDataInterface {
  ResultIndex: string;
  IsRefundable: boolean;
  IsPanRequiredAtBook: boolean;
  IsPanRequiredAtTicket: boolean;
  IsPassportRequiredAtBook: boolean;
  IsPassportRequiredAtTicket: boolean;
  IsPassportFullDetailRequiredAtBook: boolean;
  Fare: selectedFlightDataFareInterface;
  FareBreakdown: selectedFlightDataFareBreakdownInterface[];
  Segments: selectedFlightDataSegmentsInterface[];
  AirlineCode: string;
}
export interface requestDetailInterface {
  adults: number;
  children: number;
  infants: number;
  destination: string;
  flightType: number;
  journeyDate: string;
  returnDate: string;
  source: string;
  travelClass: number;
  tripType: number;
  arrivalFilter: number;
  stopsFilter: number;
  priceFilter: number;
}
export interface ICoupon {
  bookingId: string;
  couponCode: string;
}
export interface IDiscount {
  Discount: number;
  TotalFare: number;
  couponCode: string;
}
export interface BookingState {
  couponForm: ICoupon;
  couponDiscount: IDiscount;
  form: ContactInterface;
  loading: boolean;
  search: string;
  flightBookingId: string;
  bookingTimer: string;
  selectedFlightData: selectedFlightDataInterface;
  selectedFlightReturnData: selectedFlightDataInterface;
  BookingPaymentForm: BookingPaymentInterface;
  RazorePayForm: RazorePayInterface;
  saveTraveler: boolean;
  status: number;
  selectedFlightCard: SelectedBookingInterface;
  requestDetail: requestDetailInterface;
  timerModal: boolean;
}

export type InitialBookingState = BookingState;
