import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { AxiosResponse } from "axios";

import CatchBlockFunction from "../../Component/CatchError";
import {
  ProfileUpdate,
  GetCountryList,
  GetTMCData,
  UserContactUs,
  GetBlogData,
  GetBlogById,
  GetOffersRequest,
  GetFaqRequest,
  HomePageBanner,
} from "../../utils/request";
import { toast } from "react-toastify";

import { BlogInterface, ContactUsInterface } from "./types";
import {
  selectBlogDetails,
  selectForm,
  selectPageNo,
  selectPageSize,
} from "./selector";

export function* doUserContactUsRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const form: ContactUsInterface = yield select(selectForm);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(UserContactUs, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetTmcDataRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetTMCData);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setTmcList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetBlogRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const form: BlogInterface = yield select(selectBlogDetails);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetBlogData,
      `?pageNo=${pageNo}&pageSize=${1000}&category=${form.category}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setBlogList(response.data.data.results));
    yield put(actions.setTotalPage(response.data.data.meta.totalPages));
    yield put(actions.setTotalRow(response.data.data.meta.total));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetBlogByIdRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(GetBlogById, action.payload.id);
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setBlogDetails(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetOffersRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      GetOffersRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setOffersList(response.data.data.results));
    yield put(actions.setTotalPage(response.data.data.meta.totalPages));
    yield put(actions.setTotalRow(response.data.data.meta.total));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetFaqRequest() {
  yield delay(500);

  yield put(actions.setLoading(true));
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const response: AxiosResponse = yield call(
      GetFaqRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setFaqList(response.data.data.results));
    yield put(actions.setTotalPage(response.data.data.meta.totalPages));
    yield put(actions.setTotalRow(response.data.data.meta.total));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetHomeBannerRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(HomePageBanner);

    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setBannerData(response.data.data.images));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* HomeRepoSaga() {
  yield takeLatest(actions.doGetTmcData, doGetTmcDataRequest);

  yield takeLatest(actions.doSend, doUserContactUsRequest);
  yield takeLatest(actions.doGetBlogList, doGetBlogRequest);
  yield takeLatest(actions.doGetBlogById, doGetBlogByIdRequest);
  yield takeLatest(actions.doGetOffersList, doGetOffersRequest);
  yield takeLatest(actions.doGetFaqList, doGetFaqRequest);
  yield takeLatest(actions.doGetHomeBanner, doGetHomeBannerRequest);
}
