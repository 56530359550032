import React, { useEffect, useState } from "react";
import {
  SelectLoading,
  selectCancelBooking,
  selectCancelBookingTotalPassenger,
  selectCancelPassengerList,
  selectSetCancelPassengerList,
  selectShowUseDetailsModal,
} from "../../../Redux/Login/selector";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Fade,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import theme from "../../../Style/theme";
import BookButton from "../../BookButton";
import WhiteButton from "../../BookButton/whiteButton";
import { actions } from "../../../Redux/Login/slice";
import CheckButton from "../../CheckButton";
import { GenderEnum } from "../../../Redux/Login/types";
import InfoIcon from "@mui/icons-material/Info";
import SubmitCancelTicket from "./SubmitCancelTicket";
import { toast } from "react-toastify";
import CurrencyFormat from "../../CurrencyFormat";

function ShowUserDetails() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowUseDetailsModal);
  const listData = useSelector(selectCancelPassengerList);
  const loading = useSelector(SelectLoading);
  const form = useSelector(selectSetCancelPassengerList);
  const totalUser = useSelector(selectCancelBookingTotalPassenger);
  const loader = useSelector(selectCancelBooking);
  const handleClose = () => {
    dispatch(actions.clearCancelPassengerList());
    dispatch(actions.clearCancelBookingForm());
    dispatch(actions.clearCancelPassengerTotalBreakdownForm());
    dispatch(actions.clearSetCancelPassengerListForm());
    dispatch(actions.toggleShowUseDetailsModal(false));
  };
  const handleChange = (isSelected: any, index: number) => {
    dispatch(
      actions.SetCancelPassengerListFormValue({
        index: index,
        isSelected: isSelected,
      })
    );
  };
  const [selected, setSelected] = useState(false);
  const handleAllSelected = () => {
    if (selected == true) {
      setSelected(false);
      for (let index = 0; index < listData.length; index++) {
        dispatch(
          actions.SetCancelPassengerListFormValueForAll({
            index: index,
            isSelected: false,
          })
        );
      }
    } else {
      setSelected(true);
      for (let index = 0; index < listData.length; index++) {
        dispatch(
          actions.SetCancelPassengerListFormValueForAll({
            index: index,
            isSelected: true,
          })
        );
      }
    }
  };
  let selectedData = [];
  let arrayOfIds = [];
  useEffect(() => {
    if (totalUser == 1) {
      dispatch(
        actions.SetCancelPassengerListFormValueForAll({
          index: 0,
          isSelected: true,
        })
      );
    }

    return () => {};
  }, [totalUser]);

  const handleSubmit = () => {
    selectedData = listData.filter((x) => x.isSelected == true);
    // console.log(selectedData, "selectedData");
    arrayOfIds = selectedData.map((obj) => obj._id);
    // console.log(arrayOfIds, "selectedData -arrayOfIds");
    dispatch(
      actions.updateSetCancelPassengerListFormValue({
        key: "bookingId",
        value: listData[0].bookingId,
      })
    );
    if (arrayOfIds.length == 0) {
      toast.error("please select at least 1 passenger");
      return;
    }

    if (selected == true || totalUser == 1) {
      dispatch(
        actions.updateSetCancelPassengerListFormValue({
          key: "userId",
          value: [],
        })
      );
    } else {
      dispatch(
        actions.updateSetCancelPassengerListFormValue({
          key: "userId",
          value: arrayOfIds,
        })
      );
    }

    dispatch(
      actions.doGetCancelCharges({
        callback() {
          dispatch(actions.toggleShowUseDetailsModal(false));
          dispatch(actions.toggleSubmitCancelPolicyModal());
        },
      })
    );
  };
  const [open, setOpen] = useState<any>(null);
  return (
    <div>
      <Modal open={show}>
        <Box sx={style}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                sx={{
                  width: "40px !important",
                  height: "40px !important",
                  margin: "0px 10px",
                  color: "#DE3151",
                  textAlign: "center",
                }}
              />
            </Box>
          ) : (
            <Grid container rowGap={{ xs: 2, sm: 3 }}>
              <Grid item xs={12}>
                {totalUser > 1 && (
                  <SpaceBox1 sx={{ mb: 1 }}>
                    <FilterBox>
                      <CheckButton
                        checked={selected}
                        onClick={(e: any) => handleAllSelected()}
                      />
                      <PolicyTypo>All</PolicyTypo>
                    </FilterBox>
                  </SpaceBox1>
                )}
                <Grid container rowGap={1}>
                  {listData.map((data, index: number) => {
                    return (
                      <Grid xs={12} md={8} lg={7}>
                        <SpaceBox1>
                          <FilterBox>
                            <CheckButton
                              checked={data?.isSelected}
                              onClick={(e: any) =>
                                handleChange(data?.isSelected, index)
                              }
                            />
                            <PolicyTypo>
                              {data?.FirstName} {data?.LastName}{" "}
                              <>
                                (
                                {data?.Gender === GenderEnum.MALE
                                  ? "Male"
                                  : data?.Gender === GenderEnum.FEMALE
                                  ? "Female"
                                  : "Other"}
                                )
                              </>
                            </PolicyTypo>
                            <ClickAwayListener
                              mouseEvent="onMouseDown"
                              touchEvent="onTouchStart"
                              onClickAway={() => setOpen(null)}
                            >
                              <Tooltip
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      maxWidth: { xs: "90%", sm: "550px" },
                                      backgroundColor: "rgb(77,77,77)",
                                    },
                                  },
                                }}
                                open={open === index}
                                onClose={() => setOpen(null)}
                                disableHoverListener
                                disableTouchListener
                                TransitionProps={{ timeout: 600 }}
                                TransitionComponent={Fade}
                                title={
                                  <Box
                                    sx={{
                                      p: { sm: "8px 8px 8px 10px" },
                                      width: {xs: "100%", sm: 460, md: 480},
                                    }}
                                  >
                                    <Box>
                                      <TooltipHeading>
                                        Cancellation fee per person
                                      </TooltipHeading>
                                      <Grid container columnSpacing={3}>
                                        <Grid item xs={12} sm={6}>
                                          <Box>
                                            <CancellationHeadingTypo>
                                              Total Paid
                                            </CancellationHeadingTypo>
                                            <Box
                                              className="textAlign"
                                              sx={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <PolicyTypo1>
                                                Airline Fare
                                              </PolicyTypo1>
                                              <PolicyTypo1>
                                                <CurrencyFormat
                                                  value={
                                                    data?.Fare?.AirlineFare
                                                  }
                                                />
                                              </PolicyTypo1>
                                            </Box>
                                            <Box
                                              className="textAlign"
                                              sx={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <PolicyTypo1>
                                                Trivzy Convenience Fee :
                                              </PolicyTypo1>
                                              <PolicyTypo1>
                                                <CurrencyFormat
                                                  value={
                                                    data?.Fare
                                                      ?.ConvenienceFeeAmount
                                                  }
                                                />
                                              </PolicyTypo1>
                                            </Box>
                                          </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                          <Box>
                                            <CancellationHeadingTypo
                                              sx={{ mt: { xs: 2, sm: 0 } }}
                                            >
                                              Deductions
                                            </CancellationHeadingTypo>
                                            <PolicyTypo1>
                                              Cancellation & Service Charges
                                            </PolicyTypo1>
                                            <Box
                                              className="textAlign"
                                              sx={{
                                                mt: 1,
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <PolicyTypo1>
                                                Airline Cancellation Fare:
                                              </PolicyTypo1>
                                              <PolicyTypo1>
                                                <CurrencyFormat
                                                  value={
                                                    data?.Fare
                                                      ?.AirlineCancellationFee
                                                  }
                                                />
                                              </PolicyTypo1>
                                            </Box>
                                            <Box
                                              className="textAlign"
                                              sx={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <PolicyTypo1>
                                                Trivzy Cancellation Fee :
                                              </PolicyTypo1>
                                              <PolicyTypo1>
                                                <CurrencyFormat
                                                  value={
                                                    data?.Fare
                                                      ?.TrivzyCancellationFee
                                                  }
                                                />
                                              </PolicyTypo1>
                                            </Box>
                                            <PolicyTypo1 sx={{ mt: 1 }}>
                                              Non-Refundable Fee Component
                                            </PolicyTypo1>
                                            <Box
                                              className="textAlign"
                                              sx={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <PolicyTypo1>
                                                Trivzy Convenience Fee :
                                              </PolicyTypo1>
                                              <PolicyTypo1>
                                                <CurrencyFormat
                                                  value={
                                                    data?.Fare
                                                      ?.ConvenienceFeeAmount
                                                  }
                                                />
                                              </PolicyTypo1>
                                            </Box>

                                            <Box
                                              className="textAlign"
                                              sx={{
                                                mt: 2,
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <CancellationHeadingTypo
                                                sx={{ borderBottom: 0 }}
                                              >
                                                Refund Amount
                                              </CancellationHeadingTypo>
                                              <CancellationHeadingTypo
                                                sx={{ borderBottom: 0 }}
                                              >
                                                <CurrencyFormat
                                                  value={
                                                    data?.Fare?.RefundableAmount
                                                  }
                                                />
                                              </CancellationHeadingTypo>
                                            </Box>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <IconButton
                                  disableTouchRipple
                                  disableRipple
                                  onClick={() => setOpen(index)}
                                  sx={{
                                    padding: 0,
                                    mt: "2px",
                                  }}
                                >
                                  <InfoIcon
                                    sx={{
                                      color: "#d5d5d5",
                                      marginLeft: "10px",
                                      width: 14,
                                      height: 14,
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          </FilterBox>
                        </SpaceBox1>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className="textCenter"
                sx={{ columnGap: "28px" }}
              >
                <WhiteButton
                  handleClick={handleClose}
                  loading={false}
                  title="Cancel"
                />
                <BookButton
                  handleClick={handleSubmit}
                  loading={loader}
                  title="Submit"
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
      <SubmitCancelTicket />
    </div>
  );
}

export default ShowUserDetails;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
  padding: "35px 40px",
  borderRadius: 3,
  height: "auto",
  outline: 0,
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    padding: "20px 20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "70%",
    padding: "30px 30px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: "60%",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    width: "60%",
  },
};
const SpaceBox1 = styled(Box)(({ theme }) => ({
  // marginBottom: 20,
}));
const FilterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const NameTypo = styled(Typography)(({ theme }) => ({
  color: "#929292",
  fontSize: 14,
  fontWeight: 600,
}));
const TooltipHeading = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 16,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const CancellationHeadingTypo = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: 12,
  fontWeight: 600,
  borderBottom: "1px dashed #b3b3b3",
  paddingBottom: 2,
  paddingRight: 12,
  marginBottom: 14,
  width: "max-content",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    width: "auto",
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const PolicyTypo = styled(Typography)(({ theme }) => ({
  color: "#050505",
  fontSize: 14,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const PolicyTypo1 = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontSize: 14,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));

const DownloadTicketButtonText = styled(Typography)(({ theme }) => ({
  color: "rgba(232, 70, 0, 1)",
  fontSize: 12,
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
