import MainLayout from "../../Component/Layout/MainLayout";
import FlightCard2 from "../../Component/Listing/ListingFlightCard/flightCard2";
import {
  Box,
  Container,
  Grid,
  Skeleton,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Filters from "../../Component/Listing/Filters";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectAirportList,
  SelectButtonLoading,
  SelectCouponList,
  SelectFlightBookingId,
  SelectFlightSearchList,
  SelectFormUpdate,
  SelectReturnFlightSearchList,
  selectForm,
  selectGenForm,
} from "../../Redux/flightSearch/selector";
import { actions } from "../../Redux/flightSearch/slice";
import { actions as actionsSelect } from "../../Redux/Booking/slice";
import TwoWayFlight from "../../Component/Listing/ListingFlightCard/TwoWayFlight";
import { TripTypesEnum } from "../../Redux/flightSearch/types";
import SelectedFlightCard from "../../Component/Listing/ListingFlightCard/SelectedFlightCard";
import { createSearchParams, useNavigate } from "react-router-dom";
import Filter from "./Filter";
import SkeletonLoadingCard from "../../Component/Listing/ListingFlightCard/SkeletonLoadingCard";
import TwoWaySkeletonLoadingCard from "../../Component/Listing/ListingFlightCard/TwoWaySkeletonLoadingCard";
import theme from "../../Style/theme";

function FlightListing() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const adults = searchParams.get("adults");
  const children = searchParams.get("children");
  const infants = searchParams.get("infants");
  const destination = searchParams.get("destination");
  const destinationLocation = searchParams.get("destinationLocation");
  const flightType = searchParams.get("flightType");
  const journeyDate = searchParams.get("journeyDate");
  const returnDate = searchParams.get("returnDate");
  const source = searchParams.get("source");
  const sourceLocation = searchParams.get("sourceLocation");
  const travelClass = searchParams.get("travelClass");
  const tripType: any = searchParams.get("tripType");
  const arrivalFilter = searchParams.get("arrivalFilter");
  const stopsFilter = searchParams.get("stopsFilter");
  const priceFilter = searchParams.get("priceFilter");

  const flightList = useSelector(SelectFlightSearchList);
  const couponList = useSelector(SelectCouponList);
  const returnFlightList = useSelector(SelectReturnFlightSearchList);
  const bookingId = useSelector(SelectFlightBookingId);
  const loading = useSelector(SelectButtonLoading);
  const airportList = useSelector(SelectAirportList);
  const fromUpdate = useSelector(SelectFormUpdate);
  const form = useSelector(selectForm);
  const genForm = useSelector(selectGenForm);
  const dispatch = useDispatch();
  const mdd = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    dispatch(actions.setButtonLoading(true));
    dispatch(actions.setFromUpdate(false));
    dispatch(
      actions.updateGenFormValue({
        key: "children",
        value: parseFloat(children as string),
      })
    );
    dispatch(
      actions.updateGenFormValue({
        key: "infants",
        value: parseFloat(infants as string),
      })
    );
    dispatch(
      actions.updateGenFormValue({
        key: "adults",
        value: parseFloat(adults as string),
      })
    );
    dispatch(
      actions.updateFormValue({ key: "destination", value: destination })
    );
    dispatch(
      actions.updateFormValue({
        key: "destinationLocation",
        value: destinationLocation,
      })
    );
    dispatch(actions.updateFormValue({ key: "flightType", value: flightType }));
    dispatch(
      actions.updateFormValue({ key: "journeyDate", value: journeyDate })
    );
    dispatch(actions.updateFormValue({ key: "returnDate", value: returnDate }));
    dispatch(actions.updateFormValue({ key: "source", value: source }));
    dispatch(
      actions.updateFormValue({ key: "sourceLocation", value: sourceLocation })
    );
    dispatch(
      actions.updateFormValue({ key: "travelClass", value: travelClass })
    );
    dispatch(actions.updateFormValue({ key: "tripType", value: tripType }));
    dispatch(
      actions.updateFormValue({ key: "arrivalFilter", value: arrivalFilter })
    );
    dispatch(
      actions.updateFormValue({ key: "stopsFilter", value: stopsFilter })
    );
    dispatch(
      actions.updateFormValue({ key: "priceFilter", value: priceFilter })
    );
    setTimeout(() => {
      dispatch(actions.setFromUpdate(true));
    }, 500);

    return () => {};
  }, []);

  React.useEffect(() => {
    if (fromUpdate) {
      dispatch(actions.doGetAirportList(""));
      dispatch(actions.doGetCouponList());
      dispatch(
        actions.doSearchFlight({
          callback: () => {},
        })
      );
    }
    console.log("tripType", tripType);
    return () => {};
  }, [fromUpdate]);

  React.useEffect(() => {
    const handleBackNavigation = () => {
      // Your function to be called on back navigation
      console.log("User navigated back!");
      dispatch(actions.clearSearchFlightForm());
    };

    // Event listener for popstate event (back/forward navigation)
    window.addEventListener("popstate", handleBackNavigation);

    return () => {
      // Cleanup: remove event listener when component unmounts
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const params = {
    adults: String(genForm.adults),
    children: String(genForm.children),
    infants: String(genForm.infants),
    destination: form.destination,
    destinationLocation: form.destinationLocation,
    flightType: "1",
    journeyDate: form.journeyDate,
    returnDate:
      String(form.tripType) == String(TripTypesEnum.ROUNDTRIP)
        ? form.returnDate
        : "",
    source: form.source,
    sourceLocation: form.sourceLocation,
    travelClass: String(form.travelClass),
    tripType: String(form.tripType),
    arrivalFilter: String(null),
    stopsFilter: "1",
    priceFilter: "1",
  };
  const handleSearch = () => {
    navigate({
      pathname: "/flight-listing",
      search: `?${createSearchParams(params)}`,
    });
    dispatch(actions.setButtonLoading(true));
    setTimeout(() => {
      dispatch(
        actions.doSearchFlight({
          callback: () => {},
        })
      );
    }, 500);
  };
  const handleSelectReturn = (data: any, index: number) => {
    dispatch(
      actions.setReturnFlightSelected({
        index: index,
      })
    );

    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnResultIndex",
        value: data.ResultIndex,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnAirlineName",
        value: data.AirlineName,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnAirlineLogo",
        value: "",
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnAirlineCode",
        value: data.AirlineCode,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnFlightNumber",
        value: data.FlightNumber,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnDepTime",
        value: data.DepTime,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnSourceCityName",
        value: data.SourceCity,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnDuration",
        value: data.Duration,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnStop",
        value: data.Segments.length,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnArrTime",
        value: data.ArrTime,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnDestinationCityName",
        value: data.DestinationCity,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnPrice",
        value: data.PublishedFare,
      })
    );
  };
  const handleSelectDepartureArrival = (data: any, index: number) => {
    dispatch(
      actions.setFlightSearchListSelected({
        index: index,
      })
    );

    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "resultIndex",
        value: data.ResultIndex,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "airlineName",
        value: data.AirlineName,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "airlineLogo",
        value: "",
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "airlineCode",
        value: data.AirlineCode,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "flightNumber",
        value: data.FlightNumber,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "depTime",
        value: data.DepTime,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "sourceCityName",
        value: data.SourceCity,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "duration",
        value: data.Duration,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "stop",
        value: data.Segments.length,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "arrTime",
        value: data.ArrTime,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "destinationCityName",
        value: data.DestinationCity,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "price",
        value: data.PublishedFare,
      })
    );
  };

  React.useEffect(() => {
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "stop",
        value: flightList[0]?.Segments?.length,
      })
    );
    dispatch(
      actionsSelect.updateSelectedFlightCardFormValue({
        key: "returnStop",
        value: returnFlightList[0]?.Segments?.length,
      })
    );
    console.log("stop", flightList[0]?.Segments?.length);
    console.log("stop", returnFlightList[0]?.Segments?.length);

    return () => {};
  }, [flightList, returnFlightList]);

  return (
    <>
      <MainLayout maxWidth="1400px !important" hasFooter={false}>
        <Box
          sx={{
            borderBottom: "2px solid rgba(0, 0, 0, 0.05)",
            position: { xs: "static", md: "sticky" },
            top: 0,
            backgroundColor: "#fff",
            zIndex: 99,
          }}
        >
          <Container sx={{ maxWidth: "1400px !important" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  pt: 3,
                  pb: 2,
                }}
              >
                <Filters
                  airportData={airportList}
                  handleSearch={handleSearch}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box mb={4}>
          <Container sx={{ maxWidth: "1400px !important" }}>
            <Grid container columnSpacing={{ md: 4, lg: 8 }}>
              <Grid item xs={12} md={3}>
                {/* <HeadingTypo>Filters</HeadingTypo> */}
                <Filter />
              </Grid>

              {loading ? (
                <>
                  {parseFloat(tripType) == TripTypesEnum.ONE_WAY ? (
                    <Grid item xs={12} md={9}>
                      <HeadingTypo>Searching for Best flights</HeadingTypo>
                      {Array.from({ length: 6 }).map((data, index) => (
                        <SkeletonLoadingCard key={index} />
                      ))}
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={9}>
                      <HeadingTypo>Searching for Best flights</HeadingTypo>
                      <TwoWaySkeletonLoadingCard />
                      <Grid container columnSpacing={1}>
                        {Array.from({ length: mdd ? 6 : 12 }).map(
                          (data, index) => (
                            <Grid item xs={12} md={6} key={index}>
                              <SkeletonLoadingCard twoWay={true} />
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={12} md={9}>
                  {flightList.length > 0 ? (
                    <>
                      <HeadingTypo>
                        Best flights from{" "}
                        {flightList?.map((data: any, index: number) => {
                          return (
                            <>
                              {index == 0 && <>{data?.SourceCity}</>}

                              {index == 0 && <> to {data?.DestinationCity}</>}
                            </>
                          );
                        })}{" "}
                        ({flightList?.length} Flights Found )
                      </HeadingTypo>
                      {parseFloat(tripType) == TripTypesEnum.ONE_WAY && (
                        <Box sx={stickyHeader}>
                          <Grid container>
                            <Grid item xs={2} sm={3} md={3}>
                              <ColumnHeadingBox
                                sx={{
                                  borderRadius: "4px 0px 0px 4px",
                                }}
                              >
                                <ColumnHeadingTypo>Airline</ColumnHeadingTypo>
                              </ColumnHeadingBox>
                            </Grid>
                            <Grid item xs={8} sm={5} md={4.5}>
                              <ColumnHeadingBox sx={{ borderRadius: 0 }}>
                                <ColumnHeadingTypo>Departure</ColumnHeadingTypo>
                                <ColumnHeadingTypo>Duration</ColumnHeadingTypo>
                                <ColumnHeadingTypo>Arrival</ColumnHeadingTypo>
                              </ColumnHeadingBox>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4.5}>
                              <ColumnHeadingBox
                                sx={{
                                  borderRadius: "0px 4px 4px 0px",
                                }}
                              >
                                <ColumnHeadingTypo>Price</ColumnHeadingTypo>
                              </ColumnHeadingBox>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {parseFloat(tripType) == TripTypesEnum.ONE_WAY ? (
                        <>
                          {flightList?.map((data, index) => {
                            return (
                              <FlightCard2
                                couponList={couponList}
                                flightData={data}
                                index={index}
                                bookingId={bookingId}
                                travelClass={travelClass}
                              />
                            );
                          })}
                        </>
                      ) : parseFloat(tripType) == TripTypesEnum.ROUNDTRIP ? (
                        <>
                          <Grid container columnSpacing={1}>
                            <Grid item xs={12} sx={stickyHeader}>
                              <Grid container columnSpacing={1} rowGap={1}>
                                <SelectedFlightCard bookingId={bookingId} />
                                <Grid item xs={12} md={6}>
                                  <Box>
                                    <Grid container>
                                      <Grid item xs={2} sm={3} md={3}>
                                        <TwoWayColumnHeadingBox
                                          sx={{
                                            borderRadius: "4px 0px 0px 4px",
                                          }}
                                        >
                                          <ColumnHeadingTypo>
                                            Airline
                                          </ColumnHeadingTypo>
                                        </TwoWayColumnHeadingBox>
                                      </Grid>
                                      <Grid item xs={8} sm={5} md={5}>
                                        <TwoWayColumnHeadingBox
                                          sx={{
                                            borderRadius: 0,
                                            justifyContent: "start",
                                          }}
                                        >
                                          <ColumnHeadingTypo
                                            sx={{ mr: { xs: 2, sm: 4, md: 2 } }}
                                          >
                                            Departure
                                          </ColumnHeadingTypo>
                                          <ColumnHeadingTypo>
                                            Duration
                                          </ColumnHeadingTypo>
                                        </TwoWayColumnHeadingBox>
                                      </Grid>
                                      <Grid item xs={2} sm={4} md={4}>
                                        <TwoWayColumnHeadingBox
                                          sx={{
                                            borderRadius: "0px 4px 4px 0px",
                                          }}
                                        >
                                          <ColumnHeadingTypo>
                                            Price
                                          </ColumnHeadingTypo>
                                        </TwoWayColumnHeadingBox>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  sx={{ display: { xs: "none", md: "block" } }}
                                >
                                  <Box>
                                    <Grid container>
                                      <Grid item xs={2} sm={3} md={3}>
                                        <TwoWayColumnHeadingBox
                                          sx={{
                                            borderRadius: "4px 0px 0px 4px",
                                          }}
                                        >
                                          <ColumnHeadingTypo>
                                            Airline
                                          </ColumnHeadingTypo>
                                        </TwoWayColumnHeadingBox>
                                      </Grid>
                                      <Grid item xs={8} sm={5} md={5}>
                                        <TwoWayColumnHeadingBox
                                          sx={{
                                            borderRadius: 0,
                                            justifyContent: "start",
                                          }}
                                        >
                                          <ColumnHeadingTypo
                                            sx={{ mr: { xs: 2, sm: 4, md: 2 } }}
                                          >
                                            Departure
                                          </ColumnHeadingTypo>
                                          <ColumnHeadingTypo>
                                            Duration
                                          </ColumnHeadingTypo>
                                        </TwoWayColumnHeadingBox>
                                      </Grid>
                                      <Grid item xs={2} sm={4} md={4}>
                                        <TwoWayColumnHeadingBox
                                          sx={{
                                            borderRadius: "0px 4px 4px 0px",
                                          }}
                                        >
                                          <ColumnHeadingTypo>
                                            Price
                                          </ColumnHeadingTypo>
                                        </TwoWayColumnHeadingBox>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {flightList?.map((data, index) => {
                                return (
                                  <TwoWayFlight
                                    flightData={data}
                                    couponList={couponList}
                                    index={index}
                                    bookingId={bookingId}
                                    travelClass={travelClass}
                                    onselect={handleSelectDepartureArrival}
                                  />
                                );
                              })}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {returnFlightList?.map((data, index) => {
                                return (
                                  <TwoWayFlight
                                    flightData={data}
                                    couponList={couponList}
                                    index={index}
                                    bookingId={bookingId}
                                    travelClass={travelClass}
                                    onselect={handleSelectReturn}
                                  />
                                );
                              })}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        my: 15,
                      }}
                    >
                      <Typography sx={{ fontSize: 16 }}>
                        Flight data Not Found
                      </Typography>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      </MainLayout>
    </>
  );
}

export default FlightListing;

const HeadingTypo = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: 18,
  fontWeight: 600,
  marginBottom: 30,
  marginTop: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    marginBottom: 20,
    marginTop: 30,
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginBottom: 30,
  },
}));
const FilterBox = styled(Box)(({ theme }) => ({
  boxShadow: "0px 4px 30px 0px #D2D2D25E",
  height: 600,
  borderRadius: 15,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const ColumnHeadingBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 4,
  backgroundColor: "#f7f7f7",
  padding: "8px 48px",
  border: "1px solid #f7f7f7",
  [theme.breakpoints.down("sm")]: {
    padding: "8px 16px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "8px 28px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    padding: "8px 28px",
  },
}));
const TwoWayColumnHeadingBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 4,
  backgroundColor: "#f7f7f7",
  padding: "8px 16px",
  border: "1px solid #f7f7f7",
  [theme.breakpoints.down("sm")]: {
    padding: "8px 16px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "8px 28px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    padding: "8px 28px",
  },
}));
const ColumnHeadingTypo = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: "#4d4d4d",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const stickyHeader = {
  position: "sticky" as "sticky",
  top: 85,
  zIndex: 99,
  padding: "8px 0px",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    position: "static",
  },
  [theme.breakpoints.between("sm", "md")]: {
    position: "static",
  },
  [theme.breakpoints.between("md", "lg")]: {
    top: 142,
  },
};
