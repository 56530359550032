import { RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";

interface Props {
  value: any;
  name: string;
  forChecked: any;
  className: any;
  handleFieldChange: any;
  label: string;
}

const Index = (props: Props) => {
  const style = useStyles();
  return (
    <div>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        className={`${style.muiRadio} `}
      >
        <FormControlLabel
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 17,
            },
            fontSize: 10,
          }}
          className={`${style.muiRadio}`}
          value={props?.value}
          control={
            <Radio
              disableRipple
              sx={{
                color: "#DB383C",
                "&.Mui-checked": {
                  color: "#DB383C",
                },
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: { xs: 14, md: 12, lg: 14 } }}>
              {props?.label}
            </Typography>
          }
          name={props?.name}
          checked={props?.forChecked == props?.value}
          onChange={(e: any) => props.handleFieldChange(e)}
        />
      </RadioGroup>
    </div>
  );
};

export default Index;
