import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import CurrencyFormat from "../../CurrencyFormat";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import TwoWayFlightInfoCard from "./twoWayFlightInfoCard";
import RadioButton from "../../RadioButton";
import { path } from "../../../utils/Api";

function FlightCard2({
  flightData,
  index,
  bookingId,
  travelClass,
  onselect,
  couponList,
}: any) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => {
    if (show == false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const inputString = flightData?.Duration;
  const resultList = inputString?.split(":");

  const dispatch = useDispatch();
  const convertTimeString = (timeString: any) => {
    const [hours, minutes, seconds] = timeString?.split(":");
    const formattedTime = `${parseInt(hours, 10)}hr ${parseInt(minutes, 10)}m`;
    return formattedTime;
  };

  const handleSelectFlight = (data: any, index: any) => {
    onselect(data, index);
  };

  return (
    <>
      <FlightCard
        key={index}
        className={`flightCardBorder ${show == true ? "hideBottomBorder" : ""}`}
      >
        <Grid container sx={{ pr: { xs: 0, md: 0 } }} rowGap={2}>
          <Grid item xs={12}>
            <Grid container sx={{ mt: { md: 0.5 }, rowGap: { xs: 2, md: 0 } }}>
              <Grid item xs={12} sm={3} md={3}>
                <Box className="textAlign">
                  <img
                    src={
                      flightData?.AirlineLogo
                        ? path() + flightData?.AirlineLogo
                        : "/images/airplane.png"
                    }
                    alt="Logo"
                    style={{ width: "40px", height: "40px", marginRight: 18 }}
                  />
                  <Box>
                    <AirlineNameTypo>
                      {flightData.AirlineName?.length > 9
                        ? flightData.AirlineName.slice(0, 8) + "..."
                        : flightData.AirlineName}
                    </AirlineNameTypo>
                    <CountryCodeTypo>
                      {" "}
                      {flightData.AirlineCode}-{flightData.FlightNumber}
                    </CountryCodeTypo>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} md={5.5}>
                <Box className="flightCardFlex">
                  <Box textAlign={"center"}>
                    <FlightTimeTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == 0 && (
                                <>
                                  {DateTime?.fromISO(
                                    details?.Origin?.DepTime
                                  )?.toFormat("HH:mm")}
                                </>
                              )}
                            </>
                          );
                        }
                      )}
                    </FlightTimeTypo>
                    <DestinationTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == 0 && (
                                <>{details.Origin.Airport.CityName}</>
                              )}
                            </>
                          );
                        }
                      )}
                    </DestinationTypo>
                  </Box>
                  <Box textAlign={"center"}>
                    <TimeTypo>
                      {convertTimeString(flightData.Duration)}
                    </TimeTypo>
                    <AirlineNameTypo sx={{ color: "rgba(37, 37, 37, 0.7)" }}>
                      {flightData?.Segments.length} stop
                    </AirlineNameTypo>
                  </Box>
                  <Box textAlign={"center"}>
                    <FlightTimeTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == flightData?.Segments.length - 1 && (
                                <>
                                  {DateTime?.fromISO(
                                    details?.Destination?.ArrTime
                                  )?.toFormat("HH:mm")}
                                </>
                              )}
                            </>
                          );
                        }
                      )}
                    </FlightTimeTypo>
                    <DestinationTypo>
                      {flightData?.Segments.map(
                        (details: any, index: number) => {
                          return (
                            <>
                              {index == flightData?.Segments.length - 1 && (
                                <>{details.Destination.Airport.CityName}</>
                              )}
                            </>
                          );
                        }
                      )}
                    </DestinationTypo>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={3.5} className="flightCardFlex">
                <Box textAlign={"center"}>
                  <FlightTimeTypo>
                    <CurrencyFormat value={flightData?.PublishedFare} />
                  </FlightTimeTypo>
                  <DestinationTypo>Per Adult</DestinationTypo>
                </Box>
                <RadioButton
                  className=""
                  forChecked={flightData.selected}
                  handleFieldChange={() =>
                    handleSelectFlight(flightData, index)
                  }
                  name=""
                  value={index}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={{ rowGap: 2 }}>
              <Grid
                item
                xs={12}
                md={7}
                lg={6.8}
                sx={{ justifyContent: "space-between", float: "right" }}
                className="textAlign seatFilingFastBox"
              >
                {(index == 0 || index == 1 || index == 2) && (
                  <WarningButton>
                    <DownloadTicketButtonText sx={{ color: "#FF9900" }}>
                      Seat Filing Fast
                    </DownloadTicketButtonText>
                  </WarningButton>
                )}

                {Number(couponList[index]?.couponDiscount) > 0 && (
                  <>
                    {index == 0 || index == 1 || index == 2 || index == 3 ? (
                      <OfferButton>
                        <DownloadTicketButtonText sx={{ display: "flex" }}>
                          Get&nbsp;
                          <CurrencyFormat
                            value={Number(couponList[index]?.couponDiscount)}
                          />
                          &nbsp; Off
                        </DownloadTicketButtonText>
                      </OfferButton>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={5} className="textCenter">
                <DownloadTicketButtonText
                  sx={{ fontWeight: 600, color: "#DB383C", cursor: "pointer" }}
                  onClick={handleClick}
                >
                  {show == false ? "View Flight Info" : "Hide Flight Info"}
                </DownloadTicketButtonText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FlightCard>
      {show == true && (
        <>
          {flightData?.Segments.map((details: any, i: number) => {
            return (
              <TwoWayFlightInfoCard
                detailsData={details}
                index={i}
                travelClass={travelClass}
                stop={flightData?.Segments.length}
              />
            );
          })}
        </>
      )}
    </>
  );
}

export default FlightCard2;

const FlightCard = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: "24px 24px 16px 24px",
  marginTop: 8,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const AirlineNameTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 11,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const CountryCodeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.7)",
  fontSize: 11,
  fontWeight: 400,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FlightTimeTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 13,
  fontWeight: 700,
  marginBottom: 4,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 13,
  },
}));
const DestinationTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 11,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const TimeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.6)",
  fontSize: 11,
  fontWeight: 600,
  paddingBottom: 4,
  marginBottom: 4,
  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 11,
  },
}));
const WarningButton = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(255, 153, 0, 1)",
  backgroundColor: "rgba(255, 153, 0, 0.05)",
  borderRadius: 35,
  padding: "6px 12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginRight: 10,
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginRight: 14,
  },
}));
const OfferButton = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(0, 119, 26, 1)",
  backgroundColor: "rgba(0, 119, 26, 0.05)",
  borderRadius: 35,
  padding: "6px 10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const DownloadTicketButtonText = styled(Box)(({ theme }) => ({
  color: "rgba(0, 119, 26, 1)",
  fontSize: 12,
  fontWeight: 700,
}));
