import { Box, Typography, styled } from "@mui/material";
import React from "react";

interface Props {
  title: string;
  onClick: any;
  color: any;
  fontSize: string;
  backgroundColor: string;
  marginRight?: string;
}

function RoundedButton(props: Props) {
  return (
    <RoundedBox
      onClick={props.onClick}
      sx={{
        borderColor: props.color,
        background: props.backgroundColor,
        mr: props?.marginRight,
      }}
    >
      <ButtonText sx={{ color: props.color, fontSize: props.fontSize }}>
        {props.title}
      </ButtonText>
    </RoundedBox>
  );
}

export default RoundedButton;

const RoundedBox = styled(Box)(({ theme }) => ({
  border: "1px solid",
  cursor: "pointer",
  borderRadius: 35,
  padding: "6px 18px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  marginRight: 14,
  [theme.breakpoints.down("sm")]: {
    padding: "6px 14px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "6px 16px",
  },
}));
const ButtonText = styled(Typography)(({ theme }) => ({
  //   color: "#00771A",
  // fontSize: 12,
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
