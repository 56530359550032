import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { OutlinedInput, styled } from "@mui/material";
interface Props {
  name: string;
  value: any;
  onChange: any;
  data: any;
  disabled: any;
  placeholder: string;
}
const BootstrapInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 12,
    padding: "10.5px 14px 10.5px 14px",
  },
}));

export default function BasicSelect(props: Props) {
  return (
    <Box>
      <FormControl fullWidth>
        <Select
          size="small"
          disabled={props.disabled}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          input={<BootstrapInput />}
          displayEmpty
          renderValue={
            props?.value !== "" ? undefined : () => props.placeholder
          }
          className="fw-semibold"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 2,
            },
            " fieldset": {
              border: "1px solid rgba(0, 0, 0, 0.1) !important",
              outline: "none !important",
            },
          }}
        >
          {props.data?.map((item: any) => (
            <MenuItem
              key={item._id}
              value={item._id}
              className="selectorText fw-semibold fs-13"
            >
              {item.item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
