import { Box, Skeleton, Button } from "@mui/material";
import React from "react";

function TwoWaySkeletonLoadingCard() {
  return (
    <>
      <Box
        border="1px solid rgba(37, 37, 37, 0.2)"
        borderRadius={2}
        width={"100%"}
        mx="auto"
        mt={2}
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          width={"100%"}
          p={{
            xs: "24px 24px 16px 24px",
            sm: "24px 12px 16px 24px",
            md: "24px 24px 16px 24px",
          }}
          borderRight={{ sm: "1px solid rgba(0, 0, 0, 0.1)" }}
        >
          <Box>
            <Skeleton variant="rectangular" width={30} height={30} />
            <Skeleton width={40} />
          </Box>
          <Box>
            <Skeleton width={40} />
            <Skeleton width={40} />
          </Box>
          <Box>
            <Skeleton width={40} />
            <Skeleton width={40} />
          </Box>
          <Box>
            <Skeleton width={40} />
            <Skeleton width={40} />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          width={"100%"}
          p={{
            xs: "24px 24px 16px 24px",
            sm: "24px 12px 16px 12px",
            md: "24px 24px 16px 24px",
          }}
          borderRight={{ sm: "1px solid rgba(0, 0, 0, 0.1)" }}
        >
          <Box>
            <Skeleton variant="rectangular" width={30} height={30} />
            <Skeleton width={40} />
          </Box>
          <Box>
            <Skeleton width={40} />
            <Skeleton width={40} />
          </Box>
          <Box>
            <Skeleton width={40} />
            <Skeleton width={40} />
          </Box>
          <Box>
            <Skeleton width={40} />
            <Skeleton width={40} />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "space-between", sm: "right" }}
          p={{
            xs: "24px 24px 16px 24px",
            sm: "24px 24px 16px 12px",
            md: "24px 24px 16px 24px",
          }}
          width={{ xs: "100%", sm: "25%", md: "80%" }}
        >
          <Skeleton width={60} />
          <Button variant="contained" disabled sx={{ ml: 1 }}>
            <Skeleton width={60} />
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default TwoWaySkeletonLoadingCard;
