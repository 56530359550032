import { Chip, Stack } from "@mui/material";
import React from "react";
interface Props {
  variant: string;
}
function StatusChip(props: Props) {
  return (
    <Stack direction="row" spacing={1}>
      {props.variant == "upcoming" ? (
        <Chip
          label="Upcoming"
          size="small"
          sx={{
            bgcolor: "#4baa11",
            color: "#fff",
            borderRadius: "5px",
            fontSize: { xs: 11, md: 12 },
          }}
        />
      ) : props.variant == "payment-failed" ? (
        <Chip
          label="Payment Failed"
          size="small"
          sx={{
            bgcolor: "#aa3349",
            color: "#fff",
            borderRadius: "5px",
            fontSize: { xs: 11, md: 12 },
          }}
        />
      ) : props.variant == "completed" ? (
        <Chip
          label="Completed"
          size="small"
          sx={{
            bgcolor: "#a0a0a0",
            color: "#fff",
            borderRadius: "5px",
            fontSize: { xs: 11, md: 12 },
          }}
        />
      ) : props.variant == "refund" ? (
        <Chip
          label="Refunded"
          size="small"
          sx={{
            bgcolor: "yellow",
            color: "#fff",
            borderRadius: "5px",
            fontSize: { xs: 11, md: 12 },
          }}
        />
      ) : (
        ""
      )}
    </Stack>
  );
}

export default StatusChip;
