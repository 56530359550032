import React from "react";
import { Box, Skeleton, Button } from "@mui/material";

function SkeletonLoadingCard({ twoWay = false }: { twoWay?: boolean }) {
  return (
    <>
      <Box
        p={"24px 24px 16px 24px"}
        border="1px solid rgba(37, 37, 37, 0.2)"
        borderRadius={2}
        width={"100%"}
        mx="auto"
        mt={1}
      >
        <Box
          display="flex"
          alignItems={{ xs: "start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box display="flex" alignItems={{ xs: "start", sm: "center" }}>
            <Skeleton variant="rectangular" width={40} height={40} />
            <Box ml={2}>
              <Skeleton width={twoWay == true ? 40 : 100} />
              <Skeleton width={twoWay == true ? 40 : 60} />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems={{ xs: "start", sm: "center" }}
            justifyContent="space-between"
            width={"100%"}
            pl={
              twoWay == true
                ? { xs: 0, sm: "10%", md: "2%", lg: "10%" }
                : { xs: 0, sm: "10%" }
            }
            mt={{ xs: 2.5, sm: 0 }}
          >
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={50} />
              <Skeleton width={50} />
            </Box>
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={50} />
              <Skeleton width={50} />
            </Box>
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={50} />
              <Skeleton width={50} />
            </Box>
            <Box
              textAlign={{ xs: "start", sm: "center" }}
              display={{ xs: "none", sm: "block" }}
            >
              <Skeleton width={50} />
              <Skeleton width={50} />
            </Box>
            {twoWay == false && (
              <Box
                textAlign={{ xs: "start", sm: "center" }}
                display={{ xs: "none", sm: "block" }}
              >
                <Button variant="contained" disabled>
                  <Skeleton width={60} />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Box mt={twoWay == true ? 2 : { xs: 2.5, sm: 5 }}>
          <Box
            display={{ xs: "flex", sm: "none" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              textAlign={{ xs: "start", sm: "center" }}
              display={{ xs: "block", sm: "none" }}
            >
              <Skeleton width={50} />
              <Skeleton width={50} />
            </Box>
            {twoWay == false && (
              <Button variant="contained" disabled>
                <Skeleton width={60} />
              </Button>
            )}
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={
              twoWay == true ? "space-between" : { xs: "left", md: "right" }
            }
            mt={{ xs: 2, md: 0 }}
          >
            <Box display={"flex"} alignItems={"center"} mr={2}>
              <Box textAlign="center" mr={2}>
                <Skeleton width={60} />
              </Box>
              <Box textAlign="center">
                <Skeleton width={60} />
              </Box>
            </Box>
            <Box textAlign="center">
              <Skeleton width={60} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SkeletonLoadingCard;
