import { Box, Grid, Modal } from "@mui/material";
import React, { useEffect } from "react";
import BookButton from "../BookButton";
import WhiteButton from "../BookButton/whiteButton";
import TextInputField from "../TextInputField";
import PhoneInput from "../PhoneInput";
import { useDispatch, useSelector } from "react-redux";
import {
  sliceKey as HomeSliceKey,
  reducer as HomeReducer,
  actions,
} from "../../Redux/Home/slice";
import { HomeRepoSaga } from "../../Redux/Home/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  selectContactModal,
  selectForm,
  selectLoading,
} from "../../Redux/Home/selector";
import MultiRowInput from "../TextInputField/MultiRowInput";
import { isValidPhoneNumber } from "react-phone-number-input";
// import { log } from "../../utils/logger";
import { toast } from "react-toastify";
import theme from "../../Style/theme";
import { ContactUsTypeEnum } from "../../Redux/Home/types";

function Index({ type }: { type: ContactUsTypeEnum }) {
  useInjectReducer({ key: HomeSliceKey, reducer: HomeReducer });
  useInjectSaga({ key: HomeSliceKey, saga: HomeRepoSaga });
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const loading = useSelector(selectLoading);
  const open = useSelector(selectContactModal);
  const emailRegex = /^[a-zA-Z0-9.]+@[a-z]+\.[a-z]{2,3}$/;
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  useEffect(() => {
    if (type == ContactUsTypeEnum.CANCEL_BOOKING_QUERY) {
      dispatch(actions.updateFormValue({ key: "type", value: 2 }));
    } else {
      dispatch(actions.updateFormValue({ key: "type", value: 1 }));
    }

    return () => {};
  }, [type]);

  const handleNumberFieldChange = (value: string, name: string) => {
    console.log("value", value);
    let isValid;
    if (value) {
      isValid = isValidPhoneNumber(value);
    }

    dispatch(
      actions.updateFormValue({
        key: "isNoValid",
        value: isValid,
      })
    );
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  const handleClick = () => {
    if (form.email.length <= 0) {
      if (!emailRegex.test(form.email)) {
        toast.error("Please enter valid email");
        return;
      }
    }
    if (type == ContactUsTypeEnum.CANCEL_BOOKING_QUERY) {
      dispatch(actions.updateFormValue({ key: "type", value: 2 }));
    } else {
      dispatch(actions.updateFormValue({ key: "type", value: 1 }));
    }

    dispatch(
      actions.doSend({
        callback: () => {
          dispatch(actions.clearForm());
          dispatch(actions.toggleOpenContactModal());
        },
      })
    );
  };
  const cancel = () => {
    dispatch(actions.clearForm());
    dispatch(actions.toggleOpenContactModal());
  };
  return (
    <div>
      <Modal open={open}>
        <Box sx={style}>
          <Grid
            container
            spacing={{ xs: 0, sm: 3.5 }}
            rowGap={{ xs: 2, sm: 0 }}
          >
            <Grid item xs={12}>
              <TextInputField
                type={"text"}
                placeHolder={"Enter first name"}
                className={""}
                value={form.name}
                name={"name"}
                onChange={handleChange}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInputField
                type={"text"}
                placeHolder={"Email"}
                className={""}
                value={form.email}
                name={"email"}
                onChange={handleChange}
                disabled={false}
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <PhoneInput
                countryCode="IN"
                handleChange={handleNumberFieldChange}
                name="phoneNo"
                value={form.phoneNo}
              />
            </Grid>
            <Grid item xs={12}>
              <MultiRowInput
                type={"text"}
                placeHolder={"Address"}
                className={""}
                value={form.description}
                name={"description"}
                onChange={handleChange}
                disabled={undefined}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className=""
              style={{
                justifyContent: "flex-end",
                display: "flex",
              }}
              mt={5}
            >
              <WhiteButton
                handleClick={cancel}
                loading={false}
                title="Cancel"
              />
            </Grid>
            <Grid
              item
              xs={6}
              className=""
              mt={5}
              sx={{ paddingLeft: { xs: 2, md: "auto" } }}
            >
              <BookButton
                handleClick={handleClick}
                loading={loading}
                title="Submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default Index;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "45%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
  padding: "35px 40px",
  borderRadius: 3,
  // height: 500,
  outline: 0,
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    width: "95%",
    padding: "20px 20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "auto",
    width: "70%",
    padding: "30px 30px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: "auto",
    width: "80%",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    height: "auto",
    width: "60%",
  },
};
