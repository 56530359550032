import RoundedButton from "../../Component/Chip/RoundedButton";
import MainLayout from "../../Component/Layout/MainLayout";
import { Box, Container, Typography, styled } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectToken } from "../../Redux/Login/selector";
import { actions } from "../../Redux/Login/slice";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/Api";

function BookingConfirm() {
  const searchParams = new URLSearchParams(window.location.search);
  const bookingId = searchParams.get("bookingId");
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const email = searchParams.get("email");
  const status = searchParams.get("status");
  const msg = searchParams.get("msg");
  const dispatch = useDispatch();
  const isToken = useSelector(SelectToken);
  React.useEffect(() => {
    let token = localStorage.getItem("token");
    dispatch(actions.setToken(token || ""));

    return () => {};
  }, [isToken]);
  const handleDownloadTicket = (id: string) => {
    window.open(
      `${BASE_URL()}airports/export-booking-ticket/${id}?token=${isToken}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <>
      <MainLayout>
        <Container sx={{ minHeight: "calc(100vh - 200px)" }}>
          <MainBox>
            <TitleTypo>
              Hello&nbsp;{firstName} {lastName} 👋
            </TitleTypo>

            {status == "true" ? (
              <>
                <DescriptionTypo>
                  Booking Confirmed with
                  <span style={redTextStyle}> Booking Id {bookingId} </span>
                  {/* {returnPnr && (
                    <span style={redTextStyle}>
                     & one more Return PNR {returnPnr} <br />
                    </span>
                  )}{" "} */}
                  <br />
                  E-ticket shared to email I’d
                  <span style={userMailStyle}>&nbsp;{email}</span>
                </DescriptionTypo>
                <DescriptionTypo>
                  Download your
                  <span
                    style={{
                      cursor: "pointer",
                      color: "rgba(222, 49, 81, 1)",
                      fontWeight: 700,
                    }}
                    onClick={() => handleDownloadTicket(bookingId as string)}
                  >
                    &nbsp;E-ticket
                  </span>
                </DescriptionTypo>
                <ImportantNoteBox>
                  <RoundedButton
                    title={"Important Note"}
                    onClick={undefined}
                    color={"rgba(255, 153, 0, 1)"}
                    fontSize={"14px"}
                    backgroundColor={"rgba(255, 153, 0, 0.05)"}
                  />
                </ImportantNoteBox>
                <DescriptionTypo>
                  Web check-in for passengers is available 48 hrs to 60 mins
                  before domestic flight departure, and 24 hrs to 75 mins before
                  international flight departure.Airport Check-in at the counter
                  is available 60 mins before domestic flight departure and 75
                  mins before international flight departure.
                </DescriptionTypo>
              </>
            ) : (
              <>
                <DescriptionTypo>
                  Booking Id :<span style={redTextStyle}> {bookingId}</span>{" "}
                  <br />
                  {/* Sorry Your Booking field <br />
                   */}
                  {msg}
                </DescriptionTypo>
              </>
            )}
          </MainBox>
        </Container>
      </MainLayout>
    </>
  );
}

export default BookingConfirm;
const MainBox = styled(Box)(({ theme }) => ({
  marginTop: 64,
  marginBottom: 64,
  paddingRight: "30%",
  backgroundImage: "url(/images/flightWatermark.png)",
  backgroundPosition: "center right",
  backgroundOrigin: "border-box",
  backgroundRepeat: "no-repeat",
  backgroundSize: "60%",
  [theme.breakpoints.down("sm")]: {
    marginTop: 32,
    marginBottom: 32,
    paddingRight: "0%",
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginTop: 32,
    marginBottom: 32,
    paddingRight: "0%",
  },
}));
const TitleTypo = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: 24,
  color: "rgba(0, 0, 0, 1)",
  marginBottom: 30,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 22,
  },
}));
const ImportantNoteBox = styled(Box)(({ theme }) => ({
  width: "max-content",
  marginTop: 35,
  marginBottom: 25,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 22,
  },
}));
const DescriptionTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  color: "rgba(37, 37, 37, 1)",
  marginBottom: 10,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const redTextStyle = {
  color: "rgba(222, 49, 81, 1)",
  fontWeight: 700,
};
const userMailStyle = {
  color: "rgba(37, 37, 37, 1)",
  fontWeight: 700,
};
const NewLink = styled(Link)({
  textDecoration: "none",
});
