
import axios from 'axios';
let url = '';
let st_url = '';
url = process.env.REACT_APP_API || '';
st_url = process.env.REACT_APP_ASSETSURL || '';
export const path = () => {
  return st_url;
};
export const BASE_URL = () => {
  return url;
};
export default axios.create({
  baseURL: url
});
