import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { OutlinedInput, styled } from "@mui/material";
import { useStyles } from "./style";
import { ExpandMore } from "@mui/icons-material";

interface Props {
  name: string;
  placeholder: string;
  value: any;
  onChange: any;
  className: string;
  data: any;
  disabled: any;
  bootstrapInputClass?: string;
}
const BootstrapInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 12,
    padding: "12.5px 14px 12.5px 14px",
  },
}));

export default function BasicSelect(props: Props) {
  const style = useStyles();

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          size="small"
          disabled={props.disabled}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          IconComponent={ExpandMore}
          input={<BootstrapInput className={props?.bootstrapInputClass} />}
          displayEmpty
          renderValue={
            props?.value !== "" || undefined
              ? undefined
              : () => `${props.placeholder}`
          }
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            " fieldset": {
              border: "none !important",
              outline: "none !important",
            },
          }}
          className="fw-semibold"
          variant="outlined"
        >
          {props?.data?.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item.id}
              className=" selectorText fw-semibold fs-13"
            >
              {item?.name.length > 45
                ? item.name.slice(0, 45) + "..."
                : item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
