import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../types";

const selectDomain = (state: RootState) => {
  if (state && state.homeState) {
    return state.homeState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const selectTmcData = createSelector(
  [selectDomain],
  (state) => state.tmcData
);

export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectTotalPage = createSelector(
  [selectDomain],
  (state) => state.totalPage
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);

export const selectContactModal = createSelector(
  [selectDomain],
  (state) => state.contactModal
);
export const selectBlogList = createSelector(
  [selectDomain],
  (state) => state.blogList
);
export const selectBlogDetails = createSelector(
  [selectDomain],
  (state) => state.blogDetails
);
export const selectOffersList = createSelector(
  [selectDomain],
  (state) => state.offerList
);
export const selectFaqList = createSelector(
  [selectDomain],
  (state) => state.faqList
);
export const selectHomeBanner = createSelector(
  [selectDomain],
  (state) => state.homeBanner
);