import { Chip, Stack } from "@mui/material";
import React from "react";
interface Props {
  variant: string;
  handleClick: any;
}
function StatusChip(props: Props) {
  return (
    <Stack direction="row" spacing={1}>
      {props.variant == "ticket" ? (
        <Chip
          label="Download Ticket"
          size="small"
          sx={{
            bgcolor: "#4baa11",
            color: "#fff",
            borderRadius: "10px",
            fontSize: { xs: 11, md: 12 },
            "&:hover": {
              bgcolor: "#4baa11",
            },
          }}
          onClick={props.handleClick}
        />
      ) : props.variant == "invoice" ? (
        <Chip
          label="Download Invoice"
          size="small"
          sx={{
            bgcolor: "#a0a0a0",
            color: "#fff",
            borderRadius: "10px",
            fontSize: { xs: 11, md: 12 },
            "&:hover": {
              bgcolor: "#a0a0a0",
            },
          }}
          onClick={props.handleClick}
        />
      ) : (
        ""
      )}
    </Stack>
  );
}

export default StatusChip;
