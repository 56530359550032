import { fontSize } from "@mui/system";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  muiRadio: {
    // "& .MuiButtonBase-root": {
    //   padding: "0 9px 0 0!important",
    // },
    "& .muiRadio.css-ahj2mt-MuiTypography-roo": {
      fontSize: "12px",
    },
    "& .MuiRadio-root": {
      padding: "5px 9px",
    },
    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
      color: "#DE3151!important",
    },
    "& .css-vt4ac4-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
      color: "#DE3151!important",
    },

    "& .MuiFormControlLabel-labelPlacementEnd": {
      marginLeft: "0px",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "0px",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "12px",
    },
  },
});
