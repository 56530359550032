import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../types";

const selectDomain = (state: RootState) => {
  if (state && state.bookingState) {
    return state.bookingState;
  } else {
    return initialState;
  }
};

export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const SelectFlightBookingId = createSelector(
  [selectDomain],
  (state) => state.flightBookingId
);
export const selectSelectedFlightList = createSelector(
  [selectDomain],
  (state) => state.selectedFlightData
);
export const selectSelectedTravellerList = createSelector(
  [selectDomain],
  (state) => state.BookingPaymentForm.Passengers
);
export const selectSelectedFlightBooking = createSelector(
  [selectDomain],
  (state) => state.BookingPaymentForm
);
export const selectRazorePayForm = createSelector(
  [selectDomain],
  (state) => state.RazorePayForm
);
export const selectSaveTraveler = createSelector(
  [selectDomain],
  (state) => state.saveTraveler
);
export const selectStatus = createSelector(
  [selectDomain],
  (state) => state.status
);

export const selectSelectedFlightCard = createSelector(
  [selectDomain],
  (state) => state.selectedFlightCard
);
export const selectSelectedFlightReturnData = createSelector(
  [selectDomain],
  (state) => state.selectedFlightReturnData
);
export const selectRequestDetail = createSelector(
  [selectDomain],
  (state) => state.requestDetail
);
export const selectBookingTimer = createSelector(
  [selectDomain],
  (state) => state.bookingTimer
);
export const selectTimerModal = createSelector(
  [selectDomain],
  (state) => state.timerModal
);
export const selectCouponForm = createSelector(
  [selectDomain],
  (state) => state.couponForm
);
export const selectCouponDiscount = createSelector(
  [selectDomain],
  (state) => state.couponDiscount
);
