export interface FlightSearchInterface {
  journeyDate: any; // date "2023-12-15T08:00:00",
  journeyArrivalDate: string;
  returnArrivalDate: string;
  returnDate: any;
  travelClass: TravelClassEnum;
  destination: string;
  source: string;
  tripType: TripTypesEnum;
  airportSearch: string;
  priceFilter: FlightPriceFilterEnum;
  stopsFilter: FlightStopsQuantityEnum;
  departureFilterSelection: DateFilterEnum;
  arrivalFilterSelection: DateFilterEnum;
  sourceLocation: string;
  destinationLocation: string;
}
export enum FlightPriceFilterEnum {
  NONE = 0,
  LOW_TO_HIGH = 1,
  HIGH_TO_LOW = 2,
}
export enum FlightStopsQuantityEnum {
  ALL = 0,
  NON_STOP = 1,
  ONE_STOP = 2,
  TWO_OR_MORE_STOP = 3,
}
export enum DateFilterEnum {
  All = 0,
  Morning = 1,
  AfterNoon = 2,
  Evening = 3,
  Night = 4,
}

export enum TripTypesEnum {
  ONE_WAY = 1,
  ROUNDTRIP = 2,
}

export enum TravelClassEnum {
  All = 1,
  ECONOMY = 2,
  PREMIUM_ECONOMY = 3,
  BUSINESS = 4,
  PREMIUM_BUSINESS = 5,
  FIRST_CLASS = 6,
}

export interface AirportListInterface {
  airportCode: string;
  airportDesc: string;
  city: string;
  country: string;
  _id: string;
}
export interface FlightSearchGenInterface {
  adults: number;
  children: number;
  infants: number;
}
export interface FlightSearchDetailsInterface {
  adults: number;
  children: number;
  infants: number;
}
export interface CouponListInterface {
  _id: string;
  title: string;
  description: string;
  status: number;
  couponDiscountType: CouponDiscountTypeEnum;
  couponDiscount: string;
  couponDiscountPercentage: string;
  createdAt: string;
  updatedAt: string;
  id: string;
}
export enum CouponDiscountTypeEnum {
  VALUE = 1,
  PERCENTAGE = 2
}
export interface FlightSearchListInterface {
  AirlineLogo: string;
  ResultIndex: string;
  PublishedFare: number;
  source: string;
  selected: boolean;
  destination: string;
  AirlineCode: string;
  AirlineName: string;
  FlightNumber: string;
  FareClass: string;
  SourceCity: string;
  DestinationCity: string;
  DepTime: string;
  ArrTime: string;
  Duration: string;
  Segments: [
    {
      Baggage: string;
      CabinBaggage: string;
      Airline: {
        AirlineCode: string;
        AirlineName: string;
        FlightNumber: string;
        FareClass: string;
        OperatingCarrier: string;
      };
      NoOfSeatAvailable: number;
      Origin: {
        Airport: {
          AirportCode: string;
          AirportName: string;
          Terminal: string;
          CityCode: string;
          CityName: string;
          CountryCode: string;
          CountryName: string;
        };
        DepTime: string;
      };
      Destination: {
        Airport: {
          AirportCode: string;
          AirportName: string;
          Terminal: string;
          CityCode: string;
          CityName: string;
          CountryCode: string;
          CountryName: string;
        };
        ArrTime: string;
      };
      Craft: string;
      Duration: string;
      GroundTime: string;
    }
  ];
}

export interface FlightSearchState {
  list: FlightSearchInterface[];
  form: FlightSearchInterface;
  genList: FlightSearchGenInterface[];
  genForm: FlightSearchGenInterface;
  flightSearchList: FlightSearchListInterface[];
  returnFlightSearchList: FlightSearchListInterface[];
  loading: boolean;
  isAdd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  airportList: AirportListInterface[];
  flightBookingId: string;
  formUpdate: boolean;
  couponList: CouponListInterface[];
  buttonLoading: boolean;
}

export type InitialFlightSearchState = FlightSearchState;
