import {
  SelectLoading,
  SelectTravellerList,
  selectDeleteModal,
} from "../../../Redux/Login/selector";
import { actions } from "../../../Redux/Login/slice";
import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import RoundedButton from "../../Chip/RoundedButton";
import PersonIcon from "@mui/icons-material/Person";
import { GenderEnum, PassengerPaxTypeEnum } from "../../../Redux/Login/types";
import AddTravellerTab from "../AddTravellerTab";
import DeleteModal from "../../DeleteModal";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as AuthSliceKey,
  reducer as AuthReducer,
} from "../../../Redux/Login/slice";
import { AuthRepoSaga } from "../../../Redux/Login/saga";
import moment from "moment";

function Index() {
  useInjectReducer({ key: AuthSliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  const [showAction, setShowAction] = React.useState(-1);
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = React.useState("");
  const list = useSelector(SelectTravellerList);
  const loading = useSelector(SelectLoading);
  const deleteModal = useSelector(selectDeleteModal);
  React.useEffect(() => {
    dispatch(actions.doGetTraveller());
    return () => {};
  }, []);
  useInjectReducer({ key: AuthSliceKey, reducer: AuthReducer });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  const handleAdd = () => {
    dispatch(actions.toggleOpenPassenger());
  };
  const handleEdit = (id: any) => {
    dispatch(
      actions.doGetByIdTraveller({
        id: id,
        callback() {
          dispatch(actions.toggleOpenPassenger());
          dispatch(actions.doGetTraveller());
        },
      })
    );
  };
  const handleDelete = (id: any) => {
    dispatch(
      actions.doDeleteTraveller({
        id: id,
        callback() {
          dispatch(actions.toggleOpenDelete());
          dispatch(actions.doGetTraveller());
        },
      })
    );
  };
  const handleShowAction = (id: number) => {
    setShowAction(id);
  };
  const handleHideAction = () => {
    setShowAction(-1);
  };
  const getYear = (dob: string) => {
    var a = moment();
    var b = moment(dob, "YYYY");
    var diff = a.diff(b, "years"); // calculates patient's age in years
    return diff;
  };
  // React.useEffect(() => {
  //   getYear();
  //   return () => {};
  // }, []);

  return (
    <Box>
      {/* {adultData.length > 0 && <Heading>Adult:</Heading>} */}
      <Grid container sx={{ justifyContent: "space-between", mb: 2.5 }}>
        <Grid item>
          <Heading>
            You have {list?.length} Traveller
            {list?.length > 0 ? " (s)" : ""}
          </Heading>
        </Grid>
        <Grid item>
          <Box width={"150px"}>
            <RoundedButton
              fontSize="12px"
              backgroundColor=""
              color="rgba(222, 49, 81, 1)"
              onClick={handleAdd}
              title="+ Add Traveller"
            />
          </Box>
        </Grid>
      </Grid>
      {list.map((data, index: number) => {
        return (
          <TravellerBox
            key={index}
            onMouseEnter={() => handleShowAction(index)}
            onMouseLeave={handleHideAction}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={10}
                md={11.3}
                className="textAlign"
                sx={{ flexWrap: "wrap" }}
              >
                <InfoTypo sx={{ display: "flex", alignItems: "center" }}>
                  <PersonIcon />
                </InfoTypo>
                <InfoTypo>{data.fullName}</InfoTypo>
                <InfoTypo1>
                  <span style={{ textTransform: "uppercase" }}>
                    {"(" +
                      (data.Gender == GenderEnum.MALE
                        ? "Male "
                        : data.Gender == GenderEnum.FEMALE
                        ? "Female "
                        : "Other ")}
                  </span>
                  {getYear(data?.DateOfBirth) + "Y" + ")"}
                </InfoTypo1>
                <InfoTypo1>{data?.ContactNo}</InfoTypo1>
                <InfoTypo1>{data?.Email}</InfoTypo1>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={0.7}
                className="textAlign"
                sx={{ justifyContent: "end" }}
              >
                {showAction == index && (
                  <>
                    <IconButton
                      sx={{
                        p: 0,
                        "&: hover": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                      onClick={() => handleEdit(data._id)}
                    >
                      {/* <EditIcon
                        sx={{
                          cursor: "pointer",
                          color: "rgba(222, 49, 81, 1)",
                          mr: 1,
                        }}
                      /> */}
                      <InfoTypo
                        sx={{
                          color: "rgba(222, 49, 81, 1)",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        View Detail
                      </InfoTypo>
                    </IconButton>
                    <IconButton
                      sx={{
                        p: 0,
                        "&: hover": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                      onClick={() => {
                        setDeleteId(data._id);
                        dispatch(actions.toggleOpenDelete());
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          cursor: "pointer",
                          color: "rgba(222, 49, 81, 1)",
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </TravellerBox>
        );
      })}

      <AddTravellerTab />
      <DeleteModal
        show={deleteModal}
        handleClose={() => dispatch(actions.toggleOpenDelete())}
        handleDelete={() => handleDelete(deleteId)}
        deleteId={deleteId}
        loading={loading}
      />
    </Box>
  );
}

export default Index;
const TravellerBox = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid rgba(37, 37, 37, 0.2)",
  // borderRadius: 6,
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "16px 20px",
  // marginBottom: 15,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const InfoTypo = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  marginRight: 10,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  // marginRight: 10,
  // marginBottom: 20,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
const InfoTypo1 = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: "#4a4a4a",
  marginRight: 10,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {},
}));
