import { Link, useParams } from "react-router-dom";
import MainLayout from "../../Component/Layout/MainLayout";
import { selectBlogDetails } from "../../Redux/Home/selector";
import { actions } from "../../Redux/Home/slice";
import { Box, Button, Container, styled, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

function TravellersDenDetails() {
  const dispatch = useDispatch();
  const detail = useSelector(selectBlogDetails);
  const params = useParams();
  React.useEffect(() => {
    dispatch(
      actions.doGetBlogById({
        id: params.id as string,
        callback: () => {},
      })
    );
    return () => {};
  }, [params.id]);
  return (
    <>
      <MainLayout>
        <Container
          sx={{ pb: { xs: 2, md: 5 } }}
        >
          <NewLink to={"/travellers-den"}>
            <OutlinedButton>Back</OutlinedButton>
          </NewLink>
          <Box>
            <img src="/images/flight_2.png" alt="Flight Image" width={"100%"} />
          </Box>
          <Box>
            <TitleTypo>{detail.title}</TitleTypo>
            <DescriptionTypo
              dangerouslySetInnerHTML={{ __html: detail.description }}
            />
          </Box>
        </Container>
      </MainLayout>
    </>
  );
}

export default TravellersDenDetails;

const OutlinedButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 600,
  borderRadius: 8,
  width: 100,
  fontSize: 16,
  border: "1px solid #DE3151",
  color: "#DB3938",
  marginTop: 20,
  marginBottom: 28,
  "&:hover": {
    backgroundColor: "#fff",
  },
  [theme.breakpoints.down("sm")]: {
    width: 80,
    // padding: "8px 12px",
    marginTop: 16,
    marginBottom: 24,
  },
}));
const TitleTypo = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: 28,
  color: "rgba(0, 0, 0, 1)",
  marginTop: 32,
  marginBottom: 12,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    marginTop: 22,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 24,
  },
}));
const DescriptionTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 20,
  color: "rgba(0, 0, 0, 1)",
  marginBottom: 12,
  textAlign: "unset",
  lineHeight: "29.3px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const NewLink = styled(Link)({
  textDecoration: "none",
});
