import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./types/initialState";
import { RootState } from "../../types";

const selectDomain = (state: RootState) => {
  if (state && state.authState) {
    return state.authState;
  } else {
    return initialState;
  }
};

export const selectUserDataForm = createSelector(
  [selectDomain],
  (state) => state.userData
);
export const SelectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectSearch = createSelector(
  [selectDomain],
  (state) => state.search
);
export const SelectLoginModal = createSelector(
  [selectDomain],
  (state) => state.loginModal
);
export const selectForm = createSelector([selectDomain], (state) => state.form);
export const SelectTravellerList = createSelector(
  [selectDomain],
  (state) => state.travellerList
);
export const selectTravellerForm = createSelector(
  [selectDomain],
  (state) => state.travellerForm
);
export const selectTmc = createSelector([selectDomain], (state) => state.tmc);
export const selectPassengerModal = createSelector(
  [selectDomain],
  (state) => state.passengerModal
);

export const selectStatus = createSelector(
  [selectDomain],
  (state) => state.status
);

export const SelectIsLogin = createSelector(
  [selectDomain],
  (state) => state.isLogin
);
export const SelectToken = createSelector(
  [selectDomain],
  (state) => state.token
);
export const selectCountryList = createSelector(
  [selectDomain],
  (state) => state.countryList
);
export const selectDeleteModal = createSelector(
  [selectDomain],
  (state) => state.deleteModal
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectTotalPage = createSelector(
  [selectDomain],
  (state) => state.totalPage
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectHasMore = createSelector(
  [selectDomain],
  (state) => state.hasMore
);
export const selectBookingList = createSelector(
  [selectDomain],
  (state) => state.bookingList
);
export const selectBookingCancelForm = createSelector(
  [selectDomain],
  (state) => state.bookingCancel
);
export const selectBookingLoading = createSelector(
  [selectDomain],
  (state) => state.bookingLoading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectShowUseDetailsModal = createSelector(
  [selectDomain],
  (state) => state.showUseDetailsModal
);
export const selectSubmitCancelPolicyModal = createSelector(
  [selectDomain],
  (state) => state.submitCancelPolicyModal
);
export const selectCancelPassengerList = createSelector(
  [selectDomain],
  (state) => state.cancelPassengerList
);
export const selectSetCancelPassengerList = createSelector(
  [selectDomain],
  (state) => state.setCancelPassengerList
);
export const selectCancelPassengerTotalBreakdown = createSelector(
  [selectDomain],
  (state) => state.CancelPassengerTotalBreakdown
);
export const selectCancelBooking = createSelector(
  [selectDomain],
  (state) => state.cancelBooking
);
export const selectCancelBookingTotalPassenger = createSelector(
  [selectDomain],
  (state) => state.cancelBookingTotalPassenger
);
export const selectStateList = createSelector(
  [selectDomain],
  (state) => state.stateList
);
