import CouponCard from "../../Component/Card/CouponCard";
import MainLayout from "../../Component/Layout/MainLayout";
import {
  selectLoading,
  selectOffersList,
  selectPageNo,
  selectTotalPage,
} from "../../Redux/Home/selector";
import { actions } from "../../Redux/Home/slice";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Component/Pagination";
import Footer from "../../Component/Layout/Footer";

function Offers() {
  const dispatch = useDispatch();
  const list = useSelector(selectOffersList);
  const pageNo = useSelector(selectPageNo);
  const TotalPage = useSelector(selectTotalPage);
  const loading = useSelector(selectLoading);
  //pagination
  const handlePlus = (value: number) => {
    dispatch(actions.setPageNo(value + 1));
  };
  const handleMinus = (value: number) => {
    dispatch(actions.setPageNo(value - 1));
  };
  const handlePageChange = (event: any, value: number) => {
    dispatch(actions.setPageNo(value));
  };

  React.useEffect(() => {
    dispatch(actions.doGetOffersList());
    return () => {};
  }, []);

  return (
    <>
      <MainLayout>
        <NewContaier>
          {list.length > 0 && (
            <Grid container rowSpacing={3} columnSpacing={3}>
              {list?.map((data: any) => (
                <Grid key={data?._id} item xs={12} sm={6} md={4} lg={3}>
                  <CouponCard
                    loading={loading}
                    image={"/images/coupon_1.png"}
                    couponCode={data?.title}
                    offUpto={data?.description}
                  />
                </Grid>
              ))}
              {TotalPage >= 1 && (
                <Grid item xs={12}>
                  <Pagination
                    count={TotalPage}
                    handleChange={handlePageChange}
                    handleMinus={handleMinus}
                    handlePlus={handlePlus}
                    pageNo={pageNo}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </NewContaier>
        <Footer />
      </MainLayout>
    </>
  );
}

export default Offers;

const NewContaier = styled(Container)(({ theme }) => ({
  paddingTop: 70,
  paddingBottom: 70,
  minHeight: "calc(100vh - 150px)",
  [theme.breakpoints.down("sm")]: {
    paddingTop: 40,
    paddingBottom: 40,
  },
}));
