import { Box, Typography, styled, Grid } from "@mui/material";
import React from "react";
import RoundedButton from "../Chip/RoundedButton";
import ViewFlightInfoCard from "../Listing/ListingFlightCard/viewFlightInfoCard";
import { DateTime } from "luxon";
import moment from "moment";
import {
  FlightReturnDataInterface,
  requestDetailInterface,
  selectedFlightDataInterface,
} from "../../Redux/Booking/types";
import { TravelClassEnum } from "../../Redux/flightSearch/types";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as BookingSliceKey,
  reducer as BookingReducer,
} from "../../Redux/Booking/slice";
import { BookingRepoSaga } from "../../Redux/Booking/saga";
import {
  AirlineSeatReclineExtra,
  DirectionsRunRounded,
} from "@mui/icons-material";
import { path } from "../../utils/Api";
// import { log } from "../../utils/logger";

interface Props {
  data: selectedFlightDataInterface;
  returnData: selectedFlightDataInterface;
  requestData: requestDetailInterface;
}

function Index(props: Props) {
  useInjectReducer({ key: BookingSliceKey, reducer: BookingReducer });
  useInjectSaga({ key: BookingSliceKey, saga: BookingRepoSaga });
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    if (show == false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const calculateTimeDifference = (date1: string, date2: string) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);
    const duration = moment.duration(momentDate2.diff(momentDate1));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const formattedDifference = `${hours}h ${minutes} m`;
    return formattedDifference;
  };
  const convertMinutesToHours = (time: number) => {
    var num = time;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0) {
      return rhours + "h " + rminutes + "m";
    } else {
      return rminutes + "m";
    }
  };
  return (
    <>
      {props?.data?.Segments?.map((data, index: number) => {
        return (
          <FlightCard
            key={index}
            className={`flightCardBorder ${
              props?.data?.Segments?.length > 1 && index == 0
                ? "hideBottomBorder"
                : index == 1
                ? "topBorderRadius"
                : ""
            }`}
          >
            {data?.GroundTime > 0 && (
              <Box className="textCenter">
                <LayoverText>
                  {convertMinutesToHours(data?.GroundTime) > "2:00" ? (
                    <AirlineSeatReclineExtra
                      sx={{ width: 14, height: 14, color: "#4d4d4d" }}
                    />
                  ) : (
                    <DirectionsRunRounded
                      sx={{ width: 14, height: 14, color: "#4d4d4d" }}
                    />
                  )}
                  Layover {convertMinutesToHours(data?.GroundTime)}
                </LayoverText>
              </Box>
            )}
            <Grid
              container
              sx={{ pr: { xs: 0, md: 0 }, rowGap: { xs: 2, md: 0 } }}
            >
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "end" }}>
                  <BookingTitleTypo>
                    {data?.Origin?.Airport?.CityName} -{" "}
                    {data?.Destination?.Airport?.CityName}
                  </BookingTitleTypo>
                  <DayAndDateTypo
                    sx={{ mb: { xs: "1px", sm: "1px", md: "2px" } }}
                  >
                    {DateTime.fromISO(data?.Origin?.DepTime)?.toFormat(
                      "EEE dd MMM"
                    )}
                  </DayAndDateTypo>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{ mt: { md: 0.5 }, rowGap: { xs: 2, md: 0 } }}
                >
                  <Grid item xs={12} sm={3} md={4}>
                    <Box className="textAlign">
                      <img
                        src={
                          data?.Airline?.AirlineLogo
                            ? path() + data?.Airline?.AirlineLogo
                            : "/images/airplane.png"
                        }
                        alt="Logo"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: 18,
                        }}
                      />
                      <Box>
                        <AirlineNameTypo>
                          {data?.Airline?.AirlineName}
                        </AirlineNameTypo>
                        <CountryCodeTypo>
                          {" "}
                          {data?.Airline?.AirlineCode}-
                          {data?.Airline?.FlightNumber}
                        </CountryCodeTypo>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={9} md={8}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Box textAlign={"center"}>
                          <FlightTimeTypo>
                            {DateTime?.fromISO(data?.Origin?.DepTime)?.toFormat(
                              "HH:mm"
                            )}
                          </FlightTimeTypo>
                          <DestinationTypo>
                            {data?.Origin?.Airport.CityName}{" "}
                            {data?.Origin?.Airport?.Terminal.length > 0 ? (
                              <>(Terminal {data?.Origin?.Airport?.Terminal})</>
                            ) : (
                              ""
                            )}
                          </DestinationTypo>
                          <DestinationTypo>
                            {data?.Origin?.Airport.AirportName}
                          </DestinationTypo>
                          <DayAndDateTypo>
                            {DateTime.fromISO(data?.Origin?.DepTime)?.toFormat(
                              "EEE dd MMM"
                            )}
                          </DayAndDateTypo>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box textAlign={"center"}>
                          <TimeTypo>
                            {calculateTimeDifference(
                              data?.Origin?.DepTime,
                              data?.Destination?.ArrTime
                            )}
                          </TimeTypo>
                          {/* <AirlineNameTypo
                          sx={{ color: "rgba(37, 37, 37, 0.7)" }}
                        >
                          {data?.length} stop
                        </AirlineNameTypo> */}
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box textAlign={"center"}>
                          <FlightTimeTypo>
                            {DateTime?.fromISO(
                              data?.Destination?.ArrTime
                            )?.toFormat("HH:mm")}
                          </FlightTimeTypo>
                          <DestinationTypo>
                            {data?.Destination?.Airport.CityName}{" "}
                            {data?.Destination?.Airport?.Terminal.length > 0 ? (
                              <>
                                (Terminal {data?.Destination?.Airport?.Terminal}
                                )
                              </>
                            ) : (
                              ""
                            )}
                          </DestinationTypo>
                          <DestinationTypo>
                            {data?.Destination?.Airport.AirportName}
                          </DestinationTypo>
                          <DayAndDateTypo>
                            {DateTime.fromISO(
                              data?.Destination?.ArrTime
                            )?.toFormat("EEE dd MMM")}
                          </DayAndDateTypo>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={{ xs: 0, md: 2 }}>
                <Grid container sx={{ rowGap: 2 }}>
                  <Grid item xs={12} md={9.5} lg={9.5}>
                    <Grid container sx={{ rowGap: 2, overflow: "flex-wrap" }}>
                      <Grid item>
                        <RoundedButton
                          fontSize="12px"
                          onClick={undefined}
                          backgroundColor="rgba(232, 70, 0, 0.05)"
                          color="rgba(232, 70, 0, 1)"
                          title={
                            props.requestData?.travelClass ===
                            TravelClassEnum.ECONOMY
                              ? "Economy"
                              : props.requestData?.travelClass ===
                                TravelClassEnum.PREMIUM_ECONOMY
                              ? "Premium Economy"
                              : props.requestData?.travelClass ===
                                TravelClassEnum.BUSINESS
                              ? "Business Class"
                              : props.requestData?.travelClass ===
                                TravelClassEnum.FIRST_CLASS
                              ? "First Class"
                              : "Economy"
                          }
                        />
                      </Grid>
                      {data?.Baggage?.length > 0 && (
                        <Grid item>
                          <ClassPerksButton>
                            <DownloadTicketButtonText>
                              Check-in baggage {data?.Baggage} / Adult
                            </DownloadTicketButtonText>
                          </ClassPerksButton>
                        </Grid>
                      )}
                      {data?.CabinBaggage?.length > 0 && (
                        <Grid item>
                          <ClassPerksButton>
                            <DownloadTicketButtonText>
                              Cabin baggage {data?.CabinBaggage} / Adult
                            </DownloadTicketButtonText>
                          </ClassPerksButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2.5} lg={2.5} className="textCenter">
                    <DownloadTicketButtonText
                      sx={{
                        fontWeight: 600,
                        color: "#DB383C",
                        cursor: "pointer",
                      }}
                      onClick={handleClick}
                    >
                      {/* {show == false ? "View Flight Info" : "Hide Flight Info"} */}
                    </DownloadTicketButtonText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {show == true && (
              <>
                <ViewFlightInfoCard detailsData={data} />
              </>
            )}
          </FlightCard>
        );
      })}
      {props?.returnData?.Segments?.map((data, index: number) => {
        return (
          <FlightCard
            key={index}
            className={`flightCardBorder ${
              props?.returnData?.Segments?.length > 1 && index == 0
                ? "hideBottomBorder"
                : index == 1
                ? "topBorderRadius"
                : ""
            }`}
            sx={{ mt: index == 0 ? 1 : 0 }}
          >
            {data?.GroundTime > 0 && (
              <Box className="textCenter">
                <LayoverText>
                  {convertMinutesToHours(data?.GroundTime) > "2:00" ? (
                    <AirlineSeatReclineExtra
                      sx={{ width: 14, height: 14, color: "#4d4d4d" }}
                    />
                  ) : (
                    <DirectionsRunRounded
                      sx={{ width: 14, height: 14, color: "#4d4d4d" }}
                    />
                  )}
                  Layover {convertMinutesToHours(data?.GroundTime)}
                </LayoverText>
              </Box>
            )}
            <Grid
              container
              sx={{ pr: { xs: 0, md: 0 }, rowGap: { xs: 2, md: 0 } }}
            >
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "end" }}>
                  <BookingTitleTypo>
                    {data?.Origin?.Airport?.CityName} -{" "}
                    {data?.Destination?.Airport?.CityName}
                  </BookingTitleTypo>
                  <DayAndDateTypo
                    sx={{ mb: { xs: "1px", sm: "1px", md: "2px" } }}
                  >
                    {DateTime.fromISO(data?.Origin?.DepTime)?.toFormat(
                      "EEE dd MMM"
                    )}
                  </DayAndDateTypo>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{ mt: { md: 0.5 }, rowGap: { xs: 2, md: 0 } }}
                >
                  <Grid item xs={12} sm={3} md={4}>
                    <Box className="textAlign">
                      <img
                        src={
                          data?.Airline?.AirlineLogo
                            ? path() + data?.Airline?.AirlineLogo
                            : "/images/airplane.png"
                        }
                        alt="Logo"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: 18,
                        }}
                      />
                      <Box>
                        <AirlineNameTypo>
                          {data?.Airline?.AirlineName}
                        </AirlineNameTypo>
                        <CountryCodeTypo>
                          {" "}
                          {data?.Airline?.AirlineCode}-
                          {data?.Airline?.FlightNumber}
                        </CountryCodeTypo>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={9} md={8}>
                    <Grid container>
                      <Grid item xs={4}>
                        <Box textAlign={"center"}>
                          <FlightTimeTypo>
                            {DateTime?.fromISO(data?.Origin?.DepTime)?.toFormat(
                              "HH:mm"
                            )}
                          </FlightTimeTypo>
                          <DestinationTypo>
                            {data?.Origin?.Airport.CityName}{" "}
                            {data?.Origin?.Airport?.Terminal.length > 0 ? (
                              <>(Terminal {data?.Origin?.Airport?.Terminal})</>
                            ) : (
                              ""
                            )}
                          </DestinationTypo>
                          <DestinationTypo>
                            {data?.Origin?.Airport.AirportName}
                          </DestinationTypo>
                          <DayAndDateTypo>
                            {DateTime.fromISO(data?.Origin?.DepTime)?.toFormat(
                              "EEE dd MMM"
                            )}
                          </DayAndDateTypo>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box textAlign={"center"}>
                          <TimeTypo>
                            {calculateTimeDifference(
                              data?.Origin?.DepTime,
                              data?.Destination?.ArrTime
                            )}
                          </TimeTypo>
                          {/* <AirlineNameTypo
                          sx={{ color: "rgba(37, 37, 37, 0.7)" }}
                        >
                          {data?.length} stop
                        </AirlineNameTypo> */}
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box textAlign={"center"}>
                          <FlightTimeTypo>
                            {DateTime?.fromISO(
                              data?.Destination?.ArrTime
                            )?.toFormat("HH:mm")}
                          </FlightTimeTypo>
                          <DestinationTypo>
                            {data?.Destination?.Airport.CityName}{" "}
                            {data?.Destination?.Airport?.Terminal.length > 0 ? (
                              <>
                                (Terminal {data?.Destination?.Airport?.Terminal}
                                )
                              </>
                            ) : (
                              ""
                            )}
                          </DestinationTypo>
                          <DestinationTypo>
                            {data?.Destination?.Airport.AirportName}
                          </DestinationTypo>
                          <DayAndDateTypo>
                            {DateTime.fromISO(
                              data?.Destination?.ArrTime
                            )?.toFormat("EEE dd MMM")}
                          </DayAndDateTypo>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={{ xs: 0, md: 2 }}>
                <Grid container sx={{ rowGap: 2 }}>
                  <Grid item xs={12} md={9.5} lg={9.5}>
                    <Grid container sx={{ rowGap: 2, overflow: "flex-wrap" }}>
                      <Grid item>
                        <RoundedButton
                          fontSize="12px"
                          onClick={undefined}
                          backgroundColor="rgba(232, 70, 0, 0.05)"
                          color="rgba(232, 70, 0, 1)"
                          title={
                            props.requestData?.travelClass ===
                            TravelClassEnum.ECONOMY
                              ? "Economy"
                              : props.requestData?.travelClass ===
                                TravelClassEnum.PREMIUM_ECONOMY
                              ? "Premium Economy"
                              : props.requestData?.travelClass ===
                                TravelClassEnum.BUSINESS
                              ? "Business Class"
                              : props.requestData?.travelClass ===
                                TravelClassEnum.FIRST_CLASS
                              ? "First Class"
                              : "Economy"
                          }
                        />
                      </Grid>
                      {data?.Baggage?.length > 0 && (
                        <Grid item>
                          <ClassPerksButton>
                            <DownloadTicketButtonText>
                              Check-in baggage {data?.Baggage} / Adult
                            </DownloadTicketButtonText>
                          </ClassPerksButton>
                        </Grid>
                      )}
                      {data?.CabinBaggage?.length > 0 && (
                        <Grid item>
                          <ClassPerksButton>
                            <DownloadTicketButtonText>
                              Cabin baggage {data?.CabinBaggage} / Adult
                            </DownloadTicketButtonText>
                          </ClassPerksButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2.5} lg={2.5} className="textCenter">
                    <DownloadTicketButtonText
                      sx={{
                        fontWeight: 600,
                        color: "#DB383C",
                        cursor: "pointer",
                      }}
                      onClick={handleClick}
                    >
                      {/* {show == false ? "View Flight Info" : "Hide Flight Info"} */}
                    </DownloadTicketButtonText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {show == true && (
              <>
                <ViewFlightInfoCard detailsData={data} />
              </>
            )}
          </FlightCard>
        );
      })}
    </>
  );
}

export default Index;
const FlightCard = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: "24px 24px 16px 24px",
  position: "relative",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const AirlineNameTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 14,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const CountryCodeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.7)",
  fontSize: 12,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const FlightTimeTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 4,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const DestinationTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 12,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const TimeTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.6)",
  fontSize: 14,
  fontWeight: 600,
  paddingBottom: 4,
  marginBottom: 4,
  // borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const WarningButton = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(255, 153, 0, 1)",
  backgroundColor: "rgba(255, 153, 0, 0.05)",
  borderRadius: 35,
  padding: "6px 12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginRight: 10,
  },
  [theme.breakpoints.between("sm", "md")]: {
    marginRight: 14,
  },
}));

const BookingTitleTypo = styled(Typography)(({ theme }) => ({
  color: "#252525",
  fontSize: 16,
  fontWeight: 600,
  marginRight: 12,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 14,
  },
}));
const DayAndDateTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(37, 37, 37, 0.7)",
  fontSize: 12,
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const ClassPerksButton = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  border: "1px solid rgba(232, 70, 0, 1)",
  backgroundColor: "rgba(232, 70, 0, 0.05)",
  borderRadius: 35,
  padding: "6px 18px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  marginRight: 14,
  [theme.breakpoints.down("sm")]: {
    padding: "6px 14px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "6px 16px",
  },
}));
const DownloadTicketButtonText = styled(Typography)(({ theme }) => ({
  color: "rgba(232, 70, 0, 1)",
  fontSize: 12,
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 12,
  },
}));
const LayoverText = styled(Box)(({ theme }) => ({
  color: "#000",
  backgroundColor: "#f7f7f7",
  fontSize: 12,
  fontWeight: 400,
  padding: "3px 10px",
  width: "20%",
  position: "absolute",
  top: -12,
  left: "50%",
  transform: "translate(-50%)",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  flexShrink: 0,
  minWidth: 180,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
