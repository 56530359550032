import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IUserDataInterface } from "../../Redux/Login/types";
import { Box, Typography, styled } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  profileData: IUserDataInterface;
}

function ProfileSelector(props: Props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/profile?index=0&tab=booking");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    navigate("/");
    window.location.reload();
    localStorage.removeItem("token");
    localStorage.clear();
  };

  return (
    <div>
      <Button
        disableRipple
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon sx={{ color: "#DB3938" }} />}
        sx={{
          textTransform: "capitalize",
          ":hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <MyAccount>
          <img
            src={props.profileData.profilePic}
            alt={props.profileData.profilePic}
            width={26}
            height={26}
            style={{ borderRadius: "50%" }}
            className="navbarUserIcon "
          />

          <NavbarText sx={{ color: "#DE3151" }}>
            My Account
            {/* Hi {props.profileData.fullName.substring(0, 5)} */}
          </NavbarText>
        </MyAccount>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          top: "55px",
        }}
      >
        <MenuItem
          onClick={handleProfile}
          sx={{ "&.MuiMenuItem-root": { minHeight: "auto !important" } }}
        >
          <NavbarText>My Profile</NavbarText>
        </MenuItem>
        <MenuItem
          sx={{
            display: { xs: "block", md: "none" },
            "&.MuiMenuItem-root": { minHeight: "auto !important" },
          }}
        >
          <NewLink to={"/offers"}>
            <NavbarText>Offers</NavbarText>
          </NewLink>
        </MenuItem>
        <MenuItem
          sx={{
            display: {
              xs: "block",
              md: "none",
              "&.MuiMenuItem-root": { minHeight: "auto !important" },
            },
          }}
        >
          <NewLink to={"/travellers-den"}>
            <NavbarText>Travellers Den</NavbarText>
          </NewLink>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{ "&.MuiMenuItem-root": { minHeight: "auto !important" } }}
        >
          <NavbarText>Logout</NavbarText>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ProfileSelector;
const MyAccount = styled(Box)(({ theme }) => ({
  color: "#DB3938",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const NavbarText = styled(Typography)(({ theme }) => ({
  fontSize: 16.5,
  fontWeight: 500,
  color: "rgba(37, 37, 37, 1)",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [theme.breakpoints.between("sm", "md")]: {
    fontSize: 16,
  },
}));
const NewLink = styled(Link)({
  textDecoration: "none",
});
