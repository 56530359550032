import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "../Selector/style";
import { actions } from "../../Redux/flightSearch/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectAirportList,
  SelectLoading,
  selectForm,
} from "../../Redux/flightSearch/selector";
import { useLocation } from "react-router-dom";
import { AirportListInterface } from "../../Redux/flightSearch/types";
import theme from "../../Style/theme";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

const Index: React.FC = () => {
  const airportList = useSelector(SelectAirportList);
  const isLoading = useSelector(SelectLoading);
  const form = useSelector(selectForm);
  const dispatch = useDispatch();
  const location = useLocation();
  const smd = useMediaQuery(theme.breakpoints.down("sm"));
  const [listSearch, setListSearch] = useState(0);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const destination = params.get("destination");
    const source = params.get("source");
    const sourceLocation = params.get("sourceLocation");
    const destinationLocation = params.get("destinationLocation");

    if (destination) {
      setDestinationValue({
        airportDesc: "",
        airportCode: destination,
        city: destinationLocation,
      });
    }
    if (source) {
      setSourceValue({
        airportDesc: "",
        airportCode: source,
        city: sourceLocation,
      });
    }
  }, [location.search]);
  const sourceId = `autoCompleteTextField${"From"}`;
  const destinationId = `autoCompleteTextField${"To"}`;
  const [sourceValue, setSourceValue] = React.useState<any | null>(null);
  const [destinationValue, setDestinationValue] = React.useState<any | null>(
    null
  );
  const style = useStyles();
  const handleSelectAirportSource = (newValue: any) => {
    if (newValue != null && newValue != undefined) {
      setSourceValue(newValue);
      dispatch(
        actions.updateFormValue({
          key: "source",
          value: newValue?.airportCode,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "sourceLocation",
          value: newValue?.city,
        })
      );
      dispatch(actions.doGetAirportList(" "));
    }
    return;
  };
  const handleSelectAirportDestination = (newValue: any) => {
    if (newValue != null && newValue != undefined) {
      setDestinationValue(newValue);
      dispatch(
        actions.updateFormValue({
          key: "destination",
          value: newValue?.airportCode,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "destinationLocation",
          value: newValue?.city,
        })
      );
      dispatch(actions.doGetAirportList(" "));
    }
    return;
  };

  const handleSearch = (evt: any, value: any, type: number) => {
    dispatch(actions.doGetAirportList(value));
    setListSearch(type);
  };
  const handleChangeSource = () => {
    const temp = sourceValue;
    setSourceValue(destinationValue);
    setDestinationValue(temp);
    dispatch(
      actions.updateFormValue({ key: "source", value: form.destination })
    );
    dispatch(
      actions.updateFormValue({ key: "destination", value: form.source })
    );
  };
  useEffect(() => {
    if (listSearch !== 0) {
      setTimeout(() => {
        setListSearch(0);
      }, 1000);
    }

    return () => {};
  }, [listSearch]);

  return (
    <div>
      <Grid container spacing={2} sx={{ position: "relative" }}>
        <Grid item xs={12} md={11.5}>
          <Grid container className="border">
            <Grid item xs={1.2} className="textCenter">
              <img src="/images/aeroplane-takeoff.svg" alt="" />
            </Grid>
            <Grid item xs={10.8}>
              <Autocomplete
                fullWidth
                size="small"
                loading={listSearch == 1 && isLoading}
                className={style.MuiAutoComplete}
                autoHighlight
                onInputChange={(e, value) => handleSearch(e, value, 1)}
                value={sourceValue}
                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                  setSourceValue(newValue);
                  if (newValue) {
                    handleSelectAirportSource(newValue);
                  }
                }}
                isOptionEqualToValue={(option, newValue) => {
                  return option.airportCode === newValue.airportCode;
                }}
                options={airportList}
                getOptionLabel={(option: AirportListInterface) => {
                  return `${
                    option?.airportCode == sourceValue
                      ? `${option.airportCode} ${option.city} ${option.airportDesc}`
                      : `${option.airportCode} ${option.city} `
                  }`;
                }}
                componentsProps={{
                  popper: {
                    // sx: { width: { xs: "100%", sm: "auto !important" } },
                  },
                }}
                PaperComponent={({ children }) => <Paper>{children}</Paper>}
                sx={{
                  borderRadius: 4,
                  "& .MuiAutocomplete-input": {
                    padding: "7.5px 2.5px !important",
                  },
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li
                      {...props}
                      style={{
                        cursor: "pointer",
                        fontSize: 14,
                        textTransform: "capitalize",
                      }}
                      key={option.airportCode}
                    >
                      <CityCodeTypo>{`${option.airportCode}`}</CityCodeTypo>
                      {option.city.toLowerCase()} -{" "}
                      {option.airportDesc?.length > (smd ? 15 : 21)
                        ? option.airportDesc.slice(0, smd ? 15 : 21) + "..."
                        : option.airportDesc}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    //@ts-ignore
                    size="medium"
                    {...params}
                    sx={{
                      borderRadius: "4px !important",
                      backgroundColor: "#fff",
                      position: "relative",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "0px !important",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {listSearch == 1 && isLoading ? (
                            <CircularProgress
                              sx={{ color: "#D2D3E0" }}
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    id={sourceId}
                    placeholder={"From"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box className="bidirectionalIconBoxHomepage">
          <Box sx={{ position: "relative" }}>
            <img
              src="/images/arrow-swap.svg"
              alt=""
              className="ArrowIconHomePage"
              onClick={handleChangeSource}
            />
            <img
              src="/images/circel.svg"
              alt=""
              className="circleIconHomePage"
              onClick={handleChangeSource}
            />
          </Box>
        </Box>
        <Grid item xs={12} md={11.5}>
          <Grid container className="border">
            <Grid item xs={1.2} className="textCenter">
              <img src="/images/aeroplane-landing.svg" alt="" />
            </Grid>
            <Grid item xs={10.8}>
              <Autocomplete
                fullWidth
                size="small"
                loading={listSearch == 2 && isLoading}
                className={style.MuiAutoComplete}
                autoHighlight
                onInputChange={(e, value) => handleSearch(e, value, 2)}
                value={destinationValue}
                filterOptions={(x) => x}
                onChange={(event, newValue) => {
                  setDestinationValue(newValue);
                  if (newValue) {
                    handleSelectAirportDestination(newValue);
                  }
                }}
                isOptionEqualToValue={(option, newValue) => {
                  return option.airportCode === newValue.airportCode;
                }}
                options={airportList}
                getOptionLabel={(option: AirportListInterface) => {
                  return `${
                    option?.airportCode == destinationValue
                      ? `${option.airportCode} ${option.city} ${option.airportDesc}`
                      : `${option.airportCode} ${option.city}`
                  }`;
                }}
                componentsProps={{
                  popper: {
                    // sx: { width: { xs: "100%", sm: "auto !important" } },
                  },
                }}
                PaperComponent={({ children }) => <Paper>{children}</Paper>}
                sx={{
                  borderRadius: 4,
                  "& .MuiAutocomplete-input": {
                    padding: "7.5px 2.5px !important",
                  },
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li
                      {...props}
                      style={{
                        cursor: "pointer",
                        fontSize: 14,
                        textTransform: "capitalize",
                      }}
                      key={option.airportCode}
                    >
                      <CityCodeTypo>{`${option.airportCode}`}</CityCodeTypo>
                      {option.city.toLowerCase()} -{" "}
                      {option.airportDesc?.length > (smd ? 15 : 21)
                        ? option.airportDesc.slice(0, smd ? 15 : 21) + "..."
                        : option.airportDesc}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    //@ts-ignore
                    size="medium"
                    {...params}
                    sx={{
                      borderRadius: "4px !important",
                      backgroundColor: "#fff",
                      position: "relative",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "0px !important",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {listSearch == 2 && isLoading ? (
                            <CircularProgress
                              sx={{ color: "#D2D3E0" }}
                              size={20}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    id={destinationId}
                    placeholder={"To"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
const CityCodeTypo = styled(Box)(({ theme }) => ({
  color: "rgba(37, 37, 37, 1)",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  borderRadius: 6,
  padding: "4px 8px",
  cursor: "pointer",
  fontSize: 12,
  marginRight: 10,
  "&:hover": {
    backgroundColor: "rgba(219, 56, 60, 1)",
    color: "#fff",
  },
  [theme.breakpoints.down("sm")]: {},
}));
