import { Box, Skeleton, Button } from "@mui/material";
import React from "react";

function BookingSkeletonLoadingCard() {
  return (
    <>
      <Box
        p={"24px 24px 16px 24px"}
        border="1px solid rgba(37, 37, 37, 0.2)"
        borderRadius={2}
        width={"100%"}
        mx="auto"
        mt={1}
      >
        <Box
          display="flex"
          alignItems={{ xs: "start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box>
            <Box>
              <Skeleton width={150} />
            </Box>
            <Box display="flex" alignItems={{ xs: "start", sm: "center" }}>
              <Skeleton variant="rectangular" width={40} height={40} />
              <Box ml={2}>
                <Skeleton width={100} />
                <Skeleton width={60} />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems={{ xs: "start", sm: "center" }}
            justifyContent="space-between"
            width={"100%"}
            pl={{ xs: 0, sm: "25%", md: "30%" }}
            mt={{ xs: 2.5, sm: 0 }}
          >
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={60} />
              <Skeleton width={60} />
            </Box>
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={60} />
              <Skeleton width={60} />
            </Box>
            <Box textAlign={{ xs: "start", sm: "center" }}>
              <Skeleton width={60} />
              <Skeleton width={60} />
            </Box>
          </Box>
        </Box>
        <Box mt={2.5}>
          <Box
            display={{ xs: "flex", sm: "none" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          ></Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"left"}
            mt={{ xs: 2, md: 0 }}
          >
            <Box display={"flex"} alignItems={"center"} mr={2}>
              <Box textAlign="center" mr={2}>
                <Skeleton width={60} />
              </Box>
              <Box textAlign="center">
                <Skeleton width={60} />
              </Box>
            </Box>
            <Box textAlign="center">
              <Skeleton width={60} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BookingSkeletonLoadingCard;
