import { DateTime } from "luxon";
import {
  DateFilterEnum,
  FlightPriceFilterEnum,
  FlightSearchState,
  FlightStopsQuantityEnum,
  TravelClassEnum,
  TripTypesEnum,
} from ".";

export const initialState: FlightSearchState = {
  loading: false,
  form: {
    source: "",
    destination: "",
    journeyArrivalDate: DateTime.now().toISO(),
    journeyDate: DateTime.now().toISO(),
    returnArrivalDate: DateTime.now().toISO(),
    returnDate: null,
    tripType: TripTypesEnum.ONE_WAY,
    travelClass: TravelClassEnum.ECONOMY,
    airportSearch: "",
    priceFilter: FlightPriceFilterEnum.LOW_TO_HIGH,
    stopsFilter: FlightStopsQuantityEnum.NON_STOP,
    departureFilterSelection: DateFilterEnum.All,
    arrivalFilterSelection: DateFilterEnum.All,
    sourceLocation: "",
    destinationLocation: "",
  },
  list: [],
  genList: [],
  genForm: { adults: 1, children: 0, infants: 0 },
  isAdd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  airportList: [],
  flightSearchList: [],
  returnFlightSearchList: [],
  flightBookingId: "",
  formUpdate: false,
  couponList: [],
  buttonLoading: false,
};
