import { Box, IconButton, Skeleton, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import CurrencyFormat from "../CurrencyFormat";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  sliceKey as BookingSliceKey,
  reducer as BookingReducer,
} from "../../Redux/Booking/slice";
import { BookingRepoSaga } from "../../Redux/Booking/saga";
import {
  requestDetailInterface,
  selectedFlightDataFareBreakdownInterface,
  selectedFlightDataFareInterface,
} from "../../Redux/Booking/types";
import { TripTypesEnum } from "../../Redux/flightSearch/types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useSelector } from "react-redux";
import { selectCouponDiscount } from "../../Redux/Booking/selector";

interface Props {
  fareData: selectedFlightDataFareInterface;
  returnFareData: selectedFlightDataFareInterface;
  fareBreakdown: selectedFlightDataFareBreakdownInterface[];
  returnFareBreakdownData: selectedFlightDataFareBreakdownInterface[];
  loading: boolean;
  requestData: requestDetailInterface;
}

function BookingPayCard(props: Props) {
  const couponData = useSelector(selectCouponDiscount);
  const [showDiscount, setShowDiscount] = useState(false);
  const handleShowDiscount = () => {
    if (showDiscount == true) {
      setShowDiscount(false);
    } else {
      setShowDiscount(true);
    }
  };
  return (
    <>
      {props.loading ? (
        <FlightCard className="flightCardBorder">
          <Box sx={{ pt: 0.5 }}>
            <Box sx={{ display: "flex" }}>
              <Skeleton width="50%" height={50} animation="wave" />
              <Skeleton
                width="50%"
                height={50}
                animation="wave"
                sx={{ marginLeft: 2 }}
              />
            </Box>
            <Skeleton animation="wave" height={50} />
            <Box sx={{ display: "flex" }}>
              <Skeleton width="50%" height={50} animation="wave" />
              <Skeleton
                width="50%"
                height={50}
                animation="wave"
                sx={{ marginLeft: 2 }}
              />
            </Box>
          </Box>
        </FlightCard>
      ) : (
        <>
          {props.requestData.tripType == TripTypesEnum.ROUNDTRIP ? (
            <FlightCard className="flightCardBorder">
              <LineCard>
                <Heading>Base fare</Heading>
                <Heading>
                  <CurrencyFormat
                    value={
                      props?.fareData?.BaseFare +
                      props?.returnFareData?.BaseFare
                    }
                  />
                </Heading>
              </LineCard>
              {props.fareBreakdown.map((data, index) => {
                return (
                  <LineCard key={index}>
                    <SubHeading1>
                      {data?.PassengerCount}
                      {data?.PassengerType == 1
                        ? " Adult "
                        : data?.PassengerType == 2
                        ? " Child "
                        : data?.PassengerType == 3
                        ? " Infants "
                        : ""}
                      (
                      <CurrencyFormat
                        value={
                          data?.PerPaxFare +
                          props.returnFareBreakdownData[index].PerPaxFare
                        }
                      />
                      )
                    </SubHeading1>
                    <SubHeading>
                      <CurrencyFormat
                        value={
                          data?.BaseFare +
                          props.returnFareBreakdownData[index].BaseFare
                        }
                      />
                    </SubHeading>
                  </LineCard>
                );
              })}

              <LineCard>
                <Heading>Other Charges</Heading>
              </LineCard>
              <LineCard>
                <SubHeading>Taxes & Airline Charges</SubHeading>
                <SubHeading>
                  <CurrencyFormat
                    value={
                      props?.fareData?.OtherCharges +
                      props?.returnFareData?.OtherCharges
                    }
                  />
                </SubHeading>
              </LineCard>
              {couponData?.couponCode?.length > 0 && (
                <>
                  <LineCard>
                    <SubHeading>
                      Discount
                      <IconButton onClick={handleShowDiscount} sx={{ p: 0 }}>
                        {showDiscount ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </IconButton>
                    </SubHeading>

                    <SubHeading>
                      <CurrencyFormat value={couponData?.Discount} />
                    </SubHeading>
                  </LineCard>{" "}
                  {showDiscount && <Coupon>{couponData?.couponCode}</Coupon>}
                </>
              )}
              <LineCard>
                <Heading sx={{ color: "DE3151" }}>Total Fare</Heading>
                <Heading sx={{ color: "DE3151" }}>
                  <CurrencyFormat
                    value={
                      couponData?.TotalFare
                        ? couponData?.TotalFare
                        : props?.fareData?.TotalFare +
                          props?.returnFareData?.TotalFare
                    }
                  />
                </Heading>
              </LineCard>
            </FlightCard>
          ) : (
            <FlightCard className="flightCardBorder">
              <LineCard>
                <Heading>Base fare</Heading>
                <Heading>
                  <CurrencyFormat value={props?.fareData?.BaseFare} />
                </Heading>
              </LineCard>
              {props.fareBreakdown.map((data, index) => {
                return (
                  <LineCard key={index}>
                    <SubHeading1>
                      {data?.PassengerCount}
                      {data?.PassengerType == 1
                        ? " Adult "
                        : data?.PassengerType == 2
                        ? " Child "
                        : data?.PassengerType == 3
                        ? " Infants "
                        : ""}
                      (
                      <CurrencyFormat value={data?.PerPaxFare} />)
                    </SubHeading1>
                    <SubHeading>
                      <CurrencyFormat value={data?.BaseFare} />
                    </SubHeading>
                  </LineCard>
                );
              })}

              <LineCard>
                <Heading>Other Charges</Heading>
              </LineCard>
              <LineCard>
                <SubHeading>Taxes & Airline Charges</SubHeading>
                <SubHeading>
                  <CurrencyFormat value={props?.fareData?.OtherCharges} />
                </SubHeading>
              </LineCard>
              {couponData?.couponCode?.length > 0 && (
                <>
                  <LineCard>
                    <SubHeading>
                      Discount
                      <IconButton onClick={handleShowDiscount} sx={{ p: 0 }}>
                        {showDiscount ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </IconButton>
                    </SubHeading>

                    <SubHeading>
                      <CurrencyFormat value={couponData?.Discount} />
                    </SubHeading>
                  </LineCard>{" "}
                  {showDiscount && <Coupon>{couponData?.couponCode}</Coupon>}
                </>
              )}

              <LineCard>
                <Heading sx={{ color: "DE3151" }}>Total Fare</Heading>
                <Heading sx={{ color: "DE3151" }}>
                  <CurrencyFormat
                    value={
                      couponData?.TotalFare
                        ? couponData?.TotalFare
                        : props?.fareData?.TotalFare
                    }
                  />
                </Heading>
              </LineCard>
            </FlightCard>
          )}
        </>
      )}
    </>
  );
}

export default BookingPayCard;

const FlightCard = styled(Box)(({ theme }) => ({
  color: "rgba(0, 0, 0, 1)",
  padding: "24px 24px 16px 24px",
  marginBottom: 16,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));

const LineCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 5,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: "hsl(0, 0%, 50%)",
  fontSize: 13,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: "hsl(0, 0%, 65%)",
  fontSize: 10.5,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const Coupon = styled(Typography)(({ theme }) => ({
  color: "hsl(0, 0%, 50%)",
  fontSize: 9,
  fontWeight: 600,
  marginTop: -8,
  // marginBottom: 10,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
const SubHeading1 = styled(Typography)(({ theme }) => ({
  display: "flex",
  color: "hsl(0, 0%, 65%)",
  fontSize: 10.5,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.between("sm", "md")]: {},
}));
