import Selector from "../../Component/Selector";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import MainLayout from "../../Component/Layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectAirportList,
  SelectButtonLoading,
  selectForm,
  selectGenForm,
} from "../../Redux/flightSearch/selector";
import { actions } from "../../Redux/flightSearch/slice";
import { actions as loginActions } from "../../Redux/Login/slice";
import { actions as homeAction } from "../../Redux/Home/slice";
import PersonSelector from "../../Component/PersonSelector";
import { TravelClassEnum, TripTypesEnum } from "../../Redux/flightSearch/types";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import SearchFlight from "../../Component/BookButton/SearchFlight";
import NewDatePicker from "../../Component/DataPicker/NewDatePicker";
import { createSearchParams, useNavigate } from "react-router-dom";
import LoginModal from "../../Component/LoginModal";
import Footer from "../../Component/Layout/Footer";
import AirportSearch from "../../Component/AirportSearch";
import { selectHomeBanner } from "../../Redux/Home/selector";
import { path } from "../../utils/Api";

function Homepage() {
  const navigate = useNavigate();
  const handleTabChange = (value: number) => {
    dispatch(actions.updateFormValue({ key: "tripType", value: value }));
    if (value == 1) {
      dispatch(
        actions.updateFormValue({
          key: "returnDate",
          value: null,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "returnArrivalDate",
          value: DateTime.now().toISO(),
        })
      );
    }
  };

  const AnyTime = "00:00:00";
  let currentDate = new Date();
  const handleChangeDate = (val: any) => {
    const newValue = DateTime.fromJSDate(val?.$d).toISO();
    const d: any = newValue?.split("T");
    const concatDate = d[0].concat("T", AnyTime);
    dispatch(
      actions.updateFormValue({
        key: "journeyDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "journeyArrivalDate",
        value: concatDate,
      })
    );
  };
  const handleChangeArrivalDate = (val: any) => {
    const newValue = DateTime.fromJSDate(val?.$d).toISO();
    const d: any = newValue?.split("T");
    const concatDate = d[0].concat("T", AnyTime);
    dispatch(
      actions.updateFormValue({
        key: "returnDate",
        value: concatDate,
      })
    );
    dispatch(
      actions.updateFormValue({
        key: "returnArrivalDate",
        value: concatDate,
      })
    );
    handleTabChange(TripTypesEnum.ROUNDTRIP);
  };
  const handleSelectTravelClass = (evt: any) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };

  const [activeSlide, setActiveSlide] = React.useState(0);
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const loading = useSelector(SelectButtonLoading);
  const images = useSelector(selectHomeBanner);
  const genForm = useSelector(selectGenForm);

  React.useEffect(() => {
    dispatch(actions.doGetAirportList(""));
    dispatch(homeAction.doGetHomeBanner());

    if (
      !sessionStorage.hasOwnProperty("visited") &&
      !localStorage.hasOwnProperty("token")
    ) {
      setTimeout(() => {
        dispatch(loginActions.toggleOpenLogin(true));
        sessionStorage.setItem("visited", "true");
      }, 1000);
    }
    return () => {};
  }, []);

  const params = {
    adults: String(genForm.adults),
    children: String(genForm.children),
    infants: String(genForm.infants),
    destination: form.destination,
    destinationLocation: form.destinationLocation,
    flightType: "1",
    journeyDate: form.journeyDate,
    returnDate:
      String(form.tripType) == String(TripTypesEnum.ROUNDTRIP)
        ? form.returnDate
        : "",
    source: form.source,
    sourceLocation: form.sourceLocation,
    travelClass: String(form.travelClass),
    tripType: String(form.tripType),
    arrivalFilter: String(null),
    stopsFilter: "1",
    priceFilter: "1",
  };
  const handleClick = () => {
    if (form.source.length == 0) {
      toast.error("please select source");
      return;
    }
    if (form.destination.length == 0) {
      toast.error("please select destination");
      return;
    }
    if (form?.journeyDate?.length == 0 || form.journeyDate == null) {
      toast.error("please select journeyDate");
      return;
    }
    if (genForm.adults == 0 && genForm.children == 0 && genForm.infants == 0) {
      toast.error("please select at least 1 passenger");
      return;
    }
    navigate({
      pathname: "/flight-listing",
      search: `?${createSearchParams(params)}`,
    });
    dispatch(actions.clearSearchFlightForm());
  };

  React.useEffect(() => {
    if (
      form.tripType == TripTypesEnum.ROUNDTRIP &&
      form.returnDate < form.journeyDate
    ) {
      dispatch(
        actions.updateFormValue({
          key: "returnDate",
          value: form.journeyDate,
        })
      );
      dispatch(
        actions.updateFormValue({
          key: "returnArrivalDate",
          value: form.journeyDate,
        })
      );
    }

    return () => {};
  }, [form.journeyDate, form.tripType == TripTypesEnum.ROUNDTRIP]);

  React.useEffect(() => {
    if (images?.length !== activeSlide) {
      setTimeout(() => {
        setActiveSlide(activeSlide + 1);
      }, 5000);
    } else {
      setActiveSlide(0);
    }
    return () => {};
  }, [activeSlide, images]);

  return (
    <>
      <MainLayout hasBorder={false}>
        <Box
          py={{ xs: 4, sm: 4 }}
          sx={{
            minHeight: "calc(100vh - 141px)",
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundImage: `url(${path()}${images[activeSlide]})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <Container
            sx={{
              py: "auto",
              minHeight: "calc(100vh - 189px)",
              alignContent: "center",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={8} md={6} lg={5}>
                <Card
                  sx={{
                    minWidth: 275,
                    borderRadius: 7,
                    padding: { xs: 1.5, md: 3 },
                    border: "1px solid rgb(230, 230, 230)",
                    boxShadow: "rgba(0, 0, 0, 0.04) 0px 8px 16px",
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: { xs: 16, sm: 18, md: 20, lg: 25 },
                        fontWeight: 600,
                        color: "rgba(37, 37, 37, 1)",
                      }}
                      gutterBottom
                    >
                      Book your flights with Trivzy
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                      <OutlinedButton
                        sx={
                          form.tripType == TripTypesEnum.ONE_WAY
                            ? style
                            : undefined
                        }
                        onClick={() => handleTabChange(TripTypesEnum.ONE_WAY)}
                      >
                        One Way
                      </OutlinedButton>
                      <OutlinedButton
                        sx={
                          form.tripType == TripTypesEnum.ROUNDTRIP
                            ? style
                            : undefined
                        }
                        onClick={() => handleTabChange(TripTypesEnum.ROUNDTRIP)}
                      >
                        Round trip
                      </OutlinedButton>
                    </Box>

                    <Box sx={{ mt: 3.5 }}>
                      <AirportSearch />
                      <Grid container mt={2}>
                        <Grid item xs={12} sm={12} md={11.5} lg={11.5}>
                          <Grid
                            container
                            className="dateAndTripBorder"
                            sx={{ position: "relative" }}
                          >
                            <Grid item xs={5.6} sm={5.7} className="">
                              <NewDatePicker
                                name={"journeyDate"}
                                onChange={handleChangeDate}
                                disabled={undefined}
                                value={form.journeyDate}
                                disablePast={true}
                                disableFuture={false}
                                minDate={currentDate}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={5.9}
                              sm={6.3}
                              className="textCenter"
                              sx={{
                                borderLeft: "1px solid #e5e5e5",
                                position: "relative",
                              }}
                            >
                              <NewDatePicker
                                name={"returnDate"}
                                onChange={handleChangeArrivalDate}
                                disabled={false}
                                value={form.returnDate} // Ensure it is null if not set
                                disablePast={true}
                                disableFuture={false}
                                minDate={
                                  form?.tripType == TripTypesEnum.ROUNDTRIP
                                    ? form.journeyDate || currentDate
                                    : null
                                }
                                placeholder="Return"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container columnSpacing={2} mt={2}>
                        <Grid item xs={12} sm={6} md={5.5}>
                          <Grid container className="border">
                            <Grid item xs={1.2} sm={2.5} className="textCenter">
                              <img
                                src="/images/user.svg"
                                alt=""
                                style={{
                                  width: 24,
                                  height: 24,
                                }}
                              />
                            </Grid>
                            <Grid item xs={10.8} sm={9.5}>
                              <PersonSelector />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} mt={{ xs: 2, sm: 0 }}>
                          <Grid container className="border">
                            <Grid item xs={1.2} sm={2.5} className="textCenter">
                              <img
                                src="/images/flight-class.svg"
                                alt=""
                                style={{
                                  width: 28,
                                  height: 28,
                                }}
                              />
                            </Grid>
                            <Grid item xs={10.8} sm={9.5}>
                              <Selector
                                data={FlightClassData}
                                name={"travelClass"}
                                placeholder={"Select Flight Class"}
                                value={form.travelClass}
                                onChange={handleSelectTravelClass}
                                className={""}
                                disabled={undefined}
                                bootstrapInputClass="travelClassSelector"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <CardActions className="textCenter" sx={{ mt: 2, mb: 1 }}>
                    <SearchFlight
                      handleClick={handleClick}
                      loading={loading}
                      title="Search flights"
                    />
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container sx={{ px: "0px !important" }}>
            <Footer homepage={true} />
          </Container>
        </Box>
      </MainLayout>
      <LoginModal />
    </>
  );
}

export default Homepage;

const FlightClassData = [
  {
    id: TravelClassEnum.ECONOMY,
    name: "Economy",
  },
  {
    id: TravelClassEnum.PREMIUM_ECONOMY,
    name: "Premium Economy",
  },
  {
    id: TravelClassEnum.BUSINESS,
    name: "Business Class",
  },
  {
    id: TravelClassEnum.FIRST_CLASS,
    name: "First Class",
  },
];

const OutlinedButton = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  color: "rgba(51, 51, 51, 1)",
  fontWeight: 500,
  borderRadius: 8,
  fontSize: 14,
  padding: "6px 10px",
  // width: 130,
  "&:hover": {
    backgroundColor: "#fff",
  },
  [theme.breakpoints.down("sm")]: {
    // width: 100,
  },
}));
const style = {
  border: "1px solid rgba(222, 49, 81, 1)",
  backgroundColor: "rgba(219, 56, 60, 0.05)",
  color: "#DB3938",
  "&:hover": {
    backgroundColor: "rgba(219, 56, 60, 0.05)",
  },
};
const AddBox = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(222, 49, 81, 0.1)",
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  borderRadius: 8,
  padding: "0px 8px",
  marginLeft: 10,
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
  },
}));
const ReturnText = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  textAlign: "start",
  alignContent: "center",
  fontSize: 12,
  color: "#000",
  paddingLeft: 10,
  backgroundColor: "#fff",
  pointerEvents: "none",
}));
