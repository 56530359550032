import BookButton from "../../BookButton";
import WhiteButton from "../../BookButton/whiteButton";
import Selector from "../../Selector";
import CountrySelector from "../../Selector/CountrySelector";
import TextInputField from "../../TextInputField";
import {
  selectButtonLoading,
  selectCountryList,
  selectPassengerModal,
  selectTravellerForm,
} from "../../../Redux/Login/selector";
import { actions } from "../../../Redux/Login/slice";
import {
  GenderEnum,
  PassengerPaxTypeEnum,
  PassengerTitleEnum,
} from "../../../Redux/Login/types";
import theme from "../../../Style/theme";
import { Box, Grid, Modal, Typography, styled } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NewDatePicker from "../../DataPicker/NewDatePickerUser";
import { isValidPhoneNumber } from "react-phone-number-input";

function Index() {
  const dispatch = useDispatch();
  const form = useSelector(selectTravellerForm);
  const loading = useSelector(selectButtonLoading);
  const countryList = useSelector(selectCountryList);
  const open = useSelector(selectPassengerModal);
  const [showError, setShowError] = React.useState(0);

  const emailRegex = /^[a-zA-Z0-9.]+@[a-z]+\.[a-z]{2,3}$/;
  React.useEffect(() => {
    dispatch(actions.doGetCountryList());
    return () => {};
  }, []);

  const handleChangeDate = (val: any) => {
    dispatch(
      actions.updateTravellerFormValue({
        key: "DateOfBirth",
        value: DateTime.fromJSDate(val?.$d).toISO(),
      })
    );
  };
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(
      actions.updateTravellerFormValue({
        key: name,
        value: name == "ContactNo" ? value.slice(0, 10) : value,
      })
    );
    if (name == "Email") {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        setShowError(0);
      } else {
        setShowError(4);
      }
    }
  };
  const handleNumberFieldChange = (value: string, name: string) => {
    console.log("value", value);
    let isValid;
    if (value) {
      isValid = isValidPhoneNumber(value);
    }
    const countryCodeMatch = value.match(/^\+(\d+)/);
    const countryCode = countryCodeMatch ? countryCodeMatch[1] : null;
    console.log("Country Code:", countryCode);
    dispatch(
      actions.updateTravellerFormValue({
        key: "isNoValid",
        value: isValid,
      })
    );
    dispatch(actions.updateTravellerFormValue({ key: name, value: value }));
  };
  // // Date Selection
  let today = new Date();
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  // // Calculate the date two years ago
  // const twoYearsAgo = new Date(today);
  // twoYearsAgo.setFullYear(today.getFullYear() - 2);
  // const twelveYearsAgo = new Date(today);
  // twelveYearsAgo.setFullYear(today.getFullYear() - 12);

  // useEffect(() => {
  //   if (form.PaxType == PassengerPaxTypeEnum.Adult) {
  //     dispatch(
  //       actions.updateTravellerFormValue({
  //         key: "DateOfBirth",
  //         value: DateTime.fromJSDate(twelveYearsAgo).toISO(),
  //       })
  //     );
  //   } else if (form.PaxType == PassengerPaxTypeEnum.Child) {
  //     dispatch(
  //       actions.updateTravellerFormValue({
  //         key: "DateOfBirth",
  //         value: DateTime.fromJSDate(twoYearsAgo).toISO(),
  //       })
  //     );
  //   }

  //   return () => {};
  // }, []);

  const handleClick = () => {
    dispatch(
      actions.updateTravellerFormValue({
        key: "fullName",
        value: form.FirstName + " " + form.LastName,
      })
    );
    if (form.FirstName.length === 0) {
      toast.error("Please enter your first name");
      return;
    }
    if (form.LastName.length === 0) {
      toast.error("Please enter your last name");
      return;
    }
    if (form.Email.length === 0) {
      toast.error("Please enter your email");
      return;
    }
    if (!emailRegex.test(form.Email)) {
      toast.error("Please enter valid email");
      return;
    }
    if (new Date(form.DateOfBirth) >= today) {
      toast.error("Please select valid date of birth");
      return;
    }
    if (form.AddressLine1.length === 0) {
      toast.error("Please enter your Address");
      return;
    }
    if (form?.ContactNo?.length > 0) {
      if (!/^[6-9]\d{9}$/.test(form?.ContactNo)) {
        toast.error("Enter valid contact number");
        return;
      }
    }

    // if (form.PaxType == PassengerPaxTypeEnum.Adult) {
    //   const latestYear = new Date(form.DateOfBirth).getFullYear();
    //   const adultYear = twelveYearsAgo.getFullYear();
    //   if (Number(latestYear) > Number(adultYear)) {
    //     toast.error("Please select valid adult date ");
    //     return;
    //   }
    // } else if (form.PaxType == PassengerPaxTypeEnum.Child) {
    //   const latestYear = new Date(form.DateOfBirth).getFullYear();
    //   const adultYear = twoYearsAgo.getFullYear();
    //   if (Number(latestYear) > Number(adultYear)) {
    //     toast.error("Please select valid child date ");
    //     return;
    //   }
    // }
    if (form.CountryCode.length === 0) {
      toast.error("Please Select your Country");
      return;
    }
    if (form._id) {
      dispatch(
        actions.doUpdateTraveller({
          callback: () => {
            dispatch(actions.clearTravellerForm());
            dispatch(actions.doGetTraveller());
            dispatch(actions.toggleOpenPassenger());
          },
        })
      );
    } else {
      dispatch(
        actions.doAddTraveller({
          callback: () => {
            dispatch(actions.clearTravellerForm());
            dispatch(actions.doGetTraveller());
            dispatch(actions.toggleOpenPassenger());
          },
        })
      );
    }
  };
  const cancel = () => {
    dispatch(actions.clearTravellerForm());
    dispatch(actions.toggleOpenPassenger());
    setShowError(0);
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Grid
            container
            spacing={{ xs: 0, sm: 3.5 }}
            rowGap={{ xs: 2, sm: 0 }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <div className="border">
                <Selector
                  data={TitleData}
                  name={"Title"}
                  placeholder={"Select Title"}
                  value={form.Title}
                  onChange={handleChange}
                  className={""}
                  disabled={undefined}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextInputField
                type={"text"}
                placeHolder={"Enter first name"}
                className={""}
                value={form.FirstName}
                name={"FirstName"}
                onChange={handleChange}
                disabled={false}
                sx={inputFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextInputField
                type={"text"}
                placeHolder={"Enter last name"}
                className={""}
                value={form.LastName}
                name={"LastName"}
                onChange={handleChange}
                disabled={false}
                sx={inputFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextInputField
                type={"text"}
                placeHolder={"Email"}
                className={""}
                value={form.Email}
                name={"Email"}
                onChange={handleChange}
                disabled={false}
                sx={showError == 4 ? errorFieldStyle : inputFieldStyle}
              />
              {showError == 4 && (
                <ErrorTypo>Enter valid email address</ErrorTypo>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="border">
                <Selector
                  data={PaxData}
                  name={"PaxType"}
                  placeholder={"Select Pax Type"}
                  value={form.PaxType}
                  onChange={handleChange}
                  className={""}
                  disabled={undefined}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="border">
                <Selector
                  data={GenderStatusData}
                  name={"Gender"}
                  placeholder={"Select gender"}
                  value={form.Gender}
                  onChange={handleChange}
                  className={""}
                  disabled={undefined}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="border" style={{ padding: "3px 0" }}>
                <NewDatePicker
                  name={"DateOfBirth"}
                  onChange={handleChangeDate}
                  disabled={undefined}
                  value={form.DateOfBirth}
                  type={undefined}
                  placeholder="Date Of Birth"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextInputField
                type={"text"}
                placeHolder={"Address"}
                className={""}
                value={form.AddressLine1}
                name={"AddressLine1"}
                onChange={handleChange}
                disabled={false}
                sx={inputFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {/* <PhoneInput
                countryCode="IN"
                handleChange={handleNumberFieldChange}
                name="ContactNo"
                value={form.ContactNo}
              /> */}
              <TextInputField
                type={"number"}
                name={"ContactNo"}
                placeHolder={"Mobile Number"}
                value={form.ContactNo}
                className={""}
                onChange={handleChange}
                disabled={false}
                sx={inputFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="border">
                <CountrySelector
                  data={countryList}
                  name={"CountryCode"}
                  placeholder={"Select passport issue country"}
                  value={form.CountryCode}
                  onChange={handleChange}
                  className={""}
                  disabled={undefined}
                />
              </div>
            </Grid>
          </Grid>
          <Box className="textCenter" mt={5} columnGap={2}>
            <WhiteButton handleClick={cancel} loading={false} title="Cancel" />
            <BookButton
              handleClick={handleClick}
              loading={loading}
              title={form._id ? "Update" : "Save"}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Index;
const GenderStatusData = [
  {
    id: GenderEnum.MALE,
    name: "Male",
  },
  {
    id: GenderEnum.FEMALE,
    name: "Female",
  },
  {
    id: GenderEnum.OTHER,
    name: "Other",
  },
];
const PaxData = [
  {
    id: PassengerPaxTypeEnum.Adult,
    name: "Adult",
  },
  {
    id: PassengerPaxTypeEnum.Child,
    name: "Child",
  },
  {
    id: PassengerPaxTypeEnum.Infant,
    name: "Infant",
  },
];
const TitleData = [
  {
    id: PassengerTitleEnum.MR,
    name: "Mr.",
  },
  {
    id: PassengerTitleEnum.MRS,
    name: "Mrs.",
  },
  {
    id: PassengerTitleEnum.MS,
    name: "Ms.",
  },
  {
    id: PassengerTitleEnum.MSTR,
    name: "Mstr.",
  },
];
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 30px 0px rgba(210, 210, 210, 0.37)",
  padding: "35px 40px",
  borderRadius: 3,
  outline: 0,
  [theme.breakpoints.down("sm")]: {
    width: "95%",
    padding: "20px 20px",
  },
  [theme.breakpoints.between("sm", "md")]: {
    height: "auto",
    width: "70%",
    padding: "30px 30px",
  },
  [theme.breakpoints.between("md", "lg")]: {
    height: "auto",
    width: "80%",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    height: "auto",
    width: "60%",
  },
};
const inputFieldStyle = {
  "& .MuiOutlinedInput-input": {
    padding: "16.5px 14px !important",
    fontSize: 12,
  },
};
const ErrorTypo = styled(Typography)(({ theme }) => ({
  color: "#ff4d4f",
  fontSize: 12,
}));
const errorFieldStyle = {
  "& .MuiOutlinedInput-input": {
    padding: "16.5px 14px !important",
    fontSize: 12,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px !important",
    borderColor: "#ff4d4f !important",
  },
};
