import { DateTime } from "luxon";
import {
  BookingState,
  GenderEnum,
  PassengerPaxTypeEnum,
  PassengerTitleEnum,
} from ".";

export const initialState: BookingState = {
  loading: false,
  search: "",
  form: {
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
  },
  flightBookingId: "",
  selectedFlightData: {
    ResultIndex: "",
    IsRefundable: false,
    IsPanRequiredAtBook: false,
    IsPanRequiredAtTicket: false,
    IsPassportRequiredAtBook: false,
    IsPassportRequiredAtTicket: false,
    IsPassportFullDetailRequiredAtBook: false,
    Fare: {
      Currency: "",
      BaseFare: 0,
      Tax: 0,
      OfferedFare: 0,
      actualPublishedFare: 0,
      markupTotal: 0,
      ConvenienceFee: 0,
      OtherCharges: 0,
      TotalFare: 0,
    },
    FareBreakdown: [
      {
        BaseFare: 0,
        PerPaxFare: 0,
        PassengerCount: 0,
        PassengerType: 0,
        Currency: "",
      },
    ],
    Segments: [
      {
        Baggage: "",
        CabinBaggage: "",
        CabinClass: 0,
        SupplierFareClass: 0,
        TripIndicator: 0,
        SegmentIndicator: 0,
        Airline: {
          AirlineCode: "",
          AirlineName: "",
          FlightNumber: "",
          FareClass: "",
          OperatingCarrier: "",
          AirlineLogo: ""
        },
        Origin: {
          Airport: {
            AirportCode: "",
            AirportName: "",
            Terminal: "",
            CityCode: "",
            CityName: "",
            CountryCode: "",
            CountryName: "",
          },
          DepTime: "",
        },
        Destination: {
          Airport: {
            AirportCode: "",
            AirportName: "",
            Terminal: "",
            CityCode: "",
            CityName: "",
            CountryCode: "",
            CountryName: "",
          },
          ArrTime: "",
        },
        Duration: 0,
        GroundTime: 0,
        Mile: 0,
        StopOver: false,
        FlightInfoIndex: "",
        StopPoint: "",
        StopPointArrivalTime: "",
        StopPointDepartureTime: "",
        Craft: "",
        Remark: 0,
        IsETicketEligible: false,
        FlightStatus: "",
        Status: "",
        FareClassification: {
          Type: "",
        },
      },
    ],
    AirlineCode: "",
  },
  BookingPaymentForm: {
    bookingId: "",
    email: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    Passengers: [],
    PassengersForm: {
      honorifics: "Mr. ",
      Email: "",
      fullName: "",
      FirstName: "",
      LastName: "",
      DateOfBirth: "",
      PassportNo: "",
      PassportExpiry: "",
      CountryCode: "",
      CellCountryCode: "+91",
      ContactNo: "",
      type: 0,
      Gender: GenderEnum.MALE,
      _id: "",
      Title: PassengerTitleEnum.MR,
      PaxType: PassengerPaxTypeEnum.Adult,
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      Nationality: "",
      IsLeadPax: true,
      FFAirlineCode: null,
      FFNumber: "",
      GSTCompanyAddress: "",
      GSTCompanyContactNumber: "",
      GSTCompanyName: "",
      GSTNumber: "",
      GSTCompanyEmail: "",
    },
  },
  RazorePayForm: {
    callback_url: "",
    amount: 0,
    currency: "",
    key: "",
    paymentId: "",
    status: false,
  },
  saveTraveler: false,
  status: 1,

  selectedFlightCard: {
    airlineName: "",
    AirlineLogo: "",
    airlineCode: "",
    flightNumber: "",
    depTime: "",
    sourceCityName: "",
    duration: "",
    stop: "",
    arrTime: "",
    destinationCityName: "",
    price: 0,
    resultIndex: "",
    returnResultIndex: "",
    returnAirlineName: "",
    returnAirlineLogo: "",
    returnAirlineCode: "",
    returnFlightNumber: "",
    returnDepTime: "",
    returnSourceCityName: "",
    returnDuration: "",
    returnStop: "",
    returnArrTime: "",
    returnDestinationCityName: "",
    returnPrice: 0,
  },
  selectedFlightReturnData: {
    ResultIndex: "",
    IsRefundable: false,
    IsPanRequiredAtBook: false,
    IsPanRequiredAtTicket: false,
    IsPassportRequiredAtBook: false,
    IsPassportRequiredAtTicket: false,
    IsPassportFullDetailRequiredAtBook: false,
    Fare: {
      Currency: "",
      BaseFare: 0,
      Tax: 0,
      OfferedFare: 0,
      actualPublishedFare: 0,
      markupTotal: 0,
      ConvenienceFee: 0,
      OtherCharges: 0,
      TotalFare: 0,
    },
    FareBreakdown: [
      {
        BaseFare: 0,
        PerPaxFare: 0,
        PassengerCount: 0,
        PassengerType: 0,
        Currency: "",
      },
    ],
    Segments: [
      {
        Baggage: "",
        CabinBaggage: "",
        CabinClass: 0,
        SupplierFareClass: 0,
        TripIndicator: 0,
        SegmentIndicator: 0,
        Airline: {
          AirlineCode: "",
          AirlineName: "",
          FlightNumber: "",
          FareClass: "",
          OperatingCarrier: "",
          AirlineLogo: ""
        },
        Origin: {
          Airport: {
            AirportCode: "",
            AirportName: "",
            Terminal: "",
            CityCode: "",
            CityName: "",
            CountryCode: "",
            CountryName: "",
          },
          DepTime: "",
        },
        Destination: {
          Airport: {
            AirportCode: "",
            AirportName: "",
            Terminal: "",
            CityCode: "",
            CityName: "",
            CountryCode: "",
            CountryName: "",
          },
          ArrTime: "",
        },
        Duration: 0,
        GroundTime: 0,
        Mile: 0,
        StopOver: false,
        FlightInfoIndex: "",
        StopPoint: "",
        StopPointArrivalTime: "",
        StopPointDepartureTime: "",
        Craft: "",
        Remark: 0,
        IsETicketEligible: false,
        FlightStatus: "",
        Status: "",
        FareClassification: {
          Type: "",
        },
      },
    ],
    AirlineCode: "",
  },
  requestDetail: {
    adults: 0,
    children: 0,
    infants: 0,
    destination: "",
    flightType: 0,
    journeyDate: "",
    returnDate: "",
    source: "",
    travelClass: 0,
    tripType: 0,
    arrivalFilter: 0,
    stopsFilter: 0,
    priceFilter: 0,
  },
  bookingTimer: "",
  timerModal: false,
  couponForm: {
    bookingId: "",
    couponCode: "",
  },
  couponDiscount: {
    Discount: 0,
    TotalFare: 0,
    couponCode: "",
  },
};
