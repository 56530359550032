import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    muiTextField: {
        // "& .MuiOutlinedInput-notchedOutline": {
        //     borderRadius: "8px !important",
        //     borderColor: "#e5e5e5 !important",
        //   },
          " fieldset": {
            outline: "0px !important",
            border: "0px !important",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            // border: "1px solid rgba(222, 49, 81, 1) !important",
            
          },
    },
});