import Chip, { ChipProps } from "@mui/material/Chip";
import { styled } from "@mui/material";
interface Props {
  label: string;
  paddingX?: number /*Keep the value in px */;
  sx?: any;
  variant: string;
  onClick?: any;
}

export default function Chips(props: Props) {
  return (
    <>
      {props?.variant == "primary" ? (
        <PrimaryChip
          px={props?.paddingX}
          label={props.label}
          variant="outlined"
          sx={props?.sx}
          onClick={props?.onClick}
        />
      ) : (
        <NewChip
          px={props?.paddingX}
          label={props.label}
          variant="outlined"
          sx={props?.sx}
          onClick={props?.onClick}
        />
      )}
    </>
  );
}

interface NewChipProps extends ChipProps {
  px?: number | null;
}

const PrimaryChip = styled(Chip)<NewChipProps>(({ theme, px }) => ({
  color: "#DE3151",
  backgroundColor: "rgba(232, 70, 0, 0.05)",
  borderColor: "#DE3151",
  height: "auto",
  minHeight: "auto",
  "&:hover": {
    backgroundColor: "rgba(232, 70, 0, 0.05) !important",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
  "& .MuiChip-label": {
    paddingLeft: px ? `${px}px !important` : "12px !important",
    paddingRight: px ? `${px}px !important` : "12px !important",
  },
}));
const NewChip = styled(Chip)<NewChipProps>(({ theme, px }) => ({
  height: "auto",
  minHeight: "auto",
  "& .MuiTouchRipple-root": {
    display: "none",
  },
  "& .MuiChip-label": {
    paddingLeft: px ? `${px}px !important` : "12px !important",
    paddingRight: px ? `${px}px !important` : "12px !important",
  },
}));
