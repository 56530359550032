import { Box, Skeleton } from "@mui/material";
import React from "react";

function TravellerSkeletonLoading() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          mb: 2,
          ml: 1,
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            mr: 1,
            width: 21,
            height: 21,
          }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: 21, width: { xs: "100%", md: "46%" } }}
        />
      </Box>
    </>
  );
}

export default TravellerSkeletonLoading;
